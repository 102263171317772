import { Button, TextField } from '@finpay-development/shared-components';
import { Grid, Typography } from '@mui/material';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';

import { RootState } from '../../shared/state/root-reducer';
import { AppDispatch } from '../../shared/state/store';
import {
  changeAuthState,
} from '../services/authorization-service';
import {clearForm} from '../state/user-slice';

interface ForgetPasswordNotificationProps {
  onClick: (email: string) => void;
}

function ForgetPasswordNotification(props: ForgetPasswordNotificationProps) {
  const [email, setEmail] = useState("");
  const [isFormProcessing, setIsFormProcessing] = useState(false);
  const [enabledPasswordButton, setEnabledPasswordButton] = useState(false);

  const userContextClearForm = useSelector(
    (state: RootState) => state.userContext.clearForm
  );

  useEffect(() => {
    setEmail("");
    if (userContextClearForm) {
      setEmail("");
    }
  }, [userContextClearForm]);

  const dispatch = useDispatch<AppDispatch>();
  function handleBacktoLogin() {
    dispatch(clearForm(false));
    changeAuthState("signin");
  }

  async function sendPasswordNotification() {
    const enteredInEmail: string = email.toString();
    await props.onClick(enteredInEmail);
  }

  // Validation Rules
  const validationSchema = Yup.object({
    email: Yup.string()
      .required("Please Enter an Email Address")
      .email("Please Enter an Email Address"),
  });

  // Check Password Field Validation on change
  function handleChange(e: ChangeEvent<HTMLInputElement>) {
    const emailValue = e.target.value;
    setEmail(emailValue);

    validationSchema
    .validate({ email: emailValue }, { abortEarly: false })
    .then(function () {
      setEnabledPasswordButton(true);
    })
    .catch(function (err: Yup.ValidationError) {
      setEnabledPasswordButton(false);
    });
  }

  return (
    <Grid
      container
      direction="column"
      alignContent="center"
      justifyContent="center"
      spacing={2}
    >
      <Grid item>
        <Typography className="mb-6" variant="h1">
          Forgot Password
        </Typography>
        <Typography className="mb-2" variant="body1">
          Enter the email address associated with your FinPay account. FinPay will send you instructions to reset your password.
        </Typography>
      </Grid>
      <Grid item>
        <TextField
          error=""
          label="Email"
          name="email"
          value={email}
          onChange={handleChange}
          placeholder="Enter Your Email Address"
          helperText="Use the email associated with your FinPay account"
          onBlur={() => true}
        />
      </Grid>
      <Grid
        item
        container
        direction="column"
        alignContent="flex-end"
        justifyContent="flex-end"
        spacing={2}
      >
        <Grid item>
          <Link to="" onClick={handleBacktoLogin} className="forgot-password">
            Back to Login
          </Link>
        </Grid>
        <Grid item>
          <Button
            type="primary"
            spinnerLeftPosition={8}
            loading={isFormProcessing}
            disabled={isFormProcessing ? isFormProcessing : !enabledPasswordButton}
            onClick={async () => {
              setIsFormProcessing(true)
              await sendPasswordNotification()
              setIsFormProcessing(false)
            }}
          >
            Reset Password
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default ForgetPasswordNotification;
