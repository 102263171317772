import '../../../../scss/components/_btn-group.scss';
import '../../../../scss/components/_icons.scss';
import '../../../../scss/pages/admin/_admin-clients.scss';

import {
  DatePicker,
  DialogActionButton,
  saveCallbackStatus,
  TextField,
  Toggle,
} from '@finpay-development/shared-components';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import { Box, MenuItem, Typography } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import { Form, Formik } from 'formik';
import { ChangeEvent, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';

import { formatNumberToUSD } from '../../../../shared/calculators';
import { CustomFormikProps } from '../../../../shared/model/formik-props';
import { RootState } from '../../../../shared/state/root-reducer';
import { AppDispatch } from '../../../../shared/state/store';
import { Utils } from '../../../../shared/utils';
import {
  patientDownPaymentACH,
  patientDownPaymentCreditCard,
  patientDownPaymentPaidAtFacility,
} from '../../../../shared/validation/schemas';
import StripeBankField from '../../../../stripe/components/StripeBankField';
import StripeCardField from '../../../../stripe/components/StripeCardField';
import { patientHelper, patientService } from '../../../services/patient-service';
import { setDownPaymentToken, setRecurringPaymentToken } from '../../../state/patient-slice';
import { addPatientPaymentMethod, createSPAHelloSignRequest, makePayment, putProgram, resendPaymentReceipt } from '../../../state/patient-thunk';
import { PatientPaymentProgram } from '../../models/patient-payment-program';
import { Payment } from '../../models/payment';
import { PaymentDetail } from '../../models/payment-detail';
import { PAYMENT_METHOD_TYPES, PaymentMethod } from '../../models/payment-method';
import { PAYMENT_TYPES } from '../../models/payment-type';
import {paynowService as paymentService } from '../../../../guest/services/paynow-service';
import { showErrorStatus } from 'src/security/state/user-slice';
import { UserInfo } from 'src/admin/models/user-info';
import {adminUserHelper, userService} from 'src/admin/services/admin-user-service';
import { ApiBatchLimits } from 'src/shared/enums';
import { UserInfoClient } from 'src/admin/models/user-info-client';
import { axiosSaveHelper } from 'src/shared/service/axios-save-helper';

interface DownPaymentModalProps {
  open: boolean;
  handleModalClose: () => void;
}

export const paymentToggleTypes = {
  CREDIT_CARD: 'Credit Card',
  ACH: 'ACH',
  PAID_AT_FACILITY: 'Paid at Facility'
};

export function DownPaymentModal(props: DownPaymentModalProps) {
  const {open, handleModalClose} = props;
  const dispatch = useDispatch<AppDispatch>();

  const [enableSaveButton, setEnableSaveButton] = useState(false);
  const [isStripeEmpty, setIsStripeEmpty] = useState(true); // stripe input field error / empty state
  const [paymentType, setPaymentType] = useState('');
  const [receiptForEmail, setReceiptForEmail] = useState<string>('');
  const [selectedChampionName, setSelectedChampionName] = useState<string>('');

  const stateFields = {
    stripeCardToken: useSelector(
      (state: RootState) => state.patientContext.downPaymentTokens.token
    ),
    stripeRecurringCardToken: useSelector(
      (state: RootState) => state.patientContext.recurringPaymentTokens.token
    ),
    stripeBankToken: useSelector(
      (state: RootState) => state.patientContext.downPaymentTokens.bankToken
    ),
    stripeRecurringBankToken: useSelector(
      (state: RootState) => state.patientContext.recurringPaymentTokens.bankToken
    ),
    selectedEncounter: useSelector(
      (state: RootState) => state.patientContext.selectedEncounter
    ),
    selectedPatient: useSelector(
      (state: RootState) => state.patientContext.selectedPatient
    ),
    patientPaymentProgram: useSelector((state: RootState) => {
      return state?.patientContext?.selectedEncounter?.patientPaymentProgram?.length > 0 ?
        state.patientContext.selectedEncounter.patientPaymentProgram[state.patientContext.selectedEncounter.patientPaymentProgram.length - 1] : {} as PatientPaymentProgram
    }),
    transactions: useSelector((state: RootState) => {
      return state.patientContext.selectedEncounter?.patientTransactions?.length > 0 && state.patientContext.selectedEncounter.patientTransactions
    }),
    paymentMethods: useSelector((state: RootState) => state.patientContext.selectedEncounter?.patientPaymentMethods?.length > 0
      && state.patientContext.selectedEncounter.patientPaymentMethods),
    missingAddressError: useSelector((state: RootState) => state.patientContext.isError.missingAddress),
    allClientsMap: useSelector((state: RootState) => state.implementationContext?.implementationSpecialistClient?.allClientsMap),
  }

  const {
    stripeCardToken,
    stripeRecurringCardToken,
    stripeBankToken,
    stripeRecurringBankToken,
    selectedEncounter,
    patientPaymentProgram,
    selectedPatient,
    transactions,
    missingAddressError,
    paymentMethods,
    allClientsMap
  } = stateFields;

  const doAnyAchPaymentsOrPaymentMethodsExist = patientHelper.checkNonRefundedAchTransactionsOrPaymentMethods(transactions, paymentMethods)
  let downPayments = transactions ? patientHelper.getNonRefundedDownPayments(transactions) : [];
  const isZeroDown = selectedEncounter?.patientPaymentProgram?.length > 0 && selectedEncounter?.patientPaymentProgram?.[selectedEncounter?.patientPaymentProgram.length - 1].downPmtAmt === 0;

  const paymentProgramVersion = allClientsMap.get(selectedEncounter.clientId)?.paymentProgramVersion
  const isPaymentProgramVersionGreaterOrEqualTo4 = typeof paymentProgramVersion === 'number' && paymentProgramVersion >= 4

  useEffect(() => {
    // eslint-disable-next-line
    downPayments = transactions ? patientHelper.getNonRefundedDownPayments(transactions) : [];
  }, [transactions])

  const formRef: any = useRef();

  const patientsAndChampionsList = Utils.getPatientsAndChampionsList(selectedPatient, selectedEncounter, false); // payor can be anyone in this list
  const getReceiptEmail = (id: string) => {
    patientsAndChampionsList.forEach((item: { id: number, email: string, name: string }) => {
      if (item.id === parseInt(id)) {
        setReceiptForEmail(item.email);
        setSelectedChampionName(item.name?.replace(/\s{2,}/g, ' '));
      }
    })
  }

  const getPaymentInfo = () => {
    if (paymentType === paymentToggleTypes.CREDIT_CARD) {
      return {
        paymentMethod: PAYMENT_METHOD_TYPES.CARD,
        paymentTypeObj: PAYMENT_TYPES.DOWN_PAYMENT
      }
    } else if (paymentType === paymentToggleTypes.ACH) {
      return {
        paymentMethod: PAYMENT_METHOD_TYPES.ACH,
        paymentTypeObj: PAYMENT_TYPES.DOWN_PAYMENT
      }
    } else if (paymentType === paymentToggleTypes.PAID_AT_FACILITY) {
      return {
        paymentMethod: undefined, // no payment method needed for 'paid at facility' type
        paymentTypeObj: PAYMENT_TYPES.AT_FACILITY
      }
    }
    return {
      paymentMethod: undefined,
      paymentTypeObj: PAYMENT_TYPES.DOWN_PAYMENT
    }
  }

  const mapToDownPayment = () => {
    const currentVals = formRef?.current?.values;
    const currentDateTime = new Date();

    const {paymentMethod, paymentTypeObj} = getPaymentInfo();
    return {
      paymentId: 0, // always 0 when we are POSTing a new payment. use paymentID for release payment
      isManual: true, // hardcoded
      isAuthExpired: false, // hardcoded

      paymentGroupId: `${patientPaymentProgram?.patientPaymentSchedule?.patientPaymentScheduleId}`,
      patientPaymentScheduleId: patientPaymentProgram?.patientPaymentSchedule?.patientPaymentScheduleId,
      patientId: selectedEncounter.patientId,
      patientEncounterId: selectedEncounter.patientEncounterId,
      receiptEmail: receiptForEmail,
      paymentMethods: [{
        ...paymentMethod,
        payorId: currentVals?.payorName === selectedPatient?.patientId ? "" : currentVals?.payorName, // don't send if same as Patient Id
        externalPaymentId: (paymentMethod?.externalPaymentMethodId === PAYMENT_METHOD_TYPES.CARD.externalPaymentMethodId)
          ? stripeCardToken : (paymentMethod?.externalPaymentMethodId === PAYMENT_METHOD_TYPES.ACH.externalPaymentMethodId) ? stripeBankToken : '',
        nameOnCard: paymentType === paymentToggleTypes.CREDIT_CARD ? currentVals?.nameOnCard : Utils.getPayorName(
          {
            payorId: parseInt(currentVals?.payorName),
            patientId: selectedPatient?.patientId,
            selectedPatient: selectedPatient,
            selectedEncounter: selectedEncounter
          }
        )
      }],
      paymentAmt: parseFloat(currentVals?.amount),
      paymentType: paymentTypeObj,
      paymentInitDt: currentDateTime.toISOString(),
      isAuthOnly: true,
    } as Payment;
  }

  const mapToRecurringPayment = () => {
    const currentVals = formRef?.current?.values;
    const paymentDay = new Date(currentVals?.paymentDay || '').setUTCHours(23, 59, 59, 999);
    const paymentDayIso = new Date(paymentDay || '').toISOString();

    const paymentMethods = [{
      paymentMethodId: 0,
      payorId: currentVals?.payorName === selectedPatient?.patientId || currentVals?.payorName === "-1" ? null : currentVals?.payorName,
      paymentAmt: patientPaymentProgram?.patientPaymentSchedule?.paymentDueAmt,
      scheduleStartDt: paymentDayIso,
      externalPaymentMethodId: currentVals?.paymentType === paymentToggleTypes.CREDIT_CARD ? PAYMENT_METHOD_TYPES.CARD.externalPaymentMethodId : PAYMENT_METHOD_TYPES.ACH.externalPaymentMethodId,
      paymentMethodTypeId: currentVals?.paymentType === paymentToggleTypes.CREDIT_CARD ? PAYMENT_METHOD_TYPES.CARD.paymentMethodTypeId : PAYMENT_METHOD_TYPES.ACH.paymentMethodTypeId,
      externalPaymentId: currentVals?.paymentType === paymentToggleTypes.CREDIT_CARD ? stripeRecurringCardToken : stripeRecurringBankToken,
      receiptEmail: receiptForEmail,
      nameOnCard: currentVals?.paymentType === paymentToggleTypes.CREDIT_CARD ? currentVals?.nameOnCard : Utils.getPayorName(
        {
          payorId: parseInt(currentVals?.payorName),
          patientId: selectedPatient?.patientId,
          selectedPatient: selectedPatient,
          selectedEncounter: selectedEncounter
        }
      )
    }] as PaymentMethod[]
    const paymentType = PAYMENT_TYPES.RECURRING

    return {
      paymentMethods: paymentMethods,
      paymentType: paymentType
    } as PaymentDetail
  }

  useEffect(() => {
    if (!open && stripeCardToken) { // cleanup token state
      dispatch(setDownPaymentToken({}))
    }
  }, [dispatch, open, stripeCardToken])

  useEffect(() => {
    if (!open && stripeRecurringCardToken) {
      dispatch(setRecurringPaymentToken({}))
    }
  }, [dispatch, open, stripeRecurringCardToken])

  const getValidationSchema = (paymentType: string, isZeroDown: boolean) => {
    let schema;
    if (paymentType === paymentToggleTypes.CREDIT_CARD) {
      schema = patientDownPaymentCreditCard;
    } else if (paymentType === paymentToggleTypes.ACH) {
      schema = patientDownPaymentACH;
    } else if (paymentType === paymentToggleTypes.PAID_AT_FACILITY) {
      schema = {
        ...patientDownPaymentPaidAtFacility,
        amount: Yup.number().min(isZeroDown ? 0 : 1),
      }
    } else {
      schema = patientDownPaymentCreditCard;
    }
    return schema;
  }

  const isPatient = selectedEncounter?.patientChampion?.filter(x => x.isGuarantor).length <= 0;

  const signersId = selectedEncounter?.patientChampion?.filter(x => x.isGuarantor).length <= 0 ?
    selectedEncounter?.patientId : selectedEncounter?.patientChampion?.filter(x => x.isGuarantor)[0]?.patientChampionId || selectedEncounter?.patientId;

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async function handleCreateSPARequest(downPaymentInfo: Payment) {
    if (patientPaymentProgram?.patientPaymentSchedule?.paymentFreq === 'F' && selectedEncounter?.pfrAmt === downPaymentInfo?.paymentAmt) {
      await dispatch(createSPAHelloSignRequest({
        pfrAmount: selectedEncounter?.pfrAmt,
        paymentTotal: downPaymentInfo?.paymentAmt,
        clientId: selectedEncounter?.clientId,
        facilityId: selectedEncounter?.facilityId,
        encounterId: selectedEncounter?.patientEncounterId,
        signersId: signersId,
        isPatient: isPatient,
        isFullPay: patientPaymentProgram?.isPaidInFull,
      }));
    }
  }

  const checkAndCreateUser = async (userRecord: any) => {
    if (!isPaymentProgramVersionGreaterOrEqualTo4) return;
    
    // Base client scope object
    const userClientScopeToSave = {
      clientId: selectedEncounter?.clientId,
      isFacilityLevel: true,
      clientName: allClientsMap?.get(selectedEncounter?.clientId)?.clientName || '',
      allowedFacilities: []
    };

    if (!userRecord) {
      // Create new user if none exists
      const userToCreate = {
        userName: receiptForEmail,
        firstName: selectedChampionName.split(' ')[0],
        lastName: selectedChampionName.split(' ')[1] || '', // Handle case where there's no last name
        userScope: "FINPAY",
        clientId: selectedEncounter?.clientId,
        entityId: 0,
        isActive: true,
        isPatient: true,
        convRunNum: 1,
        userRole: {
          userRoleId: 4,
          roleName: "Account Holder",
          roleDesc: "User Role for Patients or Patient Guarators",
          roleType: "E",
          isActive: true
        },
        allowedClients: [userClientScopeToSave],
        patientEncounterId: selectedEncounter.patientEncounterId
      };
      await userService.createOrUpdateUser(userToCreate);
      return;
    }

    // User exists - check if they need client access added
    const existingClientAccess = userRecord.clients?.find((allowedClient: any) => 
      allowedClient.clientId === selectedEncounter?.clientId
    );

    if (!existingClientAccess) {
      // Update isFacilityLevel for existing clients if null
      const updatedClients = (userRecord.clients || []).map((client: any) => {
        // Skip updating if isFacilityLevel is already set (non-null)
        if (client.isFacilityLevel === null) {
          return {
            ...client,
            isFacilityLevel: true, // Update to true if null
          };
        }
        return client; // Keep unchanged if isFacilityLevel is already set
      });
      // Add new client access while preserving existing ones
      await axiosSaveHelper({
        dataToSave: {
          allowedClients: [
            ...updatedClients,
            {
              ...userClientScopeToSave,
              userId: userRecord.userId // Include userId only for existing user
            }
          ]
        },
        dataId: userRecord.userId,
        url: "user/v2/user",
      });
    }
  };
  
  async function handleSave() {

    const userRecord = (await userService.getAllUsers(0, ApiBatchLimits.users, { userName: receiptForEmail || '' }))?.entity?.[0];
    // Case: User exists but is not a patient
    if (userRecord && !userRecord.isPatient) {
      dispatch(showErrorStatus("Finpay Admin user already exists with this username. Please try another email"));
      handleModalClose();
      return;
    }

    if ((paymentType !== paymentToggleTypes.CREDIT_CARD && stripeBankToken) || ((paymentType === paymentToggleTypes.CREDIT_CARD) && stripeCardToken)) { // handles ACH and Credit card scenarios.
      if ((formRef?.current?.values?.recurringPayment === true) && // when we are also saving a recurring payment as well as down payment.
        ((paymentType !== paymentToggleTypes.CREDIT_CARD && stripeRecurringBankToken) ||
          ((paymentType === paymentToggleTypes.CREDIT_CARD) && stripeRecurringCardToken))) { // for payment types of Credit or ACH
        const downPaymentInfo = mapToDownPayment();
        const recurringPaymentInfo = mapToRecurringPayment();
        const response = await dispatch(
          makePayment({ payment: downPaymentInfo })
        );
        if (response?.type !== "patientContext/makePayment/rejected") {
          await dispatch(putProgram({
            patientPaymentProgramId: selectedEncounter.patientPaymentProgram?.[0]?.patientPaymentProgramId || 0,
            paymentProgram: {
              patientPaymentProgramId: selectedEncounter.patientPaymentProgram?.[0]?.patientPaymentProgramId || 0,
              patientId: selectedEncounter.patientId,
              patientEncounterId: selectedEncounter.patientEncounterId,
              patientPaymentSchedule: {
                nextPaymentDueDt: recurringPaymentInfo.paymentMethods[0].scheduleStartDt,
              }
            },
            updateState: true,
          }));
          await dispatch(
            addPatientPaymentMethod({
              patientId: selectedEncounter?.patientId,
              encounterId: selectedEncounter?.patientEncounterId,
              paymentDetail: recurringPaymentInfo,
              isAccountHolder: false,
            })
          );
          await checkAndCreateUser(userRecord);
        }
      } else {
        const downPaymentInfo = mapToDownPayment();
        const response = await dispatch(makePayment({payment: downPaymentInfo}));
        if (response?.type !== 'patientContext/makePayment/rejected') {
          await dispatch(putProgram({
            patientPaymentProgramId: patientPaymentProgram.patientPaymentProgramId,
            paymentProgram: {},
            updateState: true,
          }));
          if(!isPaymentProgramVersionGreaterOrEqualTo4) await handleCreateSPARequest(downPaymentInfo);
          await checkAndCreateUser(userRecord);
        }
      }
    } else if (paymentType === paymentToggleTypes.PAID_AT_FACILITY) { // handles the PAID AT FACILITY payment type.
      const downPaymentInfo = mapToDownPayment();
      const response = await dispatch(makePayment({payment: downPaymentInfo}));
      if (response?.type !== 'patientContext/makePayment/rejected') {
        await dispatch(putProgram({
          patientPaymentProgramId: patientPaymentProgram.patientPaymentProgramId,
          paymentProgram: {},
          updateState: true,
        }));
        await checkAndCreateUser(userRecord);
      }
    }

    handleModalClose();
  }

  const stripeTokenCallback = (data: { token?: string, cardToken?: string, bankToken?: string }) => {
    const {token, cardToken, bankToken} = data
    if (token && cardToken) {
      dispatch(setDownPaymentToken({token: token, cardToken: cardToken}))
    } else if (bankToken) {
      dispatch(setDownPaymentToken({bankToken: bankToken}))
    }
  }

  const stripeRecurringPaymentTokenCallback = (data: { token?: string, cardToken?: string, bankToken?: string }) => {
    const {token, cardToken, bankToken} = data
    if (token && cardToken) {
      dispatch(setRecurringPaymentToken({token: token, cardToken: cardToken}))
    } else if (bankToken) {
      dispatch(setRecurringPaymentToken({bankToken: bankToken}))
    }
  }

  const stripeDisabledCallback = (isDisabledFromStripe: boolean) => {
    setIsStripeEmpty(isDisabledFromStripe)
  };

  function handleSaveCallback() {
    handleModalClose()
  }

  const getMaxPaymentAmt = () => {
    let existingPaymentsSum = 0;
    downPayments && downPayments?.length > 0 && downPayments.forEach((downPayment: Payment) => {
      existingPaymentsSum += downPayment.paymentAmt;
    })
    return patientPaymentProgram?.downPmtAmt - existingPaymentsSum;
  }

  const maxPaymentAmt = downPayments && downPayments?.length > 0 ? getMaxPaymentAmt() : patientPaymentProgram?.downPmtAmt
  const initialValues = {
    payorName: "-1",
    amount: maxPaymentAmt,
    paymentType: isZeroDown ? paymentToggleTypes.PAID_AT_FACILITY : paymentToggleTypes.CREDIT_CARD,
    nameOnCard: "",
    bankName: "",
    routingNumber: "",
    accountNumber: "",
    retypeAccountNumber: "",
    paymentDay: "",
    recurringPayment: false,
  };

  interface DownPaymentsShape {
    payorName: string,
    amount: number,
    paymentType: string
    nameOnCard?: string,
    bankName?: string,
    routingNumber?: string,
    accountNumber?: string,
    retypeAccountNumber?: string,
  }

  function checkIfIsValid(value: DownPaymentsShape) {
    const schema = Yup.object(getValidationSchema(value?.paymentType, isZeroDown))
    if (schema && schema?.validate) {
      if ((value?.paymentType !== paymentType) && stripeCardToken) {
        dispatch(setDownPaymentToken({})) // need to reset stripe token each time we change 'use existing source'
      }
      setPaymentType(value?.paymentType);
      schema
        .validate(value)
        .then(() => {
          if (value.amount > maxPaymentAmt) {
            setEnableSaveButton(false);
          } else {
            setEnableSaveButton(true);
          }
        })
        .catch(() => {
          setEnableSaveButton(false);
        });
    }
  }

  useEffect(() => {
    checkIfIsValid(formRef?.current?.values)
    // eslint-disable-next-line
  }, [formRef?.current?.values, formRef?.current?.values?.paymentType, paymentType]);

  function getDateInFuture(days: number) {
    let todayFullDate = new Date();
    const todayDayOfMonth = todayFullDate.getDate();
    return todayFullDate.setDate(todayDayOfMonth + days);
  }

  const canUseForRecurringPayment = (paymentType: string, payorName: string, formik: CustomFormikProps) => {
    const canUseForRecurringPayment = (paymentType !== paymentToggleTypes.PAID_AT_FACILITY) && (Utils.checkIfSelectedPayorIsGuarantor(
      parseInt(payorName),
      patientsAndChampionsList,
    ))

    if (!canUseForRecurringPayment && formik?.values.recurringPayment) {
      // reset the 'can use for recurring payment' toggle to false if the selected payor isn't the guarantor or patient
      formik?.setFieldValue('recurringPayment', false)
    }
    return canUseForRecurringPayment
  }

  return (
    <Dialog
      scroll="body"
      className="modal client-modal"
      open={open}
      fullWidth={true}
      maxWidth="md"
    >
      <DialogTitle>
        <span className="title">Down Payment</span>
      </DialogTitle>
      <DialogContent>
        <Formik
          enableReinitialize
          innerRef={formRef}
          initialValues={initialValues}
          validate={checkIfIsValid}
          onSubmit={() => {
          }}
        >
          {(formik) => (
            <Form autoComplete='off'>
              <Grid container spacing={2} className="icon-colors">
                {missingAddressError && (
                  <Grid item xs={12}>
                    <Box style={{border: '2px solid red', borderRadius: '5px'}}>
                      <Typography variant="subtitle2" color="error" className="pt-2 px-1">
                        Missing Address Information. Please fill out address information in the Demographics tab
                        before attempting to submit any payments.
                      </Typography>
                    </Box>
                  </Grid>
                )}
                <Grid item xs={8} className="mt-2">
                  <Typography variant="subtitle2">
                    Down Payment Amount
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Box display="flex" justifyContent="flex-end" className="pr-1 mt-2">
                    <Typography variant="subtitle2">
                      {formatNumberToUSD(maxPaymentAmt)}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="subtitle2">
                    Payor
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    select={true}
                    error={
                      formik.touched["payorName"] && formik.errors["payorName"]
                    }
                    label="Payor Name"
                    name="payorName"
                    value={formik.values.payorName}
                    placeholder="Select"
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                      formik.handleChange(e);
                      getReceiptEmail(e.target.value);
                      checkIfIsValid(formik.values)
                    }}
                    onBlur={formik.handleBlur}
                  >
                    <MenuItem value="-1">Select</MenuItem>
                    {patientsAndChampionsList.map((payor: { name: string, id: number }) => (
                      <MenuItem key={payor.id} value={payor.id}>
                        {payor.name}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={6}>
                  <Box display="flex" justifyContent="flex-end">
                    <TextField
                      error={
                        formik.touched["amount"] && formik.errors["amount"]
                      }
                      label="Amount"
                      startAdornment={<AttachMoneyIcon/>}
                      name="amount"
                      value={formik.values.amount}
                      onChange={(e: ChangeEvent<HTMLInputElement>) => {
                        formik.handleChange(e);
                        checkIfIsValid(formik.values)
                      }}
                      onBlur={formik.handleBlur}
                      disabled={isZeroDown}
                    />
                  </Box>
                </Grid>
                {(selectedEncounter.patientPaymentProgram[0].patientPaymentSchedule.paymentDueAmt !== 0) &&
                  (canUseForRecurringPayment(formik.values.paymentType, formik.values.payorName, formik)) && (
                    <>
                      <Grid item xs={12}>
                        {(!formik.values.recurringPayment && formik.values.paymentType === "ACH") && (
                          <Box style={{border: '2px solid red', borderRadius: '5px'}}>
                            <Typography variant="h4" color="error" className="py-2 px-1">
                              If you plan to use the current ACH bank account to make an ACH recurring payment, you
                              must select 'yes' on the Use For Recurring Payment toggle.
                              ACH will not be an option in the recurring payment modal if you make an ACH down
                              payment.
                            </Typography>
                          </Box>
                        )}
                      </Grid>
                      <Grid item xs={12}>
                        <Typography variant='h4'>
                          Use for Recurring Payment?
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Toggle
                          name="recurringPayment"
                          value={formik.values.recurringPayment}
                          formik={formik}
                          onClick={() => {
                            doAnyAchPaymentsOrPaymentMethodsExist && !formik.values?.recurringPayment &&
                            formik.setFieldValue('paymentType', 'Credit Card')
                          }}
                        />
                      </Grid>
                    </>
                  )}
                {formik.values.recurringPayment === true && (
                  <>
                    <Grid item xs={8} className="mt-2">
                      <Typography variant="subtitle2">
                        Recurring Payment Amount
                      </Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Box
                        display="flex"
                        justifyContent="flex-end"
                        className="pr-1 mt-2"
                      >
                        <Typography variant="subtitle2">
                          {`$${selectedEncounter.patientPaymentProgram[0].patientPaymentSchedule.paymentDueAmt}`}
                        </Typography>
                      </Box>
                    </Grid>
                  </>
                )}
                <Grid item xs={12}>
                  <Typography variant="subtitle2">
                    Payment Source
                  </Typography>
                </Grid>
                {(doAnyAchPaymentsOrPaymentMethodsExist) && (
                  <Grid item xs={12}>
                    <Box style={{border: '2px solid red', borderRadius: '5px'}}>
                      <Typography variant="h4" color="error" className="py-2 px-1">
                        ACH is disabled because an existing ACH transaction or payment method already exists.
                      </Typography>
                    </Box>
                  </Grid>
                )}
                <Grid item xs={12} style={{marginBottom: -12}} className="ml-2">
                  <Typography variant="h4">
                    Payment Type
                  </Typography>
                </Grid>
                <Grid item xs={12} className="mb-2">
                  <div className="buttongroup">
                    <input
                      id="1"
                      type="radio"
                      value="Credit Card"
                      name="paymentType"
                      checked={
                        formik.values.paymentType === "Credit Card"
                      }
                      onChange={(e: ChangeEvent<HTMLInputElement>) => {
                        formik.handleChange(e);
                        checkIfIsValid(formik.values)
                      }}
                      disabled={isZeroDown}
                    />
                    <label htmlFor="1">Credit Card</label>
                    <input
                      id="2"
                      type="radio"
                      value="ACH"
                      name="paymentType"
                      checked={
                        formik.values.paymentType === "ACH"
                      }
                      onChange={(e: ChangeEvent<HTMLInputElement>) => {
                        formik.handleChange(e);
                        checkIfIsValid(formik.values)
                      }}
                      disabled={isZeroDown || doAnyAchPaymentsOrPaymentMethodsExist}
                    />
                    <label htmlFor="2">ACH</label>
                    <input
                      id="3"
                      type="radio"
                      value="Paid at Facility"
                      name="paymentType"
                      checked={
                        formik.values.paymentType === "Paid at Facility"
                      }
                      onChange={(e: ChangeEvent<HTMLInputElement>) => {
                        formik.handleChange(e);
                        checkIfIsValid(formik.values)
                      }}
                    />
                    <label
                      htmlFor="3">{isZeroDown ? "Zero Down Payment" : "Paid at Facility"}</label>
                  </div>
                </Grid>
                {formik.values.paymentType === paymentToggleTypes.CREDIT_CARD && (
                  <>
                    <Grid item xs={6} aria-autocomplete='none'>
                      <TextField
                        autoComplete={false}
                        error={
                          formik.touched["nameOnCard"] && formik.errors["nameOnCard"]
                        }
                        label="Name on Card"
                        placeholder="Enter Name on Card"
                        name="nameOnCard"
                        value={formik.values.nameOnCard}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                          formik.handleChange(e);
                          checkIfIsValid(formik.values)
                        }}
                        onBlur={formik.handleBlur}
                      />
                    </Grid>
                    <Grid item xs={12} className="my-2" aria-autocomplete='none'>
                      <StripeCardField
                        disabledCallback={stripeDisabledCallback}
                        tokenCallback={stripeTokenCallback}
                        data={
                          {
                            name: formik.values.nameOnCard,
                            address_country: 'US'
                          }
                        }
                        recurringPayment={formik.values.recurringPayment}
                        recurringPaymentTokenCallback={stripeRecurringPaymentTokenCallback}
                      />
                    </Grid>
                    {formik.values.recurringPayment === true && (
                      <Grid item xs={4}>
                        <DatePicker
                          error={
                            Utils.isValidDate(formik.values.paymentDay) &&
                            formik.touched["paymentDay"] &&
                            formik.errors["paymentDay"]
                          }
                          label="Recurring Payment Date"
                          minDate={getDateInFuture(1)}
                          maxDate={getDateInFuture(60)}
                          daysToDisable={[29, 30, 31]}
                          value={formik.values.paymentDay}
                          onChange={(date: Date) => {
                            formik.setFieldValue("paymentDay", date.toISOString());
                          }}
                        />
                      </Grid>
                    )}
                  </>
                )}
                {formik.values.paymentType === paymentToggleTypes.ACH && (
                  <>
                    <Grid item xs={6} aria-autocomplete='none'>
                      <TextField
                        autoComplete={false}
                        error={
                          formik.touched["bankName"] && formik.errors["bankName"]
                        }
                        label="Bank Name"
                        name="bankName"
                        value={formik.values.bankName}
                        placeholder="Enter Bank Name"
                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                          formik.handleChange(e);
                          checkIfIsValid(formik.values)
                        }}
                        onBlur={formik.handleBlur}
                      />
                    </Grid>
                    <Grid item xs={6} aria-autocomplete='none'>
                      <TextField
                        autoComplete={false}
                        error={
                          formik.touched["routingNumber"] && formik.errors["routingNumber"]
                        }
                        label="Routing Number"
                        name="routingNumber"
                        value={formik.values.routingNumber}
                        placeholder="Enter Routing Number"
                        maxLength={9}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                          formik.handleChange(e);
                          checkIfIsValid(formik.values)
                        }}
                        onBlur={formik.handleBlur}
                      />
                    </Grid>
                    <Grid item xs={6} aria-autocomplete='none'>
                      <TextField
                        autoComplete={false}
                        error={
                          formik.touched["accountNumber"] && formik.errors["accountNumber"]
                        }
                        label="Account Number"
                        name="accountNumber"
                        value={formik.values.accountNumber}
                        placeholder="Enter Account Number"
                        maxLength={17}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                          formik.handleChange(e);
                          checkIfIsValid(formik.values)
                        }}
                        onBlur={formik.handleBlur}
                      />
                    </Grid>
                    <Grid item xs={6} aria-autocomplete='none'>
                      <TextField
                        autoComplete={false}
                        error={
                          formik.touched["retypeAccountNumber"] && formik.errors["retypeAccountNumber"]
                        }
                        label="Re-Enter Account Number"
                        name="retypeAccountNumber"
                        value={formik.values.retypeAccountNumber}
                        placeholder="Re Enter Account Number"
                        maxLength={17}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                          formik.handleChange(e);
                          checkIfIsValid(formik.values)
                        }}
                        onBlur={formik.handleBlur}
                      />
                    </Grid>
                    {formik.values.recurringPayment === true && (
                      <Grid item xs={4}>
                        <DatePicker
                          error={
                            Utils.isValidDate(formik.values.paymentDay) &&
                            formik.touched["paymentDay"] &&
                            formik.errors["paymentDay"]
                          }
                          label="Recurring Payment Date"
                          minDate={getDateInFuture(1)}
                          maxDate={getDateInFuture(60)}
                          daysToDisable={[29, 30, 31]}
                          value={formik.values.paymentDay}
                          onChange={(date: Date) => {
                            formik.setFieldValue("paymentDay", date.toISOString());
                          }}
                        />
                      </Grid>
                    )}
                    <Grid item xs={12} aria-autocomplete='none'>
                      <StripeBankField
                        tokenCallback={stripeTokenCallback}
                        bankData={
                          {
                            country: 'US',
                            currency: 'usd',
                            routing_number: formik.values.routingNumber,
                            account_number: (formik.values.accountNumber === formik.values.retypeAccountNumber) ? formik.values.retypeAccountNumber.padStart(12, '0') : '',
                            bank_name: formik.values.bankName,
                            account_holder_name: Utils.getPayorName(
                              {
                                payorId: parseInt(formik?.values?.payorName),
                                patientId: selectedPatient?.patientId,
                                selectedPatient: selectedPatient,
                                selectedEncounter: selectedEncounter
                              }
                            ),
                            account_holder_type: 'individual',
                          }
                        }
                        recurringPayment={formik?.values?.recurringPayment}
                        recurringPaymentTokenCallback={stripeRecurringPaymentTokenCallback}
                      />
                    </Grid>
                  </ >
                )}
              </Grid>
            </Form>
          )}
        </Formik>
      </DialogContent>
      <DialogActions>
        <DialogActionButton
          isEnabled={enableSaveButton && (((paymentType === paymentToggleTypes.CREDIT_CARD) && !isStripeEmpty && !missingAddressError && (!!stripeCardToken))
            || ((paymentType === paymentToggleTypes.ACH) && !!stripeBankToken) || (paymentType === paymentToggleTypes.PAID_AT_FACILITY))}
          savebuttonText='Save'
          saveStatus={saveCallbackStatus.none}
          executeSave={handleSave}
          handleCallbackSave={handleSaveCallback}
          handleCallbackCancel={handleModalClose}
        />
      </DialogActions>
    </Dialog>
  );
}
