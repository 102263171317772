import { Status } from '@finpay-development/shared-components';
import { Grid, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { formatNumberToUSD } from '../../../shared/calculators';
import { StatusColors } from '../../../shared/enums';
import { RootState } from '../../../shared/state/root-reducer';
import { admissionsAdvisorUtils } from '../../utils/admission-advisor-utils';

export function EstDetails() {
  const vobPatientState = useSelector(
    (state: RootState) => state.admissionsAdvisorContext.vobPatientContext
  );

  const vobState = useSelector(
    (state: RootState) => state.admissionsAdvisorContext.vobContext.vob
  );

  const estimate = useSelector(
    (state: RootState) =>
      state.admissionsAdvisorContext.estimatorContext.estimate
  );

  const lengthOfStay = estimate?.summary?.lengthOfStayCovered ?? 0;

  const totalEstimatedPFR = estimate?.summary?.totalPFR ?? 0;

  return (
    <>
        <Grid container spacing={2}>

          <Grid item direction="column" md={4} sm={4}>
            <strong>
              {vobPatientState?.patient?.firstName}{" "}
              {vobPatientState?.patient?.lastName}
            </strong>
            /{vobPatientState?.patient?.advisorPatientId}
            <Typography variant="body2">
              {vobState?.payer?.payorName}/{vobState?.plan?.planName}
            </Typography>
          </Grid>
          <Grid item direction="column" md={4} sm={4} >
            {vobState.client.clientName}
            <Typography variant="body2">{vobState.facility.facilityName}</Typography>
          </Grid>
          <Grid item direction="column" md={4} sm={4}>
            <strong>{formatNumberToUSD(totalEstimatedPFR)}</strong>
            <Typography variant="body2">EST. PFR</Typography>
          </Grid>
        </Grid>
				<Grid container spacing={2} style={{marginTop: '1px'}}>
				  <Grid item direction="column" md={4} sm={4}>
            {!vobState?.selfPay && (
              <Status
                text={admissionsAdvisorUtils.isPolicyActive(vobState) ? "Active Policy" : "Inactive Policy"}
                statusColor={
                  admissionsAdvisorUtils.isPolicyActive(vobState) ? StatusColors.success : StatusColors.error
                }
              />
            )}
          </Grid>
					<Grid item direction="column" md={4} sm={4}>
            {!vobState?.selfPay && (
              <Status
                text={vobState?.payer?.inNetwork? "In Network" : "Out of Network"}
                statusColor={
                  vobState?.payer?.inNetwork? StatusColors.success : StatusColors.error
                }
              />
            )}
					</Grid>
					<Grid item direction="column" md={4} sm={4}>
					<Typography variant="body2">LOS {lengthOfStay} days</Typography>
					</Grid>
				</Grid>

</>
  );
}
