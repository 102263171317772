import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../shared/state/root-reducer";
import { formatNumberToUSD } from "../../../shared/calculators";
import {
  Box,
  Grid,
  Typography,
  Checkbox,
  FormControlLabel,
  IconButton
} from "@mui/material";
import { admissionsAdvisorUtils } from "../../utils/admission-advisor-utils";
import { AppDispatch } from "../../../shared/state/store";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import PriorCareSummaryModal from "./estimator-prior-care-summary-modal";
import { CrossoverSelectionEnum, SummarySelections } from "@finpay/estimation-types";
import { Utils } from "../../../shared/utils";
import { callNewEstimatePut } from "../../../admissions-advisor/state/estimator-thunk";

export function SidebarPriorCareAdjustment() {
  const [priorCareSummaryModalOpen, setPriorCareSummaryModalOpen] = useState(false);

  const state = {
    vobState: useSelector(
      (state: RootState) => state.admissionsAdvisorContext.vobContext.vob
    ),
    estimate: useSelector(
      (state: RootState) =>
          state.admissionsAdvisorContext.estimatorContext.estimate
    ),
  };

  const { vobState, estimate } = state;

  const dispatch = useDispatch<AppDispatch>();

  const newPFRName = SummarySelections.PRIORCARE;

  const getNewSideBarColor = (): string => {
      let sideBarColor = (estimate?.summarySelection === newPFRName)? "#6EACDE" : "#999999";
      return sideBarColor;
  };

  const handleSetSummarySelection = () => {
      if (estimate?.summarySelection !== newPFRName) {
          let estimateClone = Utils.deepClone(estimate);
          estimateClone = {
              ...estimateClone,
              summarySelection: newPFRName,
          };
          const postEstimateBody = admissionsAdvisorUtils.mapToNewEstimate(
              vobState.vobId!,
              vobState.client?.clientId!,
              vobState.facility?.facilityId!,
              estimateClone,
              Number(estimate?.advisorPatientId!),
              estimate?.patientEncounterId as string
          );
          dispatch(
              callNewEstimatePut({
                  estimate: postEstimateBody,
                  estimateId: estimateClone.estimateId,
              })
          );
      }
  };

  const openSummaryModal = () => {
    setPriorCareSummaryModalOpen(true);
  }

  const handlePriorCareSummaryModalCancel = () => {
    setPriorCareSummaryModalOpen(false);
  };

  const priorCareText = `Patient Financial Responsibility with Prior Care${
    estimate?.isPlanYearCrossover
        ? estimate?.crossoverSelection ===
          CrossoverSelectionEnum.BEFORE
            ? ' before Crossover'
            : ' with Crossover'
        : ''}`

  return (
      <>
          {
              estimate &&
              estimate.priorCareSummary && (
                  <>
                      <Box
                          border={1}
                          borderColor={getNewSideBarColor()}
                          style={{
                              backgroundColor: 'white',
                              marginBottom: '1.5em',
                          }}
                      >
                          <Grid container direction="row">
                              <Grid item direction="column" md={12}></Grid>
                          </Grid>
                          <Grid container direction="row">
                              <Grid
                                  item
                                  direction="column"
                                  md={12}
                                  xs={12}
                                  style={{
                                      color: 'white',
                                      backgroundColor: getNewSideBarColor(),
                                      padding: '0.25em',
                                  }}
                              >
                                  {priorCareText}
                              </Grid>

                              <Grid
                                  item
                                  display="flex"
                                  justifyContent={'space-between'}
                                  direction="row"
                                  md={12}
                                  style={{
                                      padding: '1em',
                                  }}
                              >
                                  <Typography
                                      variant="body2"
                                      style={{float: 'left'}}
                                  >
                                      Estimated PFR Adjusted for Prior Care
                                  </Typography>
                                  <Box sx={{fontWeight: 'bold'}}>
                                      <FormControlLabel
                                          checked={
                                              estimate?.summarySelection! ===
                                              newPFRName
                                          }
                                          control={<Checkbox color="primary" />}
                                          id="totalAdjustedPFR"
                                          name="totalAdjustedPFR"
                                          label={
                                              <strong test-id="sidebar-prior-care-pfr-value">
                                                  {formatNumberToUSD(
                                                      estimate?.priorCareSummary
                                                          ?.totalPFR
                                                  )}
                                              </strong>
                                          }
                                          labelPlacement="start"
                                          style={{float: 'right'}}
                                          onChange={() => {
                                              handleSetSummarySelection();
                                          }}
                                      />
                                  </Box>
                              </Grid>
                              <Grid
                                  item
                                  direction="column"
                                  md={12}
                                  display="flex"
                                  justifyContent="center"
                                  alignItems="center"
                              >
                                  <IconButton
                                      color="primary"
                                      test-id="sidebar-prior-care-pfr-open-details"
                                      title="Open Details"
                                      style={{fontSize: '1.5rem'}}
                                      onClick={openSummaryModal}
                                  >
                                      <OpenInNewIcon fontSize="small" /> Open
                                      Details
                                  </IconButton>
                              </Grid>
                          </Grid>
                      </Box>
                      <PriorCareSummaryModal
                          open={priorCareSummaryModalOpen}
                          handleSummaryModalCancel={
                              handlePriorCareSummaryModalCancel
                          }
                          priorCare={estimate.priorCare!}
                          priorCareSummary={estimate.priorCareSummary}
                          summary={estimate.nonAdjustedSummary!}
                      />
                  </>
              )}
      </>
  );
}
