import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import {
    Box,
    Grid,
    IconButton,
} from '@mui/material';
import {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {RootState} from '../../../shared/state/root-reducer';
import {AppDispatch} from '../../../shared/state/store';
import CrossoverSelectionModal from './estimator-crossover-selection-modal';
import {
    CrossoverSelectionEnum,
} from '@finpay/estimation-types';
import {
    callNewEstimatePut,
} from 'src/admissions-advisor/state/estimator-thunk';

export function SidebarPlanYearCrossover() {
    const state = {
        estimate: useSelector(
            (state: RootState) =>
                state.admissionsAdvisorContext.estimatorContext.estimate
        ),
    };

    const { estimate } = state;

    const [isCrossoverSelectionModalOpen, setIsCrossoverSelectionModalOpen] =
        useState(false);

    const dispatch = useDispatch<AppDispatch>();

    const handleCallbackCancel = () => {
        setIsCrossoverSelectionModalOpen(false);
    };

    const handleSaveUpdateCrossover = async (
        selection: CrossoverSelectionEnum
    ) => {
        if (estimate) {
            dispatch(callNewEstimatePut({estimate: {...estimate, crossoverSelection: selection}, estimateId: estimate.estimateId}));
            setIsCrossoverSelectionModalOpen(false);
        }
    };

    return (
        <>
            {estimate && estimate.isPlanYearCrossover && (
                    <Box
                        border={1}
                        borderColor={"#6EACDE"}
                        style={{
                            backgroundColor: "#FEEA00",
                            marginBottom: '1.5em',
                        }}
                    >
                        <Grid container direction="row">
                            <Grid item direction="column" md={12}></Grid>
                        </Grid>
                        <Grid container direction="row">
                            <Grid
                                item
                                direction="column"
                                md={12}
                                xs={12}
                                style={{
                                    color: '#006fcf',
                                    backgroundColor: "#FEEA00",
                                    padding: '10px'
                                }}
                            >
                                Crossover Selection Options
                            </Grid>
                        </Grid>
                        <Grid container direction="row">
                            <Grid
                                item
                                direction="row"
                                md={12}
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                                style={{
                                    flexDirection: "column",
                                    padding: "1em 0px 0px 0px"
                                }}
                                sx={{
                                    padding: '1em',
                                }}
                            >
                                <IconButton
                                    color="primary"
                                    title="Open Crossover Selection"
                                    style={{
                                        fontSize: "1.5rem",
                                        width: "100%",
                                        backgroundColor: "#FEEA00",
                                        borderRadius: 0
                                    }}
                                    onClick={() => {
                                        setIsCrossoverSelectionModalOpen(true);
                                    }}
                                    test-id="crossover-selection-open-details"
                                >
                                    <OpenInNewIcon fontSize="small" />
                                    Open Crossover Selection
                                </IconButton>
                            </Grid>
                        </Grid>
                    </Box>
                )}

            {estimate && estimate.isPlanYearCrossover && (
                    <CrossoverSelectionModal
                        open={isCrossoverSelectionModalOpen}
                        estimate={estimate}
                        handleCallbackCancel={handleCallbackCancel}
                        handleSave={handleSaveUpdateCrossover}
                    />
                )}
        </>
    );
}
