import "../../../../scss/pages/admin/_admin-clients.scss";

import {
  DatePicker,
  DialogActionButton,
  TextField,
  Toggle,
} from "@finpay-development/shared-components";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import { Box, MenuItem, Tooltip, Typography } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import { format } from "date-fns";
import { Form, Formik } from "formik";
import { useEffect, useRef, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";

import { ImplementationFacility } from "../../../../implementation-specialist/components/implementation-clients/details/models/implementation-facility";
import {
  getClientFacilities,
  getClientFacilityById,
} from "../../../../implementation-specialist/state/facility/implementation-facility-thunk";
import { showErrorStatus } from "../../../../security/state/user-slice";
import { checkPermissions } from "../../../../security/components/access-control";
import { RolePageNames } from "../../../../security/model/role-page-names";
import { ClientStatusCardViewModel } from "../../../../shared/model/client-status-card";
import {
  RiskClass,
  riskClass,
  riskClassNameLookup,
} from "../../../../shared/model/risk-class";
import {
  PayorRisk,
  payorRisk,
  payorRiskNameLookup,
  TimingRisk,
  timingRiskNameLookup,
} from "../../../../shared/model/timing-and-payor-risk";
import { RootState } from "../../../../shared/state/root-reducer";
import { AppDispatch } from "../../../../shared/state/store";
import { Utils } from "../../../../shared/utils";
import { instanceOfCare } from "../../../../shared/validation/schemas";
import { clearStatus } from "../../../state/patient-slice";
import { getRiskClass, saveInstanceOfCare, saveVOBForFinpass, updateIOC } from "../../../state/patient-thunk";
import {
  emptyPatientEncounter,
  PatientEncounter,
} from "../../models/patient-encounter";
import { VobPostBody } from "src/admissions-advisor/models/vob";
import { paynowService as paymentService } from "src/guest/services/paynow-service";
import { callNewEstimatePut } from "src/admissions-advisor/state/estimator-thunk";
import { Estimate, SummarySelections } from "@finpay/estimation-types";
import { CrossoverSelectionEnum } from "@finpay/estimation-types";
import { QuoteMethod } from "@finpay/estimation-types";
import { admissionsAdvisorUtils } from "src/admissions-advisor/utils/admission-advisor-utils";

interface InstanceOfCareModalProps {
  open: boolean;
  isEdit: boolean;
  clientId: number;
  handleModalCancel: () => void;
  handleModalSubmit: () => void;
}

export function InstanceOfCareModal(props: InstanceOfCareModalProps) {
    const {open, isEdit, clientId, handleModalCancel, handleModalSubmit} =
        props;

    const [enableSaveButton, setEnableSaveButton] = useState(false);
    const [facilityDidChange ,setFacilityDidChange] = useState(false);
    const [selectedFacilityId, setSelectedFacilityId] = useState(-1);

    const formRef: any = useRef();
    const dispatch = useDispatch<AppDispatch>();

    const selectors = {
        clients: useSelector(
            (state: RootState) =>
                state.implementationContext.implementationSpecialistClient
                    .filteredByIsActive
        ),
        facilities: useSelector(
            (state: RootState) =>
                state.implementationContext.implementationFacility.facilities
        ),
        facility: useSelector(
            (state: RootState) =>
                state.implementationContext.implementationFacility.facility
        ),
        currentPatient: useSelector(
            (state: RootState) => state.patientContext.selectedPatient
        ),
        currentInstanceOfCare: useSelector(
            (state: RootState) => state.patientContext.selectedEncounter
        ),
        isClientsLoading: useSelector(
            (state: RootState) =>
                state.implementationContext.implementationSpecialistClient
                    .isLoading
        ),
        isFacilitiesLoading: useSelector(
            (state: RootState) =>
                state.implementationContext.implementationFacility.isLoading
        ),
        saveStatus: useSelector(
            (state: RootState) =>
                state.patientContext.instanceOfCareModalSaveStatus
        ),
        errorMessage: useSelector(
            (state: RootState) => state.patientContext.errorMessage
        ),
        isPreConverted: useSelector((state: RootState) => {
            return state.patientContext.isPreConverted;
        }),
        userProfile: useSelector((state: RootState) => {
            return state.userContext.userProfile;
        }),
        metaData: useSelector((state: RootState) => {
            return state.metaData;
        }),
        vob: useSelector(
            (state: RootState) => state.patientContext.selectedVOB
        ),
        estimate: useSelector(
            (state: RootState) => {
                return state?.admissionsAdvisorContext.estimatorContext.estimate
            }
        ),
        isEstimateFromFinAdvisor: useSelector(
            (state: RootState) => {
                const totalEstimatePfr = state?.admissionsAdvisorContext.estimatorContext.estimate?.summary?.totalPFR
                return Boolean(totalEstimatePfr && totalEstimatePfr >= 0)
            }
          ),
        currentPaymentProgramId: useSelector(
            (state: RootState) => state.patientContext.selectedEncounter.patientPaymentProgram[0]?.patientPaymentProgramId
        ),
    };
    const {
        clients,
        facilities,
        facility,
        currentPatient,
        isClientsLoading,
        saveStatus,
        errorMessage,
        userProfile,
        metaData,
        vob,
        estimate,
        isEstimateFromFinAdvisor,
        currentPaymentProgramId
    } = selectors;
    let {currentInstanceOfCare} = selectors;

    const [enableRiskClassDropDown, setEnableRiskClassDropDown] = useState(
        () => {
            if (
                Utils.isUserRoleEditableRiskClass(userProfile.userRole.roleName)
            )
                return false;
            return !!currentInstanceOfCare.autoCalcEnabled;
        }
    );

    const [disableFields, setDisableFields] = useState({
        iocModalFields: isClientsLoading,
        admissionDt: isClientsLoading,
    });

    const mapToSaveModel = () => {
        const currentValues = formRef.current.values;
        const currentDateTime = new Date();
        const admissionDtFormatted =
            currentValues.admissionDt !== null
                ? new Date(currentValues.admissionDt)
                : null;
        const dischargeDtFormatted =
            currentValues.dischargeDt !== null
                ? new Date(currentValues.dischargeDt)
                : null;

        const timingRiskToAdd = {
            timingRiskId: currentValues.timingRisk,
            timingRiskName: timingRiskNameLookup(currentValues.timingRisk),
        } as TimingRisk;

        const payorRiskToAdd = {
            payorRiskId: currentValues.payorRisk,
            payorRiskName: payorRiskNameLookup(currentValues.payorRisk),
        } as PayorRisk;

        const riskClassToAdd = {
            riskClassId: currentValues.riskClass,
            riskClassName: riskClassNameLookup(currentValues.riskClass),
        } as RiskClass;

        const { typeOfCare, ...updatedInstanceOfCare } = currentInstanceOfCare;

        return {
            ...updatedInstanceOfCare,
            patientEncounterId: currentValues.patientEncounterId,
            patientId: currentPatient.patientId,
            clientId: currentValues.provider,
            facilityId: currentValues.facility,
            clientsPatientAccountId:
                currentValues.clientsPatientAccountId === null
                    ? ''
                    : currentValues.clientsPatientAccountId,
            clientsPatientIOCId:
                currentValues.clientsPatientIOCId === null
                    ? ''
                    : currentValues.clientsPatientIOCId,
            pfrAmt: currentValues.pfrAmt,
            levelOfCare: currentValues.levelOfCare,
            timingRisk: timingRiskToAdd,
            payorRisk: payorRiskToAdd,
            riskClass: riskClassToAdd,
            admissionDt: Utils.isValidDate(admissionDtFormatted)
                ? admissionDtFormatted
                : null,
            dischargeDt: Utils.isValidDate(dischargeDtFormatted)
                ? dischargeDtFormatted
                : null,
            paidToPatient: currentValues.paidToPatient,
            ...(!enableRiskClassDropDown && {isRiskClassOverriden: isEdit}),
            /**
             * Removed linked user id from this field because the backend is not able to handle this field
             *  In the future, please do not add this back
             *  With love,
             *  Davis Perchik
             */
            ...(!currentValues.patientEncounterId && {instanceCreateDt: currentDateTime.toISOString()})
        } as PatientEncounter;
    };

    async function handleSave() {
        let encounterToSave = mapToSaveModel();
        if(!encounterToSave.levelOfCare) {
            delete encounterToSave.levelOfCare
        }

        await dispatch(saveInstanceOfCare({
            encounter: encounterToSave,
            showSnackbar: true,
            isPreConverted: selectors.isPreConverted,
            isNewEncounter: !isEdit ? !isEdit : false
        }))

        // reseting vob, estimate, and encounter fields
        if(isEdit && facilityDidChange && vob.vobId && vob.isValid) {
            const facility = facilities.flat().filter((facility) => {
                return facility.facilityId === encounterToSave.facilityId
            })

            const vobPostBody: VobPostBody = {
                advisorPatientId: currentPatient.advisorPatientId,
                fpClientId: Number(currentPatient.clientId),
                fpClientFacilityId: encounterToSave.facilityId,
                // reset payer/plan and set new facility
                vobBody: {
                    ...vob,
                    facility: {
                        facilityId: encounterToSave.facilityId,
                        facilityName: facility[0].facilityName,
                    },
                    payer: {
                        payorId: undefined,
                        payorName: '',
                        payorCode: '',
                        inNetwork: false,
                        payersList: [],
                    },
                    plan: {
                        payorPlanId: undefined,
                        planName: '',
                        planCode: '',
                        facilityPlanId: undefined,
                        pdrRate: undefined,
                        losDays: undefined,
                        payersPlanList: [],
                    }
                },
            }

            await dispatch(saveVOBForFinpass({vob: vobPostBody}));

            // reset the estimate since facility changed
            if(estimate && estimate?.estimateId && estimate?.vobId === vob.vobId) {
                const date = admissionsAdvisorUtils.formatDateTime(new Date());
                const estimateDescription = `Last Modified - ${date}`;

                const putEstimateBody: Estimate = {
                    description: estimate?.description || estimateDescription,
                    facilityLevelOfCare: [],
                    vobId: vob.vobId,
                    advisorPatientId: estimate.advisorPatientId,
                    clientId: estimate.clientId,
                    facilityId: encounterToSave.facilityId,
                    patientEncounterId: estimate.patientEncounterId!,
                    quoteMethod: estimate.quoteMethod || QuoteMethod.AVGLOS,
                    anticipatedAdmitDate: estimate.anticipatedAdmitDate,
                    isPlanYearCrossover: false,
                    isActive: true,
                    crossoverSelection: CrossoverSelectionEnum.BEFORE,
                    summarySelection: SummarySelections.NONADJUSTED
                };

                await dispatch(callNewEstimatePut({estimate: putEstimateBody, estimateId: estimate?.estimateId}));
            }

            // if the estimate is from finadvisor, only update the IOC
            // state for the user to start the estimate over again
            if(isEstimateFromFinAdvisor) {
                await dispatch(updateIOC({
                    workflowId: 2,
                    workflowStatusId: 20,
                    workflowSubStatusId: 7,
                    pfrAmt: 0,
                    pfrDiscount: 0,
                    preDiscountPfrAmt: 0
                }))

            }
        }

        if (currentPaymentProgramId != null) {
            await paymentService.updatePaymentProgram(currentPaymentProgramId);
        }
    }

    function handleSaveCallback(saveSuccessful: boolean) {
        if (saveSuccessful) {
            handleModalSubmit();
            setEnableSaveButton(false);
        } else {
            dispatch(showErrorStatus(errorMessage));
        }
        dispatch(clearStatus());
    }

    function handleCancelCallback() {
        handleModalCancel();
    }

    useEffect(() => {
        const newClientId = clientId === -1 ? Number(currentPatient.clientId!) : clientId
        dispatch(getClientFacilities(newClientId));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, clientId]);

    useEffect(() => {
        if (selectedFacilityId !== -1) {
            dispatch(getClientFacilityById(selectedFacilityId));
        }
    }, [dispatch, selectedFacilityId]);

    if (!isEdit) {
        currentInstanceOfCare = emptyPatientEncounter;
    }

    useEffect(() => {
        if (!currentInstanceOfCare?.isConverted) {
            setDisableFields({
                iocModalFields: false,
                admissionDt: false,
            });
        } else if (
            currentInstanceOfCare?.isConverted &&
            !currentInstanceOfCare?.admissionDt
        ) {
            setDisableFields({
                iocModalFields: true,
                admissionDt: false,
            });
        } else {
            setDisableFields({
                iocModalFields: true,
                admissionDt: true,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const arePostConvertFieldsDisabled = useMemo(() => {
        const permitted: boolean = checkPermissions(
            userProfile.userRole.userRolePages,
            RolePageNames.PatientRecords,
            'Edit IOC Post Convert',
            false
        );
        const {isConverted} = currentInstanceOfCare;
        if (permitted && isConverted) return false;
        return disableFields.iocModalFields;
    }, [userProfile, disableFields]);

    const initialValues = {
        patientEncounterId: isEdit
            ? currentInstanceOfCare.patientEncounterId
            : 0,
        provider: isEdit ? currentInstanceOfCare.clientId : currentPatient.clientId,
        facility: isEdit ? currentInstanceOfCare.facilityId : '-1',
        clientsPatientAccountId: isEdit
            ? currentInstanceOfCare.clientsPatientAccountId
            : null,
        clientsPatientIOCId: isEdit
            ? currentInstanceOfCare.clientsPatientIOCId
            : null,
        pfrAmt: isEdit ? currentInstanceOfCare.pfrAmt : '',
        levelOfCare: isEdit ? currentInstanceOfCare.levelOfCare : '',
        timingRisk: isEdit
            ? currentInstanceOfCare.timingRisk.timingRiskId
            : '-1',
        payorRisk: isEdit ? currentInstanceOfCare.payorRisk.payorRiskId : '-1',
        riskClass: isEdit ? currentInstanceOfCare.riskClass.riskClassId : '-1',
        admissionDt:
            isEdit && Utils.isValidDate(currentInstanceOfCare.admissionDt)
                ? Utils.convertDate(new Date(currentInstanceOfCare.admissionDt))
                : null,
        dischargeDt:
            isEdit && Utils.isValidDate(currentInstanceOfCare.dischargeDt)
                ? Utils.convertDate(new Date(currentInstanceOfCare.dischargeDt))
                : null,
        paidToPatient: isEdit ? currentInstanceOfCare.paidToPatient : false,
    };

    const {
        rcOutOfNetworkPFRHigh,
        rcOutOfNetworkPFRLow,
        rcInNetworkPFRHigh,
        rcInNetworkPFRLow,
    } = facility;

    const validationSchema = Yup.object(instanceOfCare);

    function checkIfIsValid(value: any) {
        validationSchema
            .validate(value)
            .then(() => {
                setEnableSaveButton(true);
            })
            .catch((e: any) => {
                setEnableSaveButton(false);
            });
    }

    const handleProviderChange = (e: Event) => {
        const selectedClientId = (e.target as HTMLInputElement).value;
        // const selectedClientName = (e.target as HTMLElement).dataset.clientName;
        dispatch(getClientFacilities(+selectedClientId));
    };

    const handleFacilityChange = (e: Event) => {
        const facilityId = +(e.target as HTMLInputElement).value
        setSelectedFacilityId(facilityId);
        if(facilityId !== currentInstanceOfCare.facilityId) {
            setFacilityDidChange(true)
        } else {
            setFacilityDidChange(false)
        }
    };

    const autoCalcRiskClass = async (
        autoCalcEnabled: boolean,
        isRiskClassOverriden: boolean,
        facilityId: number,
        paidToPatient: boolean,
        timingRiskName: string,
        payorRiskName: string,
        pfrAmt: number
    ) => {
        if (autoCalcEnabled && !isRiskClassOverriden) {
            const riskClassData: any = await dispatch(
                getRiskClass({
                    facilityId,
                    paidToPatient,
                    timingRiskName,
                    payorRiskName,
                    pfrAmt,
                })
            );
            if (riskClassData.payload !== 'no result' && formRef) {
                formRef.current.setFieldValue(
                    'riskClass',
                    riskClassData.payload.riskClassId
                );
            }
        }
    };

    const handlePFRChange = async (e: Event) => {
        const currentValues = formRef.current.values;
        const facilityId = currentValues.facility;
        const timingRiskName = timingRiskNameLookup(currentValues.timingRisk)!;
        const payorRiskName = payorRiskNameLookup(currentValues.payorRisk)!;
        const paidToPatient = currentValues.paidToPatient;
        const pfrAmt = +(e.target as HTMLInputElement).value;
        if (
            timingRiskName === undefined ||
            facilityId === undefined ||
            payorRiskName === undefined ||
            paidToPatient === undefined ||
            pfrAmt === undefined
        ) {
            return;
        } else {
            if (isEdit) {
                autoCalcRiskClass(
                    currentInstanceOfCare.autoCalcEnabled!,
                    currentInstanceOfCare.isRiskClassOverriden!,
                    facilityId,
                    paidToPatient,
                    timingRiskName,
                    payorRiskName,
                    pfrAmt
                );
            } else {
                if (
                    !!rcOutOfNetworkPFRHigh &&
                    !!rcOutOfNetworkPFRLow &&
                    !!rcInNetworkPFRHigh &&
                    !!rcInNetworkPFRLow
                ) {
                    autoCalcRiskClass(
                        currentInstanceOfCare.autoCalcEnabled!,
                        currentInstanceOfCare.isRiskClassOverriden!,
                        facilityId,
                        paidToPatient,
                        timingRiskName,
                        payorRiskName,
                        pfrAmt
                    );
                } else setEnableRiskClassDropDown(isEdit);
            }
        }
    };

    const handleTimingRiskChange = async (e: Event) => {
        const currentValues = formRef.current.values;
        const facilityId = currentValues.facility;
        const timingRiskId = (e.target as HTMLInputElement).value;
        const timingRiskName = timingRiskNameLookup(+timingRiskId)!;
        const payorRiskName = payorRiskNameLookup(currentValues.payorRisk)!;
        const paidToPatient = currentValues.paidToPatient;
        const pfrAmt = +currentValues.pfrAmt;
        if (
            timingRiskName === undefined ||
            facilityId === undefined ||
            payorRiskName === undefined ||
            paidToPatient === undefined ||
            pfrAmt === undefined
        ) {
            return;
        } else {
            if (isEdit) {
                autoCalcRiskClass(
                    currentInstanceOfCare.autoCalcEnabled!,
                    currentInstanceOfCare.isRiskClassOverriden!,
                    facilityId,
                    paidToPatient,
                    timingRiskName,
                    payorRiskName,
                    +pfrAmt
                );
            } else {
                if (
                    !!rcOutOfNetworkPFRHigh &&
                    !!rcOutOfNetworkPFRLow &&
                    !!rcInNetworkPFRHigh &&
                    !!rcInNetworkPFRLow
                ) {
                    autoCalcRiskClass(
                        currentInstanceOfCare.autoCalcEnabled!,
                        currentInstanceOfCare.isRiskClassOverriden!,
                        facilityId,
                        paidToPatient,
                        timingRiskName,
                        payorRiskName,
                        +pfrAmt
                    );
                } else setEnableRiskClassDropDown(isEdit);
            }
        }
    };

    const handlePayorRiskChange = async (e: Event) => {
        const currentValues = formRef.current.values;
        const facilityId = currentValues.facility;
        const payorRiskId = (e.target as HTMLInputElement).value;
        const payorRiskName = payorRiskNameLookup(+payorRiskId)!;
        const timingRiskName = timingRiskNameLookup(currentValues.timingRisk)!;
        const paidToPatient = currentValues.paidToPatient;
        const pfrAmt = +currentValues.pfrAmt;
        if (
            timingRiskName === undefined ||
            facilityId === undefined ||
            payorRiskName === undefined ||
            paidToPatient === undefined ||
            pfrAmt === undefined
        ) {
            return;
        } else {
            if (isEdit) {
                autoCalcRiskClass(
                    currentInstanceOfCare.autoCalcEnabled!,
                    currentInstanceOfCare.isRiskClassOverriden!,
                    facilityId,
                    paidToPatient,
                    timingRiskName,
                    payorRiskName,
                    pfrAmt
                );
            } else {
                if (
                    !!rcOutOfNetworkPFRHigh &&
                    !!rcOutOfNetworkPFRLow &&
                    !!rcInNetworkPFRHigh &&
                    !!rcInNetworkPFRLow
                ) {
                    autoCalcRiskClass(
                        currentInstanceOfCare.autoCalcEnabled!,
                        currentInstanceOfCare.isRiskClassOverriden!,
                        facilityId,
                        paidToPatient,
                        timingRiskName,
                        payorRiskName,
                        pfrAmt
                    );
                } else setEnableRiskClassDropDown(isEdit);
            }
        }
    };

    const handleToggleChange = async () => {
        const currentValues = formRef.current.values;
        const facilityId = currentValues.facility;
        const timingRiskName = timingRiskNameLookup(currentValues.timingRisk)!;
        const payorRiskName = payorRiskNameLookup(currentValues.payorRisk)!;
        const paidToPatient = !currentValues.paidToPatient;
        const pfrAmt = +currentValues.pfrAmt;
        if (
            timingRiskName === undefined ||
            facilityId === undefined ||
            payorRiskName === undefined ||
            paidToPatient === undefined ||
            pfrAmt === undefined
        ) {
            return;
        } else {
            if (isEdit) {
                autoCalcRiskClass(
                    currentInstanceOfCare.autoCalcEnabled!,
                    currentInstanceOfCare.isRiskClassOverriden!,
                    facilityId,
                    paidToPatient,
                    timingRiskName,
                    payorRiskName,
                    pfrAmt
                );
            } else {
                if (
                    !!rcOutOfNetworkPFRHigh &&
                    !!rcOutOfNetworkPFRLow &&
                    !!rcInNetworkPFRHigh &&
                    !!rcInNetworkPFRLow
                ) {
                    autoCalcRiskClass(
                        currentInstanceOfCare.autoCalcEnabled!,
                        currentInstanceOfCare.isRiskClassOverriden!,
                        facilityId,
                        paidToPatient,
                        timingRiskName,
                        payorRiskName,
                        pfrAmt
                    );
                } else setEnableRiskClassDropDown(isEdit);
            }
        }
    };

    return (
        <Dialog
            scroll="body"
            className="modal client-modal"
            open={open}
            fullWidth={true}
            maxWidth="md"
        >
            <DialogTitle>
                <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <Grid item xs={6}>
                        <span className="subtitle">
                            {isEdit ? 'Edit' : ''} Instance Of Care
                        </span>
                    </Grid>
                    <Grid item xs={6}>
                        <Box
                            display="flex"
                            width={'100%'}
                            alignItems={'center'}
                            justifyContent={'flex-end'}
                            className="pb-4"
                        >
                            <Typography variant="body1">
                                {isEdit
                                    ? `ID ${currentInstanceOfCare?.patientEncounterId}`
                                    : ''}
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
            </DialogTitle>
            <DialogContent>
            {isEdit && facilityDidChange && isEstimateFromFinAdvisor && (
                <Typography variant="caption" style={{color: "red"}}>
                    The facility has changed. Please update the VOB below, and Estimate in FinAdvisor after saving
                </Typography>)
            }
            {isEdit && facilityDidChange && vob.vobId && !isEstimateFromFinAdvisor && (
                <Typography variant="caption" style={{color: "red"}}>
                    The facility has changed. Please update the VOB below after saving
                </Typography>)
            }
                <Formik
                    innerRef={formRef}
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    validate={checkIfIsValid}
                    onSubmit={() => {}}
                >
                    {formik => (
                        <Form>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Typography variant="subtitle2">
                                        Treatment Provider
                                    </Typography>
                                </Grid>
                                <Grid xs={6} item className="mb-4">
                                    <TextField
                                        select={true}
                                        error={
                                            formik.touched['provider'] &&
                                            formik.errors['provider']
                                        }
                                        label="Provider"
                                        name="provider"
                                        value={formik.values.provider}
                                        disabled={true}
                                        onChange={(e: Event) => {
                                            formik.handleChange(e);
                                            handleProviderChange(e);
                                        }}
                                        onBlur={formik.handleBlur}
                                        test-id="ioc-modal-provider"
                                    >
                                        <MenuItem value="-1">
                                            Select Provider
                                        </MenuItem>
                                        {clients &&
                                            clients.map(
                                                (
                                                    client: ClientStatusCardViewModel
                                                ) => (
                                                    <MenuItem
                                                        key={client.clientId}
                                                        value={client.clientId}
                                                    >
                                                        {client.clientName}
                                                    </MenuItem>
                                                )
                                            )}
                                    </TextField>
                                </Grid>
                                <Grid xs={6} item className="mb-4">
                                    <TextField
                                        select={true}
                                        error={
                                            formik.touched['facility'] &&
                                            formik.errors['facility']
                                        }
                                        label="Facility"
                                        name="facility"
                                        value={formik.values.facility}
                                        disabled={arePostConvertFieldsDisabled}
                                        onChange={(e: Event) => {
                                            formik.handleChange(e);
                                            handleFacilityChange(e);
                                        }}
                                        onBlur={formik.handleBlur}
                                    >
                                        <MenuItem value="-1">
                                            Select Facility
                                        </MenuItem>
                                        {facilities
                                            .flat()
                                            .map(
                                                (
                                                    facility: ImplementationFacility
                                                ) => (
                                                    <MenuItem
                                                        key={
                                                            facility.facilityId
                                                        }
                                                        value={
                                                            facility.facilityId
                                                        }
                                                    >
                                                        {facility.facilityName}
                                                    </MenuItem>
                                                )
                                            )}
                                    </TextField>
                                </Grid>
                                <Grid xs={6} item>
                                    <TextField
                                        error={
                                            formik.touched[
                                                'clientsPatientAccountId'
                                            ] &&
                                            formik.errors[
                                                'clientsPatientAccountId'
                                            ]
                                        }
                                        label="Client's Patient Account ID"
                                        placeholder="Enter Patient Account Id"
                                        name="clientsPatientAccountId"
                                        value={
                                            formik.values
                                                .clientsPatientAccountId
                                        }
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        required={false}
                                        disabled={arePostConvertFieldsDisabled}
                                        test-id="ioc-modal-clients-patient-account-id"
                                    />
                                </Grid>
                                <Grid xs={6} item>
                                    <TextField
                                        error={
                                            formik.touched[
                                                'clientsPatientIOCId'
                                            ] &&
                                            formik.errors['clientsPatientIOCId']
                                        }
                                        label="Client's Patient IOC ID"
                                        name="clientsPatientIOCId"
                                        placeholder="Enter IOC ID"
                                        value={
                                            formik.values.clientsPatientIOCId
                                        }
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        required={false}
                                        disabled={arePostConvertFieldsDisabled}
                                        test-id="ioc-modal-clients-patient-ioc-id"
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="subtitle2">
                                        Patient Information
                                    </Typography>
                                </Grid>
                                <Grid xs={6} item className="mb-4">
                                    <TextField
                                        error={
                                            formik.touched['pfrAmt'] &&
                                            formik.errors['pfrAmt']
                                        }
                                        label="Estimated PFR"
                                        name="pfrAmt"
                                        placeholder="Enter Estimated PFR"
                                        value={formik.values.pfrAmt}
                                        onChange={(e: Event) => {
                                            formik.handleChange(e);
                                            handlePFRChange(e);
                                        }}
                                        onBlur={formik.handleBlur}
                                        startAdornment={<AttachMoneyIcon />}
                                        disabled={disableFields.iocModalFields}
                                        test-id="ioc-modal-pfr"
                                    />
                                </Grid>
                              {currentInstanceOfCare.levelOfCare && (
                                  <Grid xs={6} item className="mb-4">
                                    <TextField
                                        error={
                                            formik.touched["levelOfCare"] &&
                                            formik.errors["levelOfCare"]
                                        }
                                        label="Level of Care"
                                        name="levelOfCare"
                                        placeholder="Enter Level of Care"
                                        value={formik.values.levelOfCare}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        disabled={true}
                                    />
                                  </Grid>)}
                                <Grid xs={6} item className="mb-4">
                                    <TextField
                                        select={true}
                                        error={
                                            formik.touched['timingRisk'] &&
                                            formik.errors['timingRisk']
                                        }
                                        label="Timing Risk"
                                        name="timingRisk"
                                        value={formik.values.timingRisk}
                                        onChange={(e: Event) => {
                                            formik.handleChange(e);
                                            handleTimingRiskChange(e);
                                        }}
                                        onBlur={formik.handleBlur}
                                        disabled={arePostConvertFieldsDisabled}
                                    >
                                        <MenuItem value="-1">
                                            Select Timing Risk
                                        </MenuItem>
                                        {metaData.timingRisk.map(
                                            (timing: {
                                                timingRiskName: string;
                                                timingRiskId: number;
                                            }) => (
                                                <MenuItem
                                                    key={timing.timingRiskId}
                                                    value={timing.timingRiskId}
                                                >
                                                    {timing.timingRiskName}
                                                </MenuItem>
                                            )
                                        )}
                                    </TextField>
                                </Grid>
                                <Grid xs={6} item className="mb-4">
                                    <TextField
                                        select={true}
                                        error={
                                            formik.touched['payorRisk'] &&
                                            formik.errors['payorRisk']
                                        }
                                        label="Payer Risk"
                                        name="payorRisk"
                                        value={formik.values.payorRisk}
                                        onChange={(e: Event) => {
                                            formik.handleChange(e);
                                            handlePayorRiskChange(e);
                                        }}
                                        onBlur={formik.handleBlur}
                                        disabled={disableFields.iocModalFields}
                                    >
                                        <MenuItem value="-1">
                                            Select Payer Risk
                                        </MenuItem>
                                        {payorRisk.map(
                                            (payor: {
                                                payorRiskName: string;
                                                payorRiskId: number;
                                            }) => (
                                                <MenuItem
                                                    key={payor.payorRiskId}
                                                    value={payor.payorRiskId}
                                                >
                                                    {payor.payorRiskName}
                                                </MenuItem>
                                            )
                                        )}
                                    </TextField>
                                </Grid>
                                <Grid xs={12} item className="mb-4">
                                    <TextField
                                        select={true}
                                        error={
                                            formik.touched['riskClass'] &&
                                            formik.errors['riskClass']
                                        }
                                        label="Risk Class"
                                        name="riskClass"
                                        value={formik.values.riskClass}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        disabled={
                                            enableRiskClassDropDown ||
                                            disableFields.iocModalFields
                                        }
                                        test-id="ioc-modal-risk-class"
                                    >
                                        <MenuItem value="-1">
                                            Select Risk Class
                                        </MenuItem>
                                        {riskClass.map(
                                            (risk: {
                                                riskClassName: string;
                                                riskClassId: number;
                                            }) => (
                                                <MenuItem
                                                    key={risk.riskClassId}
                                                    value={risk.riskClassId}
                                                >
                                                    {risk.riskClassName}
                                                </MenuItem>
                                            )
                                        )}
                                    </TextField>
                                </Grid>
                                {currentInstanceOfCare.isRiskClassOverriden && (
                                    <Grid xs={12} item className="mb-4">
                                        <Tooltip
                                            title={
                                                <>
                                                    {currentInstanceOfCare.lastUpdateUserId && (
                                                        <div>
                                                            {'Admin Email: ' +
                                                                currentInstanceOfCare.lastUpdateUserId}
                                                        </div>
                                                    )}
                                                    {!!currentInstanceOfCare.lastUpdateDt && (
                                                        <div>
                                                            {'Updated Date: ' +
                                                                format(
                                                                    new Date(
                                                                        currentInstanceOfCare.lastUpdateDt
                                                                    ),
                                                                    'MMM dd yyyy h:mm a'
                                                                )}
                                                        </div>
                                                    )}
                                                </>
                                            }
                                        >
                                            <Typography
                                                variant="h4"
                                                className="mr-2"
                                            >
                                                Risk Class was modified by admin
                                            </Typography>
                                        </Tooltip>
                                    </Grid>
                                )}
                                <Grid xs={6} item>
                                    <DatePicker
                                        error={
                                            Utils.isValidDate(
                                                formik.values.admissionDt
                                            ) &&
                                            formik.touched['admissionDt'] &&
                                            formik.errors['admissionDt']
                                        }
                                        label="Admission Date"
                                        value={formik.values.admissionDt}
                                        onChange={(date: Date) => {
                                            formik.setFieldValue(
                                                'admissionDt',
                                                date
                                            );
                                        }}
                                        disabled={arePostConvertFieldsDisabled}
                                        minDate={Utils.getMinDate()}
                                        maxDate={Utils.getMaxDate()}
                                        test-id='ioc-modal-admission-date'
                                    />
                                    <div className="date-optional">
                                        Optional
                                    </div>
                                </Grid>
                                <Grid xs={6} item>
                                    <DatePicker
                                        error={
                                            Utils.isValidDate(
                                                formik.values.dischargeDt
                                            ) &&
                                            formik.touched['dischargeDt'] &&
                                            formik.errors['dischargeDt']
                                        }
                                        label="Discharge Date"
                                        value={formik.values.dischargeDt}
                                        onChange={(date: Date) => {
                                            formik.setFieldValue(
                                                'dischargeDt',
                                                date
                                            );
                                        }}
                                        disabled={arePostConvertFieldsDisabled}
                                        minDate={Utils.getMinDate()}
                                        maxDate={Utils.getMaxDate()}
                                    />
                                    <div className="date-optional">
                                        Optional
                                    </div>
                                </Grid>
                                <Grid
                                    xs={12}
                                    item
                                    container
                                    className="mb-6"
                                    alignItems="center"
                                >
                                    <Typography variant="h4" className="mr-2">
                                        Paid to Patient:
                                    </Typography>
                                    <Toggle
                                        name="paidToPatient"
                                        value={formik.values.paidToPatient}
                                        formik={formik}
                                        onChange={handleToggleChange}
                                        disabled={disableFields.iocModalFields}
                                    />
                                </Grid>
                            </Grid>
                        </Form>
                    )}
                </Formik>
            </DialogContent>
            <DialogActions>
                <DialogActionButton
                    isEnabled={enableSaveButton}
                    savebuttonText="Save"
                    saveStatus={saveStatus}
                    executeSave={handleSave}
                    handleCallbackSave={handleSaveCallback}
                    handleCallbackCancel={handleCancelCallback}
                    test-id='ioc-modal-save-btn'
                />
            </DialogActions>
        </Dialog>
    );
}
