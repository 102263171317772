import React, { useEffect, useState } from 'react';
import './login.scss';
import { Grid, Paper, Typography } from '@mui/material';
import ForgetPasswordNotification from './forgot-password-notification';
import UpdatePasswordView from './update-password-view';
import { sendForgetPasswordNotification } from '../state/user-thunk';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../shared/state/root-reducer';
import { AppDispatch } from '../../shared/state/store';
import {clearForm} from '../state/user-slice';
import {useNavigate} from 'react-router-dom';
import _ from 'lodash';

interface ForgetPasswordProps {
  showPasswordChange?: boolean;
}

function ForgetPassword(props: ForgetPasswordProps) {
     const [showPasswordChange, setShowPasswordChange] = useState(props.showPasswordChange || false);

     const userContextClearForm = useSelector(
        (state: RootState) => state.userContext.clearForm
      );

    useEffect(() => {
        if (userContextClearForm) {
            setShowPasswordChange(false);
        }
    }, [userContextClearForm]);

    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();

    async function sendPasswordNotification(email: string) {
        const response: any = await dispatch(sendForgetPasswordNotification({username: email}));
        if(response?.payload?.showPasswordChangeView){
          setShowPasswordChange(true);
        }else if(_.isBoolean(response?.payload?.showPasswordChangeView) && !response?.payload?.showPasswordChangeView){
          dispatch(clearForm(true));
          //Need to refresh the browser in order to clear out guest token
          //from intercept set inside sendForgetPasswordNotification thunk
          navigate(0);
        }
    }

    return (
        <div className="page-container login-container" slot="forgot-password">
            <main>
                <div className="content fullwidth loginflow">
                    <Grid container alignContent="center" justifyContent="center" direction="column" >
                        <Paper>
                            {!showPasswordChange &&
                                <ForgetPasswordNotification
                                    onClick={async(email) => {
                                      await sendPasswordNotification(email)
                                    }}
                                />
                            }
                            {showPasswordChange &&
                                <UpdatePasswordView
                                    type="forget"
                                />
                            }
                        </Paper>
                        <Grid item className="terms-conditions">
                            <Grid container alignContent="center" justifyContent="center">
                              <Typography
                                color={'#0093d5'}
                                onClick={() => window.location.replace("https://www.finpay.com/finpay-website-user-agreement/")}
                              >
                                Terms & Conditions
                              </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </div>
            </main>
        </div>
    );
}

export default ForgetPassword;
