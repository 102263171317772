import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../shared/state/root-reducer";
import { formatNumberToUSD } from "../../../shared/calculators";
import { Typography, Box, Grid, FormControlLabel, Checkbox, IconButton } from "@mui/material";
import { admissionsAdvisorUtils } from "../../utils/admission-advisor-utils";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { EstFinancialSummaryModal } from "./estimator-financial-summary-modal";
import { AppDispatch } from "../../../shared/state/store";
import { CrossoverSelectionEnum, SummarySelections } from "@finpay/estimation-types";
import CrossoverSummaryModal from "./estimator-crossover-summary-modal";
import { Utils } from "src/shared/utils";
import { callNewEstimatePut } from "src/admissions-advisor/state/estimator-thunk";

export function SidebarTotalEstimatedPfr() {

  const state = {
    vobState: useSelector(
      (state: RootState) => state.admissionsAdvisorContext.vobContext.vob
    ),
    estimate: useSelector((state: RootState) => {
      return state.admissionsAdvisorContext.estimatorContext.estimate
    })
  };

	const {
		vobState,
    estimate
	} = state;

	const [financialSummaryModalOpen, setFinancialSummaryModalOpen] = useState(false);
	const [totalEstimatedPfrSelected, setTotalEstimatedPfrSelected] = useState(false);
  const [isCrossoverSummaryModalOpen, setIsCrossoverSummaryModalOpen] = useState(false)
	const [totalPFR, setTotalPFR] = useState<number>(0.00);
	const dispatch = useDispatch<AppDispatch>();
	const PFRName = "Total Estimated PFR";
	const newPFRName = SummarySelections.NONADJUSTED;

	useEffect(() => {
    const financialSummary = estimate?.nonAdjustedSummary

    if (financialSummary && financialSummary?.totalPFR !== totalPFR) {
      setTotalPFR(financialSummary?.totalPFR!);
    }
    if (estimate) {                 
      const crossoverPFRToShow = estimate.nonAdjustedSummary?.totalPFR;
      setTotalPFR(crossoverPFRToShow!)
    }
  }, [estimate]);

	const handleTotalEstimatedPfrSelected = () => {
    setTotalEstimatedPfrSelected(!totalEstimatedPfrSelected);

    if (estimate && estimate.summarySelection !== newPFRName) {
        // we need put
        let newEstimateClone = Utils.deepClone(estimate);
        newEstimateClone = {...newEstimateClone, summarySelection: newPFRName};
        const postEstimateBody = admissionsAdvisorUtils.mapToNewEstimate(
            vobState.vobId!,
            vobState.client?.clientId!,
            vobState.facility?.facilityId!,
            newEstimateClone,
            Number(estimate.advisorPatientId!),
            estimate.patientEncounterId as string
        );
        dispatch(
            callNewEstimatePut({
                estimate: postEstimateBody,
                estimateId: newEstimateClone.estimateId,
            })
        );
    }
  }

  const sideBarColor = estimate?.summarySelection === newPFRName ? "#6EACDE" : "#999999";

	const handleFinSumModalCancel = () => {
    setFinancialSummaryModalOpen(false);
  };

	const openFinancialSummaryModal = () => {
    // if new estimate and crossover is true..  
    if (estimate && estimate.isPlanYearCrossover) {
      setIsCrossoverSummaryModalOpen(true)
    } else {
      setFinancialSummaryModalOpen(true);
    }
	}

  const summaryExists = estimate?.facilityLevelOfCare && estimate?.facilityLevelOfCare?.length > 0 && estimate?.summary

	return (
        <>
            {summaryExists && (
                <>
                    <Box
                        border={1}
                        borderColor={sideBarColor}
                        style={{
                            backgroundColor: 'white',
                            marginBottom: '1.5em',
                        }}
                    >
                        <Grid container direction="row">
                            <Grid item direction="column" md={12}></Grid>
                        </Grid>
                        <Grid container direction="row">
                            <Grid
                                item
                                direction="column"
                                md={12}
                                xs={12}
                                style={{
                                    color: 'white',
                                    backgroundColor: sideBarColor,
                                    padding: '0.25em',
                                }}
                            >
                                Patient Financial Responsibility
                                {(
                                    estimate?.isPlanYearCrossover 
                                      ? estimate?.crossoverSelection === CrossoverSelectionEnum.BEFORE
                                          ? ' before Crossover'
                                          : ' with Crossover'
                                      : ''
                                )}
                            </Grid>
                            <Grid
                                item
                                direction="column"
                                md={12}
                                style={{
                                    padding: '1em',
                                }}
                            >
                                <Typography
                                    variant="body2"
                                    style={{float: 'left'}}
                                >
                                    Total Estimated PFR
                                </Typography>

                                <FormControlLabel
                                    checked={
                                          estimate?.summarySelection! ===
                                            newPFRName
                                    }
                                    control={<Checkbox color="primary" />}
                                    id="totalPFR"
                                    name="totalPFR"
                                    label={
                                        <strong test-id="sidebar-estimate-pfr-value">
                                            {formatNumberToUSD(totalPFR)}
                                        </strong>
                                    }
                                    labelPlacement="start"
                                    style={{float: 'right'}}
                                    onChange={() => {
                                        handleTotalEstimatedPfrSelected();
                                    }}
                                />
                            </Grid>
                        </Grid>
                        <Grid container direction="row">
                            <Grid
                                item
                                direction="column"
                                md={12}
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                            >
                                <IconButton
                                    color="primary"
                                    title="Open Details"
                                    style={{fontSize: '1.5rem'}}
                                    onClick={openFinancialSummaryModal}
                                    test-id="sidebar-estimate-pfr-open-details"
                                >
                                    <OpenInNewIcon fontSize="small" /> Open
                                    Details
                                </IconButton>
                            </Grid>
                        </Grid>
                    </Box>
                    {isCrossoverSummaryModalOpen && (
                        <CrossoverSummaryModal open={true} estimate={estimate!} handleCancel={() => {setIsCrossoverSummaryModalOpen(false)}}/>
                    )}
                    {financialSummaryModalOpen && (
                        <EstFinancialSummaryModal
                            open={financialSummaryModalOpen}
                            handleFinancialSummaryModalCancel={
                                handleFinSumModalCancel
                            }
                        />
                    )}
                </>
            )}
        </>
    );
	


}