import { Grid, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import logo from "./../../assets/images/powered-by-finmobile-color.png";
import "./../../scss/layout/_mobile-payments-header.scss";
import { RootState } from "../state/root-reducer";

function MobilePaymentsHeader() {
  const patientEncounter = useSelector((state: RootState) => (state?.patientContext?.selectedEncounter));
  const patientInfo = useSelector(
    (state: RootState) => state.guestContext.mobilePayment.patientInfo
  );

  return (
    <header className="mobile-payment-header">
      <Grid
        container
        alignItems="center"
        justifyContent="space-between"
        className="px-4 hamburger-menu"
      >
        <Grid item className="logo">
          <img src={logo} alt="logo" />
        </Grid>
        <Grid>
          <Typography color="primary">
            {patientInfo.clientName}
          </Typography>          
        </Grid>
      </Grid>
    </header>
  );
}

export default MobilePaymentsHeader;
