import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
	EstLevelOfCare,
	PfrEstimator,
	FetchedEstimate,
	apiStatus,
} from '../models/estimator';
import {FacilityLevelOfCare, Estimate} from "@finpay/estimation-types"
import { ClientDetail } from '../../shared/model/client-details';
import { LevelOfCare } from "../../admin-configuration/models/level-of-care";
import { getFacilityLevelsOfCareByPlan, getClientLevelsOfCare, getClientDetails, callNewEstimatePost, callNewEstimatePut, callNewEstimateGet, getNewFacilityLevelsOfCare, getSavedEstimates} from './estimator-thunk';
import { configGetLOC } from "../../admin-configuration/state/admin-configuration-thunk";

export interface estimatorSliceShape {
	facilityLevelsOfCareByPlan: EstLevelOfCare[],
	facilityAllLevelsOfCare: EstLevelOfCare[],
	clientLevelsOfCare: EstLevelOfCare[],
	priorLevelsOfCare: LevelOfCare[],
	savedEstimations: PfrEstimator[],
	fetchedEstimate: FetchedEstimate,
	clientDetails?: ClientDetail,
	isLoading: {
		isLoadingFacilityLocByPlan: boolean;
		facilityLocByPlanStatus: apiStatus,
		isLoadingMasterLoc: boolean,
		isLoadingClientLoc: boolean,
		configGetLOCStatus: apiStatus,
		getClientLOCStatus: apiStatus,
		isLoadingEstimates: boolean;
		isLoadingGetEstimate: boolean;
		isLoadingSaveEstimates: boolean,
		getEstimateStatus: apiStatus,
		saveEstimateStatus: apiStatus,
		isLoadingFacilityLoc: boolean;
		isLoadingClientDetails: boolean,
		clientDetailsStatus: apiStatus,
	},
	estimate: Estimate | null,
	facilityLevelsOfCareList: FacilityLevelOfCare[] | null
}

const initialState: estimatorSliceShape = {
	facilityLevelsOfCareByPlan: [],
	facilityAllLevelsOfCare: [],
	clientLevelsOfCare: [],
	priorLevelsOfCare: [],
	savedEstimations: [],
	fetchedEstimate: {
		ormVersion: undefined,
		createUserId: "",
		createDt: "",
		pfrEstimateId: undefined,
		vobId: undefined,
		advisorPatientId: undefined,
		estimateBody: undefined,
		lastUpdateUserId: "",
		lastUpdateDt: ""
	},
	clientDetails: undefined,
	isLoading: {
		isLoadingFacilityLocByPlan: false,
		facilityLocByPlanStatus: apiStatus.none,
		isLoadingMasterLoc: false,
		isLoadingClientLoc: false,
		configGetLOCStatus: apiStatus.none,
		getClientLOCStatus: apiStatus.none,
		isLoadingEstimates: false,
		isLoadingGetEstimate: false,
		isLoadingSaveEstimates: false,
		getEstimateStatus: apiStatus.none,
		saveEstimateStatus: apiStatus.none,
		isLoadingFacilityLoc: false,
		isLoadingClientDetails: false,
		clientDetailsStatus: apiStatus.none,
	},
	estimate: null,
	facilityLevelsOfCareList: null
};

export const estimatorSlice = createSlice({
	name: "estimatorContext",
	initialState,
	reducers: {
		setLevelsOfCare: (state, action) => {
			state.clientLevelsOfCare = action.payload;
		},
		setfacilityAllLevelsOfCare: (state, action) => {
			state.facilityAllLevelsOfCare = action.payload;
		},
		clearSaveEstimatorStatus: (state) => {
			state.isLoading.saveEstimateStatus = initialState.isLoading.saveEstimateStatus;
		},
		clearGetEstimateStatus: (state) => {
			state.isLoading.getEstimateStatus = initialState.isLoading.getEstimateStatus;
		},
		clearConfigGetLOCStatus: (state) => {
			state.isLoading.configGetLOCStatus = initialState.isLoading.configGetLOCStatus;
		},
		clearFacilityLocByPlanStatus: (state) => {
			state.isLoading.facilityLocByPlanStatus = initialState.isLoading.facilityLocByPlanStatus;
		},
		clearClientDetailsStatus: (state) => {
			state.isLoading.clientDetailsStatus = initialState.isLoading.clientDetailsStatus;
		},
		clearClientLocStatus: (state) => {
			state.isLoading.getClientLOCStatus = initialState.isLoading.getClientLOCStatus;
		},
		clearEstimate: (state) => {
			state.estimate = initialState.estimate 
		},
		setEstimate: (state, action) => {
			state.estimate = action.payload;
		},
		clearFacilityLevelsOfCareList: (state) => {
			state.facilityLevelsOfCareList = null
		}	
	},
  extraReducers: (builder) => {
    builder.addCase(getFacilityLevelsOfCareByPlan.pending, (state, action) => {
			state.isLoading.facilityLocByPlanStatus = apiStatus.none;
      state.isLoading.isLoadingFacilityLocByPlan = true;
    });
    builder.addCase(getFacilityLevelsOfCareByPlan.fulfilled, (state, action: PayloadAction<any>) => {
			state.isLoading.facilityLocByPlanStatus = apiStatus.success;
			state.isLoading.isLoadingFacilityLocByPlan = false;
      state.facilityLevelsOfCareByPlan = action.payload;
    });
    builder.addCase(getFacilityLevelsOfCareByPlan.rejected, (state, action) => {
			state.isLoading.facilityLocByPlanStatus = apiStatus.error;
      state.isLoading.isLoadingFacilityLocByPlan = false;
    });
    builder.addCase(configGetLOC.pending, (state, action) => {
			state.isLoading.configGetLOCStatus = apiStatus.none;
      state.isLoading.isLoadingMasterLoc = true;
    });
    builder.addCase(configGetLOC.fulfilled, (state, action: PayloadAction<any>) => {
			state.isLoading.configGetLOCStatus = apiStatus.success;
      state.isLoading.isLoadingMasterLoc = false;
      state.priorLevelsOfCare = action.payload;
    });
    builder.addCase(configGetLOC.rejected, (state, action) => {
			state.isLoading.configGetLOCStatus = apiStatus.error;
      state.isLoading.isLoadingMasterLoc = false;
    });
    builder.addCase(getClientLevelsOfCare.fulfilled, (state, action: PayloadAction<any>) => {
			state.isLoading.getClientLOCStatus = apiStatus.success;
      state.isLoading.isLoadingClientLoc = false;
      state.clientLevelsOfCare = action.payload;
    });
    builder.addCase(getClientLevelsOfCare.rejected, (state, action) => {
			state.isLoading.getClientLOCStatus = apiStatus.error;
      state.isLoading.isLoadingClientLoc = false;
    });
    builder.addCase(getClientLevelsOfCare.pending, (state, action) => {
			state.isLoading.getClientLOCStatus = apiStatus.none;
      state.isLoading.isLoadingClientLoc = true;
    });
    builder.addCase(getSavedEstimates.pending, (state, action) => {
      state.isLoading.isLoadingEstimates = true;
    });
    builder.addCase(getSavedEstimates.fulfilled, (state, action: PayloadAction<any>) => {
			state.isLoading.isLoadingEstimates = false;
      state.savedEstimations = action.payload;
    });
    builder.addCase(getSavedEstimates.rejected, (state, action) => {
      state.isLoading.isLoadingEstimates = false;
    });
    builder.addCase(getClientDetails.pending, (state, action) => {
      state.isLoading.isLoadingClientDetails = true;
			state.isLoading.clientDetailsStatus = apiStatus.none;
    });
    builder.addCase(getClientDetails.fulfilled, (state, action: PayloadAction<any>) => {
			state.isLoading.isLoadingClientDetails = false;
			state.isLoading.clientDetailsStatus = apiStatus.success;
			state.clientDetails = action.payload;
    });
    builder.addCase(getClientDetails.rejected, (state, action) => {
      state.isLoading.isLoadingClientDetails = false;
			state.isLoading.clientDetailsStatus = apiStatus.error;
    });
	builder.addCase(callNewEstimatePost.fulfilled, (state, action) => {
		state.estimate = action.payload
	});
	builder.addCase(callNewEstimatePut.fulfilled, (state, action) => {
		state.estimate = action.payload
	});
	builder.addCase(callNewEstimateGet.fulfilled, (state, action) => {
		state.estimate = action.payload
	});
	builder.addCase(getNewFacilityLevelsOfCare.fulfilled, (state, action) => {
		state.facilityLevelsOfCareList = action.payload
	});
  },
});

export const { setLevelsOfCare, setfacilityAllLevelsOfCare, clearSaveEstimatorStatus, clearGetEstimateStatus, clearConfigGetLOCStatus, clearFacilityLocByPlanStatus, clearClientDetailsStatus, clearClientLocStatus, clearEstimate, setEstimate, clearFacilityLevelsOfCareList } = estimatorSlice.actions;

export default estimatorSlice.reducer;