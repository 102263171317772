import {
    Button,
} from '@finpay-development/shared-components';
import CloseIcon from '@mui/icons-material/Close';
import {
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import _ from 'lodash';
import {useSelector} from 'react-redux';
import {formatNumberToUSD} from '../../../shared/calculators';
import {TableColumn} from '../../../shared/model/table-column';
import {RootState} from '../../../shared/state/root-reducer';
import {FacilityLevelOfCare} from '@finpay/estimation-types';

interface EstFinancialSummaryModalProps {
    open: boolean;
    handleFinancialSummaryModalCancel: () => void;
}

export function EstFinancialSummaryModal(props: EstFinancialSummaryModalProps) {
    const {open, handleFinancialSummaryModalCancel} = props;

    const estimate = useSelector((state: RootState) => {
        return state.admissionsAdvisorContext.estimatorContext.estimate;
    });

    const locTableHeaders: TableColumn[] = [
        {id: 'colLocName', label: 'Levels of Care', align: 'left'},
        {
            id: 'colLocDays',
            label: `Length of Stay (days/sessions)`,
            align: 'left',
        },
        {id: 'colLocChgs', label: 'Adjusted Charges', align: 'right'},
    ];

    const financialSummary = estimate?.nonAdjustedSummary;


    function handleModalCancel() {
        handleFinancialSummaryModalCancel();
    }

    const estimateTableRows = _.sortBy(
        estimate?.facilityLevelOfCare,
        'facilityLevelOfCareName'
    )
        .filter((loc: FacilityLevelOfCare) => {
            if (loc.locType === 1) return false;
            return true;
        })
        .map((row: FacilityLevelOfCare, index: number) => {
            return (
                <TableRow key={index} className="py-3">
                    <TableCell>
                        {row?.facilityLevelOfCareCode}-
                        {row?.facilityLevelOfCareName}
                    </TableCell>
                    <TableCell test-id={`non-adj-summary-modal-${row?.facilityLevelOfCareCode}-days`}>{row?.losDays}</TableCell>
                    <TableCell align="right" test-id={`non-adj-summary-modal-${row?.facilityLevelOfCareCode}-value`}>
                        {formatNumberToUSD(row?.losDays * row?.pdrRate)}
                    </TableCell>
                </TableRow>
            );
        });

    const tableRows =
        estimate?.facilityLevelOfCare &&
        estimate.facilityLevelOfCare.length > 0
            && estimateTableRows

    const estimatedAdjustedCharges =
        estimate?.nonAdjustedSummary
            && formatNumberToUSD(
                  estimate.nonAdjustedSummary.coveredCharges +
                      estimate.nonAdjustedSummary.unCoveredCharges
              )

    const estimateLenghtOfStay =
        estimate?.nonAdjustedSummary
            && estimate.nonAdjustedSummary.lengthOfStay

    return (
        <Dialog
            className="modal"
            open={open}
            fullWidth={true}
            maxWidth="md"
            scroll="body"
        >
            <DialogTitle>Patient Financial Responsibility Summary</DialogTitle>

            <IconButton
                aria-label="close"
                onClick={handleModalCancel}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: theme => theme.palette.grey[500],
                }}
            >
                <CloseIcon />
            </IconButton>

            <DialogContent>
                <Typography variant="subtitle2">Care Plan Details</Typography>

                <TableContainer component={Paper}>
                    <Table size="small" className="alternating-row">
                        <TableHead>
                            <TableRow>
                                {locTableHeaders.map(column => (
                                    <TableCell
                                        key={column.id}
                                        align={column.align}
                                    >
                                        {column.label}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {tableRows}
                            <TableRow className="py-3 light-green-row">
                                <TableCell colSpan={2}>
                                    Estimated Adjusted Charges
                                </TableCell>
                                <TableCell align="right" test-id="non-adj-summary-modal-loc-est-adj-charges">
                                    {estimatedAdjustedCharges}
                                </TableCell>
                            </TableRow>
                            <TableRow className="py-3 light-green-row">
                                <TableCell colSpan={2}>
                                    Estimated Length of Stay
                                </TableCell>
                                <TableCell align="right">
                                    {estimateLenghtOfStay}
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>

                <Typography variant="subtitle2" className="mt-2">
                    PFR Details
                </Typography>
                <TableContainer component={Paper}>
                    <Table size="small" className="alternating-row">
                        <TableBody>
                            <TableRow className="py-3">
                                <TableCell>Estimated Co-Pay</TableCell>
                                <TableCell align="right" test-id="non-adj-summary-modal-copay">
                                    {formatNumberToUSD(
                                        financialSummary?.patientTotalCoPays
                                    )}
                                </TableCell>
                            </TableRow>
                            <TableRow className="py-3">
                                <TableCell>Estimated Deductible</TableCell>
                                <TableCell align="right" test-id="non-adj-summary-modal-deductible">
                                    {formatNumberToUSD(
                                        financialSummary?.deductibleSpent
                                    )}
                                </TableCell>
                            </TableRow>
                            <TableRow className="py-3">
                                <TableCell>Estimated Co-Insurance</TableCell>
                                <TableCell align="right" test-id="non-adj-summary-modal-coinsurance">
                                    {formatNumberToUSD(
                                        financialSummary?.patientTotalCoInsurance
                                    )}
                                </TableCell>
                            </TableRow>
                            <TableRow className="py-3">
                                <TableCell></TableCell>
                                <TableCell
                                    align="right"
                                    sx={{borderTop: '2px solid #999'}}
                                >
                                    <strong test-id="non-adj-summary-modal-covered-srv-pfr">{formatNumberToUSD(financialSummary?.coveredSvcsPfr)}</strong>
                                </TableCell>
                            </TableRow>
                            <TableRow className="py-3">
                                <TableCell>
                                    Out of Pocket Max Remaining
                                </TableCell>
                                <TableCell align="right" test-id="non-adj-summary-modal-oopm-remaining">
                                    {financialSummary?.includeOOPmax ? (
                                        formatNumberToUSD(
                                            financialSummary?.remainingOopMaxFromVob
                                        )
                                    ) : (
                                        <>N/A</>
                                    )}
                                </TableCell>
                            </TableRow>
                            <TableRow className="py-3">
                                <TableCell>PFR for Covered Services</TableCell>
                                <TableCell align="right" test-id="non-adj-summary-modal-pfr-covered-services">
                                    {formatNumberToUSD(financialSummary?.netPFR)}
                                </TableCell>
                            </TableRow>
                            <TableRow className="py-3">
                                <TableCell>Uncovered Services</TableCell>
                                <TableCell align="right" test-id="non-adj-summary-modal-uncovered-services">
                                    {formatNumberToUSD(
                                        financialSummary?.unCoveredCharges
                                    )}
                                </TableCell>
                            </TableRow>
                            {(financialSummary?.totalSurcharges && financialSummary?.totalSurcharges > 0) ? (
                                <>
                                    <TableRow className="py-3">
                                        <TableCell>PFR Subtotal</TableCell>
                                        <TableCell align="right" test-id="non-adj-summary-modal-pfr-subtotal">
                                            {formatNumberToUSD(
                                                financialSummary?.subtotalPFR
                                            )}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow className="py-3">
                                        <TableCell>
                                            Admission Assessment
                                        </TableCell>
                                        <TableCell align="right" test-id="non-adj-summary-modal-admission-assessment">
                                            {formatNumberToUSD(
                                                financialSummary?.totalSurcharges
                                            )}
                                        </TableCell>
                                    </TableRow>
                                </>
                            ) : null}
                            <TableRow className="py-3">
                                <TableCell>
                                    <Typography variant="subtitle2">
                                        Estimated Patient Financial
                                        Responsibility
                                    </Typography>
                                </TableCell>
                                <TableCell align="right">
                                    <Typography variant="subtitle2" test-id="non-adj-summary-modal-total-pfr">
                                        {formatNumberToUSD(
                                            financialSummary?.totalPFR
                                        )}
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </DialogContent>
            <DialogActions>
                <Button test-id="non-adj-summary-close-button" onClick={() => handleModalCancel()}>Close</Button>
            </DialogActions>
        </Dialog>
    );
}
