import React from "react";
import { Button, GenericLevelOfCare } from "@finpay-development/shared-components";
import CloseIcon from "@mui/icons-material/Close";
import {
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import _ from "lodash";
import { useSelector } from "react-redux";
import { CrossoverSelectionEnum, EstimateSummary, FacilityLevelOfCare, FacilityLevelOfCareWithCrossoverDays, PriorCare} from "@finpay/estimation-types";
import { formatNumberToUSD } from "../../../shared/calculators";
import { TableColumn } from "../../../shared/model/table-column";
import { RootState } from "../../../shared/state/root-reducer";
import { admissionsAdvisorUtils } from "../../utils/admission-advisor-utils";
import { CrossoverView } from '../../../shared/components/crossover-view';
import PriorCareSummaryTable from '../../../shared/components/prior-care-summary-table';

interface PriorCareSummaryModalProps {
  open: boolean;
  handleSummaryModalCancel: () => void;
  priorCare: PriorCare;
  priorCareSummary: EstimateSummary;
  summary: EstimateSummary;
}

const PriorCareSummaryModal = (props: PriorCareSummaryModalProps) => {
  const { open, handleSummaryModalCancel, priorCareSummary, summary, priorCare } = props;

  const state = {
    estimate: useSelector(
      (state: RootState) =>
          state.admissionsAdvisorContext.estimatorContext.estimate
    ),
  };

  const { estimate } = state;

  const locTableHeaders: TableColumn[] = [
    { id: "levelOfCareCode", label: "Code", minWidth: 200 },
    { id: "levelOfCareName", label: "Name", minWidth: 200 },
    { id: "colLocRate", label: "Rate" },
    { id: "colLocDays", label: "Days/Sessions" },
  ];

  const newEstimatelocTableHeaders: TableColumn[] = [
      {id: 'colLocName', label: 'Levels of Care', align: 'left'},
      {
          id: 'colLocDays',
          label: 'Length of Stay (sessions/days)',
          align: 'left',
      },
      {id: 'colLocChgs', label: 'Adjusted Charges', align: 'right'},
  ];

  const estimatedAdjustedCharges =
      formatNumberToUSD(
          estimate?.priorCareSummary?.coveredCharges! +
              estimate?.priorCareSummary?.unCoveredCharges!
      )

  const estimateTableRows = JSON.parse(JSON.stringify(estimate?.facilityLevelOfCare))
      ?.sort(
          (a: FacilityLevelOfCare, b: FacilityLevelOfCare) =>
              a.sortOrder - b.sortOrder
      )
      .filter((loc: FacilityLevelOfCare) => {
          if (loc.locType === 1) return false;
          return true;
      })
      .map((row: FacilityLevelOfCareWithCrossoverDays, index: number) => {
          const days = estimate?.isPlanYearCrossover
              ? estimate?.crossoverSelection ===
                CrossoverSelectionEnum.BEFORE
                  ? row?.losDaysBeforeCrossover
                  : row?.losDays
              : row?.losDays;
          return (
              <TableRow key={index} className="py-3">
                  <TableCell>
                      {row?.facilityLevelOfCareCode}-
                      {row?.facilityLevelOfCareName}
                  </TableCell>
                  <TableCell test-id={`prior-care-summary-modal-${row?.facilityLevelOfCareCode}-days`}>{days}</TableCell>
                  <TableCell align="right" test-id={`prior-care-summary-modal-${row?.facilityLevelOfCareCode}-value`}>
                      {formatNumberToUSD(days * row?.pdrRate)}
                  </TableCell>
              </TableRow>
          );
      });

  function handleModalCancel() {
    handleSummaryModalCancel();
  }

  return (
    <Dialog
      className="modal"
      open={open}
      fullWidth={true}
      maxWidth="md"
      scroll="body"
    >
      <DialogTitle>Prior Care Summary
          {
              estimate?.isPlanYearCrossover && (
                  <Typography variant="h4">
                      {estimate?.crossoverSelection ===
                      CrossoverSelectionEnum.BEFORE
                          ? 'Before Crossover'
                          : 'With Crossover'}
                  </Typography>
          )}
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleModalCancel}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>

      <DialogContent>
        <Typography variant="subtitle2">Prior Care Plan Details</Typography>
              <>
                <TableContainer component={Paper}>
                  <Table size="small">
                    <TableHead>
                      <TableRow>
                        {locTableHeaders.map((column) => (
                          <TableCell key={column.id}>{column.label}</TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {_.sortBy(priorCare.loc, "sortOrder")
                      .filter((loc: FacilityLevelOfCare) => {
                        if(loc.locType === 1) return false
                        return true;
                      }).map((row, index) => {
                        return (
                          <TableRow key={`${row.facilityLevelOfCareCode}.${index}`}>
                            <TableCell>{row.facilityLevelOfCareCode}</TableCell>
                            <TableCell>{row.facilityLevelOfCareName}</TableCell>
                            <TableCell>{row.pdrRate}</TableCell>
                            <TableCell>{row.losDays}</TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                  <PriorCareSummaryTable priorCareSummary={priorCareSummary} />
                </TableContainer>
              </>
            
                 
              <Typography variant="subtitle2" style={{marginTop: "12px"}}>Care Plan Details</Typography>
              <TableContainer component={Paper}>
                  <Table size="small" className="alternating-row">
                      <TableHead>
                          <TableRow>
                              {newEstimatelocTableHeaders.map(column => (
                                  <TableCell
                                      key={column.id}
                                      align={column.align}
                                  >
                                      {column.label}
                                  </TableCell>
                              ))}
                          </TableRow>
                      </TableHead>
                      <TableBody>
                          {estimateTableRows}
                          <TableRow className="py-3 light-green-row">
                              <TableCell colSpan={2}>
                                  Estimated Adjusted Charges
                              </TableCell>
                              <TableCell test-id="prior-care-summary-modal-current-adj-charges" align="right">
                                  {estimatedAdjustedCharges}
                                  </TableCell>
                              </TableRow>
                              <TableRow className="py-3 light-green-row">
                                  <TableCell colSpan={2}>
                                      Estimated Length of Stay
                                  </TableCell>
                                  <TableCell test-id="prior-care-summary-modal-current-los" align="right">
                                      {estimate?.priorCareSummary?.lengthOfStay}
                                  </TableCell>
                              </TableRow>
                          </TableBody>
                      </Table>
                  </TableContainer>
        
        <CrossoverView estimate={estimate} summary={priorCareSummary} isPriorCareSummary={true} />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleModalCancel()} test-id="prior-care-summary-close-button">Close</Button>
      </DialogActions>
    </Dialog>
  );
}

export default PriorCareSummaryModal;
