import React, {useEffect, useState, useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Box, Paper, Grid} from '@mui/material';
import {AppDispatch} from '../../../shared/state/store';
import {RootState} from '../../../shared/state/root-reducer';
import {
    clearStatus,
    clearRiskClassSettingsStatus,
} from '../../../admin-configuration/state/admin-configuration-slice';
import {
    clearFacilityRiskThresholdsStatus,
    clearClaimRatesStatus,
    clearClientRiskClassSettingsStatus,
    clearMultiFacilityRiskClassSettingsStatus,
    clearMultiFacilityReferralSourceRevenueStatus,
    clearMultiFacilityReferralSourcesStatus,
} from '../../state/risk-assessment-slice';
import {configGetRiskThresholds} from '../../../admin-configuration/state/admin-configuration-thunk';
import {
    getMultiFacilityRiskThresholds,
    getClientClaimRates,
    getClientRiskClassSettings,
    getMultiFacilityiskClassSettings,
    getMultiFacilityReferralSourceRevenue,
    getMultiFacilityReferralSources,
} from '../../state/risk-assessment-thunk';
import {
    MultiFacilityRiskThreshold,
    FacilityRiskClassSettings,
    ReferralSourceRevenue,
} from '../../models/risk-assessment';

import {apiStatus} from '../../models/estimator';

import {
    configGetLOC,
    configGetRiskClassSettings,
} from '../../../admin-configuration/state/admin-configuration-thunk';
import {getClientDetails} from '../../state/estimator-thunk';
import {getClientDeficiencyNotification} from '../../../implementation-specialist/state/clients/implementation-clients-thunk';
import {clearClientDeficiencyNotificationStatus} from '../../../implementation-specialist/state/clients/implementation-clients-slice';
import {clearVobClassificationsLoadingStatus} from '../../state/vob-slice';
import {getVobClassifications} from '../../state/vob-thunk';
import {
    clearClientLocStatus,
    clearConfigGetLOCStatus,
} from '../../state/estimator-slice';

import {FacilityRiskAssessment, Estimate} from '@finpay/estimation-types';
import {useFormik} from 'formik';
import * as Yup from 'yup';
import {MenuItem, InputAdornment, Typography} from '@mui/material';
import {Button, TextField} from '@finpay-development/shared-components';

import {ImplementationFacility} from '../../../implementation-specialist/components/implementation-clients/details/models/implementation-facility';
import {newRiskScoringSchema} from '../../../shared/validation/schemas';

// import {EstimatorFooter} from '../pfr-estimator/estimator-footer';

import {
    payorRisk,
    timingRisk,
} from '../../../shared/model/timing-and-payor-risk';
import {saveCallbackStatus} from '@finpay-development/shared-components';

import {
    getClientRiskAssessmentConfigs,
    getFacilityReferralSources,
} from '../../../implementation-specialist/state/clients/implementation-clients-thunk';
import {
    clearClientRiskAssessmentConfigs,
    clearFacilityReferralSourcesStatus,
} from '../../../implementation-specialist/state/clients/implementation-clients-slice';
import {configGetFinPayRiskClasses} from '../../../admin-configuration/state/admin-configuration-thunk';

import {showErrorStatus} from 'src/security/state/user-slice';

import {riskAssessmentUtils} from 'src/admissions-advisor/utils/risk-assessment-utils';
import {ClientRiskAssessmentFields} from 'src/shared/model/client-risk-assessment-config';
import RiskAssessmentMidSection from './risk-assessment-midsection';

import {
    callNewEstimatePut,
    getClientLevelsOfCare,
} from 'src/admissions-advisor/state/estimator-thunk';
import {Vob} from 'src/admissions-advisor/models/vob';
import {Utils} from 'src/shared/utils';
import RiskAssessmentBottomSection from './risk-assessment-bottomsection';

const requiredProperties = [
    'monthlyOperatingCostIp',
    'monthlyOperatingCostOp',
    'bedCapacityIp',
    'bedCapacityOp',
    'variableCostPct',
];

const mapToPrettyPropertyNames = {
    monthlyOperatingCostIp: 'In Patient Total Monthly Operating Costs',
    monthlyOperatingCostOp: 'Out Patient Total Monthly Operating Costs',
    bedCapacityIp: 'In Patient Bed Capacity',
    bedCapacityOp: 'Out Patient Bed Capacity',
    variableCostPct: 'Variable Cost Percentage',
};

const {findInvalidProperties, isValidNumber, generateMessage} =
    riskAssessmentUtils;

export interface RiskAssessmentFormikValues {
    facilityCensus?: number;
    payorRiskId?: number;
    timingRiskId?: number;
    riskClassId?: number;
    facilityReferralSourceId?: number;
}

const RiskAssessmentMain = () => {
    const state = {
        facilities: useSelector(
            (state: RootState) =>
                state.implementationContext.implementationFacility.facilities
        ),
        vobPatientState: useSelector(
            (state: RootState) =>
                state.admissionsAdvisorContext?.vobPatientContext.patient
        ),
        vobState: useSelector(
            (state: RootState) => state.admissionsAdvisorContext.vobContext.vob
        ),
        configRiskThresholds: useSelector(
            (state: RootState) =>
                state.adminContext.adminConfigurationContext?.riskThresholds
        ),
        isLoadingConfigRiskThresholdsState: useSelector(
            (state: RootState) =>
                state.adminContext.adminConfigurationContext?.isLoading
                    .isLoadingRiskThresholds
        ),
        getConfigRiskThresholdsStatus: useSelector(
            (state: RootState) =>
                state.adminContext.adminConfigurationContext?.isLoading
                    .getRiskThresholdsStatus
        ),
        // holds risk threshold data for all client facilities
        multiFacilityRiskThresholds: useSelector(
            (state: RootState) =>
                state.admissionsAdvisorContext.riskAssessmentContext
                    ?.multiFacilityRiskThresholds
        ),
        isLoadingFacilityRiskThresholdsState: useSelector(
            (state: RootState) =>
                state.admissionsAdvisorContext.riskAssessmentContext?.isLoading
                    .isLoadingFacilityRiskThresholds
        ),
        facilityRiskThresholdsStatusState: useSelector(
            (state: RootState) =>
                state.admissionsAdvisorContext.riskAssessmentContext?.isLoading
                    .facilityRiskThresholdsStatus
        ),
        // begin level of care state
        masterLevelsOfCareState: useSelector(
            (state: RootState) =>
                state.adminContext.adminConfigurationContext?.levelsOfCare
        ),
        clientLevelsOfCareState: useSelector(
            (state: RootState) =>
                state.admissionsAdvisorContext.estimatorContext
                    ?.clientLevelsOfCare
        ),
        getClientLOCStatus: useSelector(
            (state: RootState) =>
                state.admissionsAdvisorContext.estimatorContext.isLoading
                    .getClientLOCStatus
        ),
        // levels of care for all client facilities
        isLoadingClientLoc: useSelector(
            (state: RootState) =>
                state.admissionsAdvisorContext.estimatorContext.isLoading
                    .isLoadingClientLoc
        ),
        configGetLOCStatus: useSelector(
            (state: RootState) =>
                state.admissionsAdvisorContext.estimatorContext.isLoading
                    .configGetLOCStatus
        ),
        isLoadingMasterLoc: useSelector(
            (state: RootState) =>
                state.admissionsAdvisorContext.estimatorContext.isLoading
                    .isLoadingMasterLoc
        ),
        // payer claim rates
        clientPayerClaimRatesState: useSelector(
            (state: RootState) =>
                state.admissionsAdvisorContext.riskAssessmentContext
                    ?.clientPayerClaimRates
        ),
        isLoadingClientClaimRatesState: useSelector(
            (state: RootState) =>
                state.admissionsAdvisorContext.riskAssessmentContext?.isLoading
                    .isLoadingClientClaimRates
        ),
        clientClaimRatesStatusState: useSelector(
            (state: RootState) =>
                state.admissionsAdvisorContext.riskAssessmentContext?.isLoading
                    .clientClaimRatesStatus
        ),
        // config risk class settings
        configRiskClassSettingsState: useSelector(
            (state: RootState) =>
                state.adminContext.adminConfigurationContext?.riskClassSettings
        ),
        isLoadingConfigRiskClassSettingState: useSelector(
            (state: RootState) =>
                state.adminContext.adminConfigurationContext?.isLoading
                    .isLoadingRiskClassSettings
        ),
        getConfigRiskClassSettingStatus: useSelector(
            (state: RootState) =>
                state.adminContext.adminConfigurationContext?.isLoading
                    .getRiskClassSettingsStatus
        ),
        // client risk class settings
        clientRiskClassSettingsState: useSelector(
            (state: RootState) =>
                state.admissionsAdvisorContext.riskAssessmentContext
                    ?.clientRiskClassSettings
        ),
        clientDetails: useSelector(
            (state: RootState) =>
                state.admissionsAdvisorContext.estimatorContext?.clientDetails
        ),
        isLoadingClientRiskClassSettingsState: useSelector(
            (state: RootState) =>
                state.admissionsAdvisorContext.riskAssessmentContext?.isLoading
                    .isLoadingClientRiskClassSettings
        ),
        clientRiskClassSettingsStatusState: useSelector(
            (state: RootState) =>
                state.admissionsAdvisorContext.riskAssessmentContext?.isLoading
                    .clientRiskClassSettingsStatus
        ),
        // facility risk class settings
        multiFacilityRiskClassSettingsState: useSelector(
            (state: RootState) =>
                state.admissionsAdvisorContext.riskAssessmentContext
                    ?.multiFacilityRiskClassSettings
        ),
        isLoadingMultiFacilityRiskClassSettingsState: useSelector(
            (state: RootState) =>
                state.admissionsAdvisorContext.riskAssessmentContext?.isLoading
                    .isLoadingMultiFacilityRiskClassSettings
        ),
        multiFacilityRiskClassSettingsStatusState: useSelector(
            (state: RootState) =>
                state.admissionsAdvisorContext.riskAssessmentContext?.isLoading
                    .multiFacilityRiskClassSettingsStatus
        ),
        // client deficiency notofication
        clientDeficiencyNotificationState: useSelector(
            (state: RootState) =>
                state.implementationContext.implementationSpecialistClient
                    .currentClientDeficiencyNotification
        ),
        isLoadingDeficiencyNotificationState: useSelector(
            (state: RootState) =>
                state.implementationContext.implementationSpecialistClient
                    .isLoadingDeficiencyNotification
        ),
        clientDeficiencyNotificationStatusState: useSelector(
            (state: RootState) =>
                state.implementationContext.implementationSpecialistClient
                    .clientDeficiencyNotificationStatus
        ),
        // facility referral sources
        multiFacilityReferralSourcesState: useSelector(
            (state: RootState) =>
                state.admissionsAdvisorContext.riskAssessmentContext
                    ?.multiFacilityReferralSources
        ),
        isLoadingMultiFacilityReferralSourcesState: useSelector(
            (state: RootState) =>
                state.admissionsAdvisorContext.riskAssessmentContext?.isLoading
                    .isLoadingMultiFacilityReferralSources
        ),
        multiFacilityReferralSourcesStatusState: useSelector(
            (state: RootState) =>
                state.admissionsAdvisorContext.riskAssessmentContext?.isLoading
                    .multiFacilityReferralSourcesStatus
        ),
        // level of care referral source revenue
        multiFacilityReferralSourceRevenueState: useSelector(
            (state: RootState) =>
                state.admissionsAdvisorContext.riskAssessmentContext
                    ?.multiFacilityReferralSourceRevenue
        ),
        isLoadingtMultiFacilityReferralSourceRevenueState: useSelector(
            (state: RootState) =>
                state.admissionsAdvisorContext.riskAssessmentContext?.isLoading
                    .isLoadingtMultiFacilityReferralSourceRevenue
        ),
        multiFacilityReferralSourceRevenueStatusState: useSelector(
            (state: RootState) =>
                state.admissionsAdvisorContext.riskAssessmentContext?.isLoading
                    .multiFacilityReferralSourceRevenueStatus
        ),
        // vob classifications
        vobClassificationsState: useSelector(
            (state: RootState) =>
                state.admissionsAdvisorContext.vobContext?.vobClassifications
        ),
        isLoadingVobClassificationsState: useSelector(
            (state: RootState) =>
                state.admissionsAdvisorContext.vobContext?.isLoading
                    .isLoadingVobClassifications
        ),
        vobClassificationsLoadingStatusState: useSelector(
            (state: RootState) =>
                state.admissionsAdvisorContext.vobContext?.isLoading
                    .vobClassificationsLoadingStatus
        ),
        estimate: useSelector((state: RootState) => {
            return state.admissionsAdvisorContext.estimatorContext.estimate;
        }),
        isLoadingFacilities: useSelector(
            (state: RootState) =>
                state.implementationContext.implementationFacility.isLoading
        ),
        patientEpisodeMarginSettings: useSelector(
            (state: RootState) =>
                state.admissionsAdvisorContext.riskAssessmentContext
                    .patientEpisodeMarginSettings
        ),
        patientEpisodeMarginSettingsStatus: useSelector(
            (state: RootState) =>
                state.admissionsAdvisorContext.riskAssessmentContext.isLoading
                    .patientEpisodeMarginSettingsStatus
        ),
        riskAssessmentFormState: useSelector(
            (state: RootState) =>
                state.admissionsAdvisorContext.riskAssessmentContext
                    .riskAssessmentForm
        ),
        facilityReferralSources: useSelector(
            (state: RootState) =>
                state.implementationContext.implementationSpecialistClient
                    ?.facilityReferralSources
        ),
        isLoadingFacilityReferralSources: useSelector(
            (state: RootState) =>
                state.implementationContext.implementationSpecialistClient
                    ?.isLoadingFacilityReferralSources
        ),
        facilityReferralSourcesStatus: useSelector(
            (state: RootState) =>
                state.implementationContext.implementationSpecialistClient
                    ?.facilityReferralSourcesStatus
        ),
        finPayRiskClasses: useSelector(
            (state: RootState) =>
                state.adminContext.adminConfigurationContext?.finPayRiskClasses
        ),
        clientRiskAssessmentConfigs: useSelector(
            (state: RootState) =>
                state.implementationContext.implementationSpecialistClient
                    ?.clientRiskAssessmentConfigs
        ),
        isLoadingClientRiskAssessmentConfigs: useSelector(
            (state: RootState) =>
                state.implementationContext.implementationSpecialistClient
                    ?.isLoadingClientRiskAssessmentConfigs
        ),
        clientRiskAssessmentConfigLoadingStatus: useSelector(
            (state: RootState) =>
                state.implementationContext.implementationSpecialistClient
                    ?.clientRiskAssessmentConfigLoadingStatus
        ),
    };

    const {
        facilities,
        vobState,
        vobPatientState,
        clientDetails,
        facilityReferralSources,
        isLoadingFacilityReferralSources,
        facilityReferralSourcesStatus,
        finPayRiskClasses,
        clientRiskAssessmentConfigs,
        clientRiskAssessmentConfigLoadingStatus,
        isLoadingClientRiskAssessmentConfigs,
        estimate,
        configRiskThresholds,
        isLoadingConfigRiskThresholdsState,
        getConfigRiskThresholdsStatus,
        multiFacilityRiskThresholds,
        isLoadingFacilityRiskThresholdsState,
        facilityRiskThresholdsStatusState,
        isLoadingMasterLoc,
        configGetLOCStatus,
        masterLevelsOfCareState,
        isLoadingClientLoc,
        clientPayerClaimRatesState,
        getClientLOCStatus,
        isLoadingClientClaimRatesState,
        clientClaimRatesStatusState,
        configRiskClassSettingsState,
        isLoadingConfigRiskClassSettingState,
        getConfigRiskClassSettingStatus,
        clientRiskClassSettingsState,
        isLoadingClientRiskClassSettingsState,
        clientRiskClassSettingsStatusState,
        multiFacilityRiskClassSettingsState,
        isLoadingMultiFacilityRiskClassSettingsState,
        multiFacilityRiskClassSettingsStatusState,
        clientDeficiencyNotificationState,
        isLoadingDeficiencyNotificationState,
        clientDeficiencyNotificationStatusState,
        multiFacilityReferralSourcesState,
        isLoadingMultiFacilityReferralSourcesState,
        multiFacilityReferralSourcesStatusState,
        multiFacilityReferralSourceRevenueState,
        isLoadingtMultiFacilityReferralSourceRevenueState,
        multiFacilityReferralSourceRevenueStatusState,
        vobClassificationsState,
        isLoadingVobClassificationsState,
        vobClassificationsLoadingStatusState,
    } = state;

    const dispatch = useDispatch<AppDispatch>();

    const [enableRunButton, setEnableRunButton] = useState(false);

    const [clientLocIsLoaded, setClientLocIsLoaded] = useState<Boolean>(false);
    const [claimRatesIsLoaded, setClaimRatesIsLoaded] = useState(false);
    const [
        configRiskClassSettingsIsLoaded,
        setConfigRiskClassSettingsIsLoaded,
    ] = useState(false);

    const [
        clientRiskClassSettingsIsLoaded,
        setClientRiskClassSettingsIsLoaded,
    ] = useState(false);

    const [
        multiFacilityRiskClassSettingsIsLoaded,
        setMultiFacilityRiskClassSettingsIsLoaded,
    ] = useState(false);

    const [
        clientDeficiencyNotificationIsLoaded,
        setClientDeficiencyNotificationIsLoaded,
    ] = useState(false);

    const [
        multiFacilityReferralSourcesAreLoaded,
        setMultiFacilityReferralSourcesAreLoaded,
    ] = useState(false);

    const [
        multiFacilityReferralSourceRevenueIsLoaded,
        setMultiFacilityReferralSourceRevenueIsLoaded,
    ] = useState(false);

    const [
        patientEpisodeMarginSettingsStateIsLoaded,
        setPatientEpisodeMarginSettingsStateIsLoaded,
    ] = useState(false);

    const [vobClassificationsAreLoaded, setVobClassificationsAreLoaded] =
        useState(false);

    // determine if there are any eiv or npr deficiencies (hides/shows data)
    const deficiencySettingEiv = Boolean(clientDeficiencyNotificationState?.isEiv);
    const hasEivdeficiencies = Boolean(estimate?.riskAssessment?.eivDeficiency);
    const hasNprdeficiencies = Boolean(estimate?.riskAssessment?.netPatientRevenueDeficiency);

    const paramId = -2;

    const allFacilityIds = useMemo(() => {
        return facilities?.flat().map(facility => {
            return facility.facilityId;
        });
    }, [facilities]);

    const isClientConfiguredForRiskScoring = clientDetails?.raVersion === 2;

    const currentFacility = useMemo(() => {
        return facilities.flat().find((facility: ImplementationFacility) => {
            return facility.facilityId === estimate?.facilityId;
        });
    }, [facilities]);

    const hasReferralSourcesConfigured = facilityReferralSources.length > 0;

    const selectedFacility = useMemo(() => {
        const facilitId = estimate!.facilityId;

        return facilities?.flat().find(fac => fac?.facilityId === facilitId);
    }, [estimate!.facilityId]);

    const isConfigRiskThresholdLoaded =
        !isLoadingConfigRiskThresholdsState &&
        getConfigRiskThresholdsStatus === saveCallbackStatus.success;

    const isMultiFacilityRiskThresholdsLoaded =
        !isLoadingFacilityRiskThresholdsState &&
        facilityRiskThresholdsStatusState === saveCallbackStatus.success;

    const areClientRiskAssessmentConfigsLoaded = useMemo(() => {
        return (
            clientRiskAssessmentConfigLoadingStatus ===
                saveCallbackStatus.success &&
            !isLoadingClientRiskAssessmentConfigs
        );
    }, [
        clientRiskAssessmentConfigLoadingStatus,
        isLoadingClientRiskAssessmentConfigs,
    ]);

    const selectedFacilityRiskAssessmentConfig = useMemo(() => {
        if (selectedFacility && areClientRiskAssessmentConfigsLoaded) {
            let facilityRiskAssessmentFields:
                | ClientRiskAssessmentFields
                | undefined = clientRiskAssessmentConfigs.find(
                (riskConfig: ClientRiskAssessmentFields) => {
                    return (
                        riskConfig.clientFacilityId ===
                        selectedFacility.facilityId
                    );
                }
            );

            if (!facilityRiskAssessmentFields) {
                facilityRiskAssessmentFields =
                    clientRiskAssessmentConfigs?.find(
                        (riskConfig: ClientRiskAssessmentFields) => {
                            return riskConfig.clientFacilityId === null;
                        }
                    )!;
            }

            return facilityRiskAssessmentFields;
        }
    }, [selectedFacility, areClientRiskAssessmentConfigsLoaded]);

    const [listOfMissingOrInvalidProperties, isFacilityConfigured] =
        useMemo(() => {
            const {
                variableCostPct,
                monthlyOperatingCostIp,
                monthlyOperatingCostOp,
                bedCapacityIp,
                bedCapacityOp,
            } = selectedFacilityRiskAssessmentConfig || {};

            const propertiesToCheck = {
                monthlyOperatingCostIp,
                monthlyOperatingCostOp,
                bedCapacityIp,
                bedCapacityOp,
                variableCostPct,
            };

            const [listOfMissingOrInvalidProperties, isMissingProperties] =
                findInvalidProperties(
                    propertiesToCheck,
                    requiredProperties,
                    isValidNumber
                );
            if (isMissingProperties) {
                return [listOfMissingOrInvalidProperties, false];
            } else {
                return [[], true];
            }
        }, [selectedFacilityRiskAssessmentConfig]);

    const isRunButtonEnabled = isFacilityConfigured && enableRunButton;

    const getLevelsOfCareByClient = () => {
        if (masterLevelsOfCareState?.length > 0) {
            const config: any = {
                paramId: paramId,
                clientId: estimate?.clientId as number,
                payerPlanId: vobState.plan.payorPlanId,
                filterByPayerPlanId: false,
                masterListLevelsOfCare: masterLevelsOfCareState,
            };
            dispatch(getClientLevelsOfCare(config));
        }
    };

    const riskThresholdData = useMemo(() => {
        if (
            isConfigRiskThresholdLoaded &&
            isMultiFacilityRiskThresholdsLoaded &&
            configRiskThresholds.length > 0
        ) {
            return multiFacilityRiskThresholds.map(
                (multiFacility: MultiFacilityRiskThreshold) => {
                    const mergedRiskThresholds =
                        riskAssessmentUtils.mergeConfigFacilityRiskThresholds(
                            configRiskThresholds,
                            multiFacility.facilityRiskThresholds,
                            multiFacility.clientFacilityId
                        );
                    return {
                        ...multiFacility,
                        facilityRiskThresholds: mergedRiskThresholds,
                    };
                }
            );
        } else {
            return [];
        }
    }, [isConfigRiskThresholdLoaded, isMultiFacilityRiskThresholdsLoaded]);

    useEffect(() => {
        dispatch(configGetFinPayRiskClasses({paramId: paramId}));
    }, []);

    useEffect(() => {
        if(finPayRiskClasses.length > 0) {
            dispatch(configGetRiskClassSettings({paramId: paramId}));
        }
    }, [finPayRiskClasses])

    useEffect(() => {
        return () => {
            dispatch(clearStatus());
            dispatch(clearFacilityRiskThresholdsStatus());
        };
    }, []);

    useEffect(() => {
        if (!masterLevelsOfCareState.length) {
            dispatch(configGetLOC(paramId));
        } else {
            getLevelsOfCareByClient();
        }

        dispatch(getVobClassifications(paramId));
        dispatch(configGetRiskThresholds({paramId: paramId}));
        dispatch(
            getClientDetails({
                paramId: paramId,
                clientId: estimate?.clientId! as number,
            })
        );

        dispatch(
            getClientRiskClassSettings({
                paramId: paramId,
                clientId: estimate?.clientId as number,
            })
        );

        dispatch(
            getMultiFacilityiskClassSettings({
                paramId: paramId,
                facilityIds: allFacilityIds,
            })
        );

        dispatch(
            getClientClaimRates({
                paramId: paramId,
                clientId: estimate?.clientId as number,
            })
        );

        dispatch(
            getClientDeficiencyNotification(estimate?.clientId as number)
        );

        dispatch(
            getMultiFacilityReferralSources({
                paramId: paramId,
                clientId: estimate?.clientId as number,
                facilityIds: allFacilityIds,
            })
        );

        dispatch(
            getMultiFacilityReferralSourceRevenue({
                paramId: paramId,
                facilityIds: allFacilityIds,
            })
        );

        if (allFacilityIds.length > 0) {
            dispatch(
                getMultiFacilityRiskThresholds({
                    paramId: paramId,
                    facilityIds: allFacilityIds,
                })
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (estimate!.clientId) {
            dispatch(
                getClientRiskAssessmentConfigs({
                    clientId: estimate?.clientId as number,
                })
            );
        }
        
        return () => {
            dispatch(clearClientRiskAssessmentConfigs());
        };
    }, [estimate?.clientId]);
    
    useEffect(() => {
        if (
            !isLoadingFacilityReferralSources &&
            facilityReferralSourcesStatus === saveCallbackStatus.success
        ) {
            // facility referral sources has loaded
            dispatch(clearFacilityReferralSourcesStatus());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoadingFacilityReferralSources]);
    
    useEffect(() => {
        if (selectedFacility) {
            dispatch(
                getFacilityReferralSources({
                    facilityId: selectedFacility.facilityId,
                    clientId: estimate?.clientId! as number,
                })
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedFacility]);
    
    useEffect(() => {
        if (
            clientRiskAssessmentConfigLoadingStatus ===
            saveCallbackStatus.success &&
            !isFacilityConfigured &&
            listOfMissingOrInvalidProperties.length > 0
        ) {
            const listOfMissingProperties = generateMessage(
                listOfMissingOrInvalidProperties,
                mapToPrettyPropertyNames
            );
            const message = `The following required facility settings are missing: ${listOfMissingProperties}. Please contact your system, implementation, or support coordinator.`;
            dispatch(showErrorStatus(message));
        }
    }, [
        selectedFacilityRiskAssessmentConfig,
        isFacilityConfigured,
        clientRiskAssessmentConfigLoadingStatus,
    ]);

    useEffect(() => {
        if (!isLoadingMasterLoc && configGetLOCStatus === apiStatus.success) {
            // config Levels of Care has loaded
            dispatch(clearConfigGetLOCStatus());
            getLevelsOfCareByClient();
        }

        if (!isLoadingClientLoc && getClientLOCStatus === apiStatus.success) {
            // client Levels of Care has loaded
            dispatch(clearClientLocStatus());
            setClientLocIsLoaded(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoadingMasterLoc, isLoadingClientLoc]);

    useEffect(() => {
        // claimrates is done loading
        if (
            !isLoadingClientClaimRatesState &&
            clientClaimRatesStatusState === saveCallbackStatus.success
        ) {
            setClaimRatesIsLoaded(true);
            clearClaimRatesStatus();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [clientClaimRatesStatusState]);

    useEffect(() => {
        // config risk class settings are done loading
        if (
            !isLoadingConfigRiskClassSettingState &&
            getConfigRiskClassSettingStatus === saveCallbackStatus.success
        ) {
            if (configRiskClassSettingsState !== undefined) {
                setConfigRiskClassSettingsIsLoaded(true);
                dispatch(clearRiskClassSettingsStatus());
            }
        }
        // client risk class settings are done loading
        if (
            !isLoadingClientRiskClassSettingsState &&
            clientRiskClassSettingsStatusState === saveCallbackStatus.success
        ) {
            if (clientRiskClassSettingsState !== undefined) {
                setClientRiskClassSettingsIsLoaded(true);
                dispatch(clearClientRiskClassSettingsStatus());
            }
        }
        // facility risk class settings are done loading
        if (
            !isLoadingMultiFacilityRiskClassSettingsState &&
            multiFacilityRiskClassSettingsStatusState ===
                saveCallbackStatus.success
        ) {
            if (multiFacilityRiskClassSettingsState !== undefined) {
                setMultiFacilityRiskClassSettingsIsLoaded(true);
                dispatch(clearMultiFacilityRiskClassSettingsStatus());
            }
        }

        // client deficiency settings are done loading
        if (
            !isLoadingDeficiencyNotificationState &&
            clientDeficiencyNotificationStatusState ===
                saveCallbackStatus.success
        ) {
            if (clientDeficiencyNotificationState !== undefined) {
                setClientDeficiencyNotificationIsLoaded(true);
                dispatch(clearClientDeficiencyNotificationStatus());
            }
        }

        // referral source revenue settings are done loading
        if (
            !isLoadingMultiFacilityReferralSourcesState &&
            multiFacilityReferralSourcesStatusState ===
                saveCallbackStatus.success
        ) {
            if (multiFacilityReferralSourcesState !== undefined) {
                setMultiFacilityReferralSourcesAreLoaded(true);
                dispatch(clearMultiFacilityReferralSourcesStatus());
            }
        }
        if (
            !isLoadingtMultiFacilityReferralSourceRevenueState &&
            multiFacilityReferralSourceRevenueStatusState ===
                saveCallbackStatus.success
        ) {
            if (multiFacilityReferralSourceRevenueState !== undefined) {
                setMultiFacilityReferralSourceRevenueIsLoaded(true);
                dispatch(clearMultiFacilityReferralSourceRevenueStatus());
            }
        }
        // vob classifications
        if (
            !isLoadingVobClassificationsState &&
            vobClassificationsLoadingStatusState === saveCallbackStatus.success
        ) {
            if (vobClassificationsState !== undefined) {
                dispatch(clearVobClassificationsLoadingStatus());
                setVobClassificationsAreLoaded(true);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        isLoadingConfigRiskClassSettingState,
        isLoadingClientRiskClassSettingsState,
        isLoadingMultiFacilityRiskClassSettingsState,
        isLoadingDeficiencyNotificationState,
        isLoadingMultiFacilityReferralSourcesState,
        isLoadingtMultiFacilityReferralSourceRevenueState,
        isLoadingVobClassificationsState,
    ]);

    const riskScoringValidationSchema = Yup.object(newRiskScoringSchema);

    function checkIsFormValid(value: {}) {
        riskScoringValidationSchema
            .validate(value, {context: {hasReferralSourcesConfigured}})
            .then(() => {
                setEnableRunButton(true);
            })
            .catch(err => {
                setEnableRunButton(false);
            });
    }

    const riskAssessmentFormik = useFormik<RiskAssessmentFormikValues>({
        initialValues: {
            facilityCensus:
                estimate?.riskAssessment?.facilityCensus ??
                ('' as unknown as number),
            payorRiskId: estimate?.riskAssessment?.payorRiskId ?? 1,
            timingRiskId: estimate?.riskAssessment?.timingRiskId ?? 1,
            riskClassId: estimate?.riskAssessment?.riskClassId ?? 1,
            facilityReferralSourceId:
                estimate?.riskAssessment?.facilityReferralSourceId,
        },
        validate: checkIsFormValid,
        validationSchema: riskScoringValidationSchema,
        validateOnMount: true,
        enableReinitialize: true,
        onSubmit: () => {},
    });

    const handleButtonClick = async () => {
        let mergedRiskClassSettings: FacilityRiskClassSettings[] =
            riskAssessmentUtils.mergeRiskClassSettings(
                finPayRiskClasses,
                configRiskClassSettingsState,
                clientRiskClassSettingsState,
                multiFacilityRiskClassSettingsState
            );

        // this merges all Referral Sources data for all facilities for lookup
        let mergedReferralSourceData: ReferralSourceRevenue[] =
            riskAssessmentUtils.mergeReferralSourceRevenue(
                multiFacilityReferralSourcesState,
                multiFacilityReferralSourceRevenueState
            );

        const vobCopy: Vob = Utils.deepClone(vobState);

        const multiFacilityRiskAssessments: FacilityRiskAssessment[] =
            riskAssessmentUtils.calculateFacilityRiskAssessmentsWithNewEstimate(
                currentFacility!,
                riskAssessmentFormik,
                vobCopy,
                estimate!,
                riskThresholdData,
                mergedRiskClassSettings,
                clientPayerClaimRatesState,
                clientRiskAssessmentConfigs,
                mergedReferralSourceData
            );

        const [facilityRiskAssessment, facilityRiskAssessmentHre] =
            multiFacilityRiskAssessments;

        const {
            priorCareSummary,
            summary,
            crossOverSummary,
            estimateId,
            createUserId,
            createDt,
            lastUpdateUserId,
            lastUpdateDt,
            summarySelection,
            ...estimatePutBody
        } = estimate!;

        const riskAssessment = {
            ...facilityRiskAssessment,
            ...riskAssessmentFormik.values,
        };

        const riskAssessmentHre = {
            ...facilityRiskAssessmentHre,
            ...riskAssessmentFormik.values,
        };

        // @ts-ignore
        const putBody: Estimate = {
            ...estimate,
            riskAssessment,
            riskAssessmentHre,
        };

        if (facilityRiskAssessment && facilityRiskAssessmentHre) {
            await dispatch(callNewEstimatePut({estimate: putBody, estimateId}));
        }
    };

    const riskAssessment = estimate?.riskAssessment;
    const riskAssessmentHre = estimate?.riskAssessmentHre;

    return (
        <>
            <Paper>
                <Box className="m-2 p-2">
                    <Typography variant="subtitle2" className="my-4">
                        Risk Scoring: {currentFacility?.facilityName}
                    </Typography>

                    <Grid
                        container
                        direction="row"
                        spacing={2}
                        className="mb-4"
                    >
                        <Grid item md={2} sm={2} xs={2}>
                            <TextField
                                label="Census"
                                name="facilityCensus"
                                type="number"
                                value={
                                    riskAssessmentFormik.values.facilityCensus
                                }
                                endAdornment={
                                    <InputAdornment position="end">
                                        <span>
                                            <strong>%</strong>
                                        </span>
                                    </InputAdornment>
                                }
                                error={
                                    riskAssessmentFormik.touched[
                                        'facilityCensus'
                                    ] &&
                                    riskAssessmentFormik.errors[
                                        'facilityCensus'
                                    ]
                                        ? riskAssessmentFormik.errors[
                                              'facilityCensus'
                                          ]
                                        : ''
                                }
                                onChange={(e: Event) => {
                                    riskAssessmentFormik.handleChange(e);
                                }}
                                test-id="facility-census"
                                onBlur={riskAssessmentFormik.handleBlur}
                            />
                        </Grid>

                        <Grid item md={2} sm={2}>
                            <TextField
                                select
                                error={
                                    riskAssessmentFormik.touched.payorRiskId &&
                                    Boolean(
                                        riskAssessmentFormik.errors.payorRiskId
                                            ? riskAssessmentFormik.errors
                                                  .payorRiskId
                                            : ''
                                    )
                                }
                                label="Payer Risk"
                                className="state-field"
                                name="payorRiskId"
                                value={riskAssessmentFormik.values.payorRiskId}
                                onChange={(e: Event) => {
                                    riskAssessmentFormik.handleChange(e);
                                }}
                                onBlur={riskAssessmentFormik.handleBlur}
                                placeholder="Select Payer Risk"
                            >
                                {payorRisk.map(risk => (
                                    <MenuItem
                                        key={risk.payorRiskId}
                                        value={risk.payorRiskId}
                                    >
                                        {risk.payorRiskName}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                        <Grid item md={2} sm={2}>
                            <TextField
                                select
                                error={
                                    riskAssessmentFormik.touched.timingRiskId &&
                                    Boolean(
                                        riskAssessmentFormik.errors.timingRiskId
                                            ? riskAssessmentFormik.errors
                                                  .timingRiskId
                                            : ''
                                    )
                                }
                                label="Timing Risk"
                                className="state-field"
                                name="timingRiskId"
                                value={riskAssessmentFormik.values.timingRiskId}
                                onChange={(e: Event) => {
                                    riskAssessmentFormik.handleChange(e);
                                }}
                                onBlur={riskAssessmentFormik.handleBlur}
                            >
                                {timingRisk.map(risk => {
                                    return (
                                        <MenuItem
                                            key={risk.timingRiskId}
                                            value={risk.timingRiskId}
                                        >
                                            {risk.timingRiskName}
                                        </MenuItem>
                                    );
                                })}
                            </TextField>
                        </Grid>
                        <Grid item md={4} sm={4}>
                            <TextField
                                select
                                label="Referral Source"
                                className="state-field"
                                name="facilityReferralSourceId"
                                test-id="risk-assessment-referral-source"
                                value={
                                    riskAssessmentFormik.values
                                        .facilityReferralSourceId
                                }
                                onChange={(e: Event) => {
                                    riskAssessmentFormik.handleChange(e);
                                }}
                                disabled={!hasReferralSourcesConfigured}
                                onBlur={riskAssessmentFormik.handleBlur}
                                error={Boolean(
                                    riskAssessmentFormik.touched
                                        .facilityReferralSourceId &&
                                        riskAssessmentFormik.errors
                                            .facilityReferralSourceId
                                )}
                            >
                                {!hasReferralSourcesConfigured && (
                                    <MenuItem value={-1}>
                                        Data Not Provided
                                    </MenuItem>
                                )}
                                {hasReferralSourcesConfigured &&
                                    facilityReferralSources.map(refsrc => (
                                        <MenuItem
                                            key={
                                                refsrc.facilityReferralSourceId
                                            }
                                            value={
                                                refsrc.facilityReferralSourceId
                                            }
                                            test-id={`risk-assessment-risk-class-option-${refsrc.facilityReferralSourceId}`}
                                        >
                                            {refsrc.facilityReferralSourceName}
                                        </MenuItem>
                                    ))}
                            </TextField>
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item md={4} sm={4}>
                            <TextField
                                select
                                label="Risk Class"
                                className="state-field"
                                name="riskClassId"
                                test-id="risk-assessment-risk-class"
                                value={riskAssessmentFormik.values.riskClassId}
                                onChange={(e: Event) => {
                                    riskAssessmentFormik.handleChange(e);
                                }}
                                onBlur={riskAssessmentFormik.handleBlur}
                                error={Boolean(
                                    riskAssessmentFormik.touched.riskClassId &&
                                        riskAssessmentFormik.errors.riskClassId
                                )}
                            >
                                {finPayRiskClasses.map(riskClass => (
                                    <MenuItem
                                        key={riskClass.riskClassId}
                                        value={riskClass.riskClassId}
                                        test-id={`risk-assessment-risk-class-option-${riskClass.riskClassId}`}
                                    >
                                        {riskClass.riskClassName}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                        {/* blank for spacing */}
                        <Grid item md={4} sm={4}></Grid>
                        <Grid
                            item
                            md={4}
                            sm={4}
                            style={{textAlign: 'right', marginBottom: '1em'}}
                        >
                            <Button
                                onClick={() => handleButtonClick()}
                                disabled={!isRunButtonEnabled}
                            >
                                Run Assessment
                            </Button>
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        direction="row"
                        spacing={2}
                        className="mt-2"
                    ></Grid>
                </Box>
            </Paper>

            {/* Risk Assessment Grid */}
            {riskAssessmentFormik.isValid &&
                isFacilityConfigured &&
                riskAssessment &&
                riskAssessmentHre && (
                    <>
                        <RiskAssessmentMidSection
                            riskAssessment={estimate?.riskAssessment}
                            riskAssessmentHre={estimate?.riskAssessmentHre}
                            deficiencySettingEiv={deficiencySettingEiv}
                            hasEivdeficiencies={hasEivdeficiencies}
                            hasNprdeficiencies={hasNprdeficiencies}
                            isClientConfiguredForRiskScoring={
                                isClientConfiguredForRiskScoring
                            }
                        />

                        <RiskAssessmentBottomSection />

                        {/* <EstFooter /> */}
                    </>
                )}
        </>
    );
};

export default RiskAssessmentMain;
