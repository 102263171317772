import {
  PatientEncounter,
} from '../../../patient/components/models/patient-encounter';
import {GuestToken} from '../../../security/services/SSO';
import {
  ApplicationStatusState,
  GetError,
} from '../../../security/model/application-status';
import React, {useState} from 'react';
import HttpInterceptor from '../../../shared/Http/http-interceptor';
import {patientService} from '../../../patient/services/patient-service';
import {
  specialistClientService,
} from '../../../implementation-specialist/services/implementation-client-service';
import {Checkbox, FormControlLabel, Grid, Typography} from '@mui/material';
import {formatNumberToUSD, getOrdinalSuffix} from '../../../shared/calculators';
import {Button} from '@finpay-development/shared-components';
import {
  MobileInstallmentDocModal as InstallmentDocModal,
} from '../../modal/mobile-installment-doc-modal';
import {
  MobilePaymentSPAModal as SPAModal,
} from '../../modal/mobile-payment-spa-modal';
import {AuthorizationDocumentStatus} from './payment-program-enrollment-page';
import {Workflow} from '../../../shared/model/client';
import {
  PatientPaymentProgram,
} from '../../../patient/components/models/patient-payment-program';
import {EnrollmentThankYouView} from './enrollment-thank-you-view';

interface EnrollmentApprovalViewProps {
  patientEncounter: PatientEncounter;
  selectedAuthDocStatus: AuthorizationDocumentStatus | null;
  guestToken: GuestToken | null;
  setMessage: (msg: ApplicationStatusState) => void;
}

export const EnrollmentApprovalView = (props: EnrollmentApprovalViewProps) => {

  const {
    patientEncounter,
    guestToken,
    selectedAuthDocStatus,
    setMessage,
  } = props;

  const [modalOpen, setModalOpen] = useState({
    isInstModalOpen: false,
    isSPAModalOpen: false,
  });
  const [approvalReq, setApprovalReq] = useState({
    isCheckboxChecked: false,
    isAuthDocViewed: false,
  });
  const [isEnrollmentThankYou, setIsEnrollmentThankYou] = useState(false);
  const [clientDetails, setClientDetails] = useState({
    surveyUrl: '',
    clientName: '',
    email: '',
    phoneNumber: '',
  });
  const [isFormProcessing, setIsFormProcessing] = useState<boolean>(false);

  const typeOfDoc = selectedAuthDocStatus?.authorizationDocuments?.typeOfDoc;

  const handleApproveBtnOnClick = async () => {

    HttpInterceptor(false, guestToken!, true);

    const peStatus = getPEStatusForAuthDocSigned({
        isACH: patientEncounter?.patientPaymentProgram?.[0].isACH,
        isPaidInFull: patientEncounter?.patientPaymentProgram?.[0].isPaidInFull,
        isACHVerified: patientEncounter?.patientPaymentProgram?.[0].isACHVerified!,
        pfrCapturedBalance: patientEncounter?.patientPaymentProgram?.[0]?.patientPaymentSchedule.pfrCapturedBalance!
    });

    const resolvedPromises = await Promise.all([
      processAuthDocApproval({
        authorizationDocumentStatusId: selectedAuthDocStatus?.authorizationDocumentStatusId!,
        peStatus,
        patientEncounterId: patientEncounter.patientEncounterId,
        patientId: patientEncounter.patientId
      }),
      //getting survey url path
      specialistClientService.getClient(patientEncounter.clientId),
    ]);

    for (let i = 0; i < resolvedPromises.length; i++) {
      if (resolvedPromises?.[i]?.hasErrors) {
        setMessage(GetError(
            'A problem occurred while approving your payment program. Please reach out to a FinPay administrator.'));
        return;
      }
    }

    const fetchedClientDetail = resolvedPromises[1]?.entity?.fullClient;

    if(
        fetchedClientDetail.clientCrm.length > 0 &&
        fetchedClientDetail.clientCrm?.[0]?.crmType?.crmTypeSlug &&
        patientEncounter.clientsPatientIOCId
    ){

      const integrationUpdateResponse = await patientService.integrationUpdate({
        patientEncounterId: patientEncounter.patientEncounterId,
        patientId: patientEncounter.patientId,
        crmTypeSlug: fetchedClientDetail.clientCrm?.[0].crmType.crmTypeSlug
      })

      if(integrationUpdateResponse.hasErrors){
        console.error("Error occurred during real time update")
      }

    }

    setIsEnrollmentThankYou(true);
    setClientDetails({
      ...clientDetails,
      surveyUrl: fetchedClientDetail?.surveyUrl,
      clientName: fetchedClientDetail?.clientName,
      email: fetchedClientDetail?.primaryContact?.email,
      phoneNumber: fetchedClientDetail?.primaryContact?.primPhoneNum,
    });

  };

  return (
      <Grid
          item
          sx={{
            padding: '1rem 2rem',
            maxWidth: '535px',
            minWidth: '300px',
          }}
      >
        <Grid>
          <Typography
              variant="h3"
              className="pt-2"
              align="center"
              color="primary"
              test-id="enrollment-page-title"
          >
            {`${patientEncounter?.client?.clientName} at ${patientEncounter?.facilityName} ${typeOfDoc ===
            'spa' ?
                'Single Payment Agreement' :
                'Payment Program'}`}
          </Typography>
        </Grid>
        {
          isEnrollmentThankYou ? (
              <EnrollmentThankYouView clientDetails={clientDetails}/>
          ) : (
              <>
                <Grid container sx={{margin: '1rem 0'}}>
                  <Grid item xs={8} className="mt-2">
                    <Typography variant="subtitle2">
                      Patient Financial Responsibility (PFR):
                    </Typography>
                  </Grid>
                  <Grid item xs={4} className="mt-2">
                    <Typography
                        variant="subtitle2"
                        align="right"
                        test-id="enrollment-page-pfr-amt"
                    >
                      {formatNumberToUSD(
                          patientEncounter?.pfrAmt)}
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <Typography
                        variant="subtitle2"
                        test-id="enrollment-page-payment-type"
                    >
                      {`${typeOfDoc === 'spa' ?
                          'Payment' :
                          'Down Payment'}:`}
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography
                        variant="subtitle2"
                        align="right"
                        test-id="enrollment-page-payment-amt"
                    >
                      {formatNumberToUSD(
                          patientEncounter?.patientPaymentProgram?.[0]?.downPmtAmt)}
                    </Typography>
                  </Grid>
                  {
                      typeOfDoc !== 'spa' && (
                          <>
                            <Grid item xs={8}>
                              <Typography
                                  variant="subtitle2"
                                  test-id="enrollment-page-recurring-payment"
                              >
                                {getRecurringPaymentText(
                                    patientEncounter?.patientPaymentProgram?.[0])}
                              </Typography>
                            </Grid>
                            <Grid item xs={4}>
                              <Typography
                                  variant="subtitle2"
                                  align="right"
                                  test-id="enrollment-page-recurring-payment-amt"
                              >
                                {formatNumberToUSD(
                                    patientEncounter?.patientPaymentProgram?.[0]?.patientPaymentSchedule?.paymentDueAmt)}
                              </Typography>
                            </Grid>
                          </>
                      )
                  }
                </Grid>
                {
                  (()=>{
                    const paymentSchedule = patientEncounter?.patientPaymentProgram?.[0]?.patientPaymentSchedule;
                    const paymentStartDay = +paymentSchedule?.paymentStartDay
                    if( paymentSchedule?.paymentFreq === 'M' && paymentStartDay > 0){
                      return (
                          <Grid item xs={12} sx={{marginBottom: '1rem'}}>
                            <Typography>
                              {`Payments are due on the ${paymentStartDay}${getOrdinalSuffix(paymentStartDay)} of each month.`}
                            </Typography>
                          </Grid>
                      )
                    }
                  })()
                }
                <Grid item xs={12} sx={{marginBottom: '1rem'}}>
                  <Typography
                      test-id="enrollment-page-instruction"
                  >
                    {`Click below to approve this Payment Authorization and ${typeOfDoc ===
                    'spa' ?
                        'approve this payment' :
                        ' enroll in this payment program'}.`}
                  </Typography>
                </Grid>
                <Grid item xs={12} sx={{marginBottom: '1rem'}}>
                  <Typography
                      test-id="enrollment-page-auth-doc"
                  >
                    Please click here to review the {' '}
                    <Typography
                        test-id="enrollment-page-auth-doc-link"
                        component="span"
                        variant="body1"
                        color="primary"
                        style={{
                          cursor: 'pointer',
                          textDecoration: 'underline',
                        }}
                        onClick={() => {
                          if (typeOfDoc === 'spa') {
                            setModalOpen({
                              ...modalOpen,
                              isSPAModalOpen: true,
                            });
                          } else {
                            setModalOpen({
                              ...modalOpen,
                              isInstModalOpen: true,
                            });
                          }
                          if (!approvalReq.isAuthDocViewed) {
                            setApprovalReq({
                              ...approvalReq,
                              isAuthDocViewed: true,
                            });
                          }
                        }
                        }
                    >
                      {`Payment Authorization`}
                    </Typography>
                    .
                  </Typography>
                </Grid>
                <Grid sx={{marginBottom: '1rem'}}>
                  <FormControlLabel
                      control={
                        <Checkbox
                            test-id="enrollment-page-approve-checkbox"
                            onChange={(e) => {
                              setApprovalReq({
                                ...approvalReq,
                                isCheckboxChecked: e.target.checked,
                              });
                            }}
                            checked={approvalReq.isCheckboxChecked}
                        />}
                      label="I accept the terms of this Payment Authorization"/>
                </Grid>
                <Grid container justifyContent="center">
                  <Button
                      test-id="enrollment-page-approve-btn"
                      spinnerLeftPosition={6}
                      loading={isFormProcessing}
                      disabled={isFormProcessing}
                      onClick={async () => {

                        if (!approvalReq.isAuthDocViewed) {
                          setMessage(GetError(
                              'Please click on the Payment Authorization link and review the terms before clicking approve.'));
                          return;
                        }
                        if (!approvalReq.isCheckboxChecked) {
                          setMessage(GetError(
                              'Please click the checkbox to accept the terms before clicking approve.'));
                          return;
                        }
                        setIsFormProcessing(true);
                        await handleApproveBtnOnClick();
                        setIsFormProcessing(false);

                      }}
                  >
                    Approve
                  </Button>
                </Grid>
                <InstallmentDocModal
                    isOpen={modalOpen.isInstModalOpen}
                    handleModalCancel={() => {
                      setModalOpen({
                        ...modalOpen,
                        isInstModalOpen: false,
                      });
                    }}/>
                <SPAModal
                    isOpen={modalOpen.isSPAModalOpen}
                    handleModalCancel={() => {
                      setModalOpen({
                        ...modalOpen,
                        isSPAModalOpen: false,
                      });
                    }}
                />
              </>
          )
        }

      </Grid>
  );

};

type PEStatus = {
  finClearanceStatus: string
  workflow: Omit<Workflow, 'workflowName'>
} | null

const getPEStatusForAuthDocSigned = ({
                       isACH,
                       isPaidInFull,
                       isACHVerified,
                       pfrCapturedBalance
}:
                         {
                             isACH: boolean,
                             isPaidInFull: boolean,
                             isACHVerified: boolean
                             pfrCapturedBalance: number
                         }): PEStatus => {

  switch (true) {

    case !isACH && isPaidInFull && pfrCapturedBalance === 0:
    case isACH && isPaidInFull && isACHVerified && pfrCapturedBalance === 0:
      return {
        finClearanceStatus: 'Paid',
        workflow: {
          'workflowId': 3,
          'workflowStatus': {
            'workflowStatusId': 16,
            'workflowStatusDesc': 'Paid',
          },
          'workflowSubStatus': {
            'workflowSubStatusId': 23,
            'workflowSubStatusDesc': 'None',
          },
        },
      };
    case !isACH && !isPaidInFull:
    case !isACH && isPaidInFull && (pfrCapturedBalance > 0 || pfrCapturedBalance === null):
    case isACH && isACHVerified && !isPaidInFull:
    case isACH && isACHVerified && isPaidInFull && (pfrCapturedBalance > 0 || pfrCapturedBalance === null):
      return {
        finClearanceStatus: 'Converted',
        workflow: {
          'workflowId': 3,
          'workflowStatus': {
            'workflowStatusId': 12,
            'workflowStatusDesc': 'Converted',
          },
          'workflowSubStatus': {
            'workflowSubStatusId': 23,
            'workflowSubStatusDesc': 'None',
          },
        },
      };
    case isACH && !isACHVerified:
      return {
        finClearanceStatus: 'Converted, Pending - Awaiting ACH Verification',
        workflow: {
          'workflowId': 3,
          'workflowStatus': {
            'workflowStatusId': 17,
            'workflowStatusDesc': 'Converted, Pending',
          },
          'workflowSubStatus': {
            'workflowSubStatusId': 34,
            'workflowSubStatusDesc': 'Awaiting ACH Verification',
          },
        },
      };
    default:
      return null;
  }

};

const getRecurringPaymentText = (patientPaymentProgram: PatientPaymentProgram) => {

  const terms = patientPaymentProgram?.patientPaymentSchedule?.terms;
  let termAndPaymentFreq;

  switch (patientPaymentProgram.patientPaymentSchedule.paymentFreq.toLowerCase()) {
    case 'm' :
      termAndPaymentFreq = `Monthly `;
      break;
    case 'w' :
      termAndPaymentFreq = `Weekly `;
      break;
    default :
      termAndPaymentFreq = '';
  }

  return `${terms} ${termAndPaymentFreq} Payment(s) of:`;
};

const processAuthDocApproval = async (params : {
  authorizationDocumentStatusId: number
  peStatus: PEStatus
  patientEncounterId: number
  patientId: number
}) => {

  const {authorizationDocumentStatusId, peStatus, patientEncounterId, patientId} = params;

  const authDocResponse = await patientService.updateAuthorizationDocumentStatus(
      {
        dataToSave: {
          documentStatus: 'signed',
        },
        authorizationDocumentStatusId: authorizationDocumentStatusId,
      });

  if (authDocResponse?.hasErrors) {
    return authDocResponse;
  }

  const docCreationResponse =  await patientService.createNoSigHelloSignDoc({
    authorizationDocumentStatusId,
  });

  if (docCreationResponse?.hasErrors || !peStatus) {

    if(docCreationResponse?.hasErrors) {
      await patientService.updateAuthorizationDocumentStatus(
          {
            dataToSave: {
              documentStatus: 'sent',   //reverting status back to sent to avoid conflicts
            },
            authorizationDocumentStatusId: authorizationDocumentStatusId,
          });
    }

    return docCreationResponse;
  }

 const resolvedPromises = await Promise.all([
    patientService.saveStatus({
      workFlow: peStatus.workflow,
      encounterId: patientEncounterId,
      patientId,
    }),
    patientService.saveIOCWithPartialObject({
      finClearanceStatus: peStatus.finClearanceStatus,
      finClearanceStatusDt: new Date().toISOString(),
    }, patientEncounterId)
  ])

  for (let i = 0; i < resolvedPromises.length; i++) {
    if (resolvedPromises[i].hasErrors) {
      return resolvedPromises[i];
    }
  }

};