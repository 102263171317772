import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {
  checkIfUserIsAuthenticated,
  getProfileState,
  logUserIn,
  logUserOut,
  refreshToken,
  sendForgetPasswordNotification,
  updatePassword,
  updatePasswordWithVerificationCode,
  verifySigninCode,
  checkForCode,
  ssoRedirect,
  generateToken,
  updatePasswordWithVerificationCodev2,
  sendForgetPasswordNotificationV2,

} from './user-thunk';
import { emptyUserProfile } from '../model/user-profile';
import { emptyApplicationStatus, GetNotification, GetError } from '../model/application-status';
import { getDefaultUserContextState, UserContextState } from '../model/user-context-state';
import { VERIFICATIONCODE_AUTHENICATION_ERROR } from '../services/authorization-service';
import Cookies from 'js-cookie';

const userStatus = Cookies.getJSON('userStatus');

const initialState = {
    userProfile: emptyUserProfile,
    isSSO: false,
    isSSOLoading: false,
    homeRoute: "",
    onLoginScreen: false,
    userIsAuthenticated: false,
    hasErrorOccurred: false,
    applicationStatus: emptyApplicationStatus,
    clearForm: false,
    userEmail: "",
    isActive: userStatus?.isActive || false,
    isLoading: false,
  } as UserContextState


const userSlice = createSlice({
  name: 'userContext',
  initialState,
  reducers: {
    showStatus(state, action: PayloadAction<string>) {
      state.applicationStatus = GetNotification(action.payload);
    },
    showErrorStatus(state, action: PayloadAction<string>) {
      state.applicationStatus = GetError(action.payload);
    },
    hideStatus(state) {
      state.applicationStatus.showMessage = false;
    },
    loginSuccess(state) {
      state.applicationStatus = emptyApplicationStatus;
    },
    setLoginError(state, action: PayloadAction<string>) {
      state.applicationStatus = GetError(action.payload);
    },
    updateApplicationStatus(state, action: PayloadAction<string>) {
      state.applicationStatus =  GetError(action.payload);
    },
    clearForm(state, action: PayloadAction<boolean>) {
      state.clearForm = action.payload;
    },
    setLoginFormStateOn(state) {
      state.onLoginScreen = true;
    },
    changeStatus(state){
      state.isActive = !state.isActive
    },
    resetUserContext() {
      return initialState;
    }
  },
    extraReducers: builder => {
      builder.addCase(logUserIn.pending, (state, action) => {
        state.isLoading = true;
        state.userIsAuthenticated = false;
        state.applicationStatus = emptyApplicationStatus;
    })
    builder.addCase(logUserIn.fulfilled, (state, action) => {
      state.isLoading = false;
      const payload = action.payload;
      state.userProfile = payload!.userProfile;
      state.homeRoute = payload!.userProfile.defaultRoute;
      state.onLoginScreen = payload!.userIsAuthenticated;
      state.userIsAuthenticated = payload!.userIsAuthenticated;
      state.hasErrorOccurred = payload!.hasErrorOccurred;
      state.applicationStatus = payload!.applicationStatus;
      state.clearForm = payload!.clearForm;
      state.userEmail = payload!.userEmail;
      state.isActive = true
     })
    builder.addCase(logUserIn.rejected, (state, action) => {
      state.isLoading = false;
      state.userIsAuthenticated = false;
      state.applicationStatus = GetError(action.error.message ? action.error.message : "");
    })

    builder.addCase(logUserOut.fulfilled, (state, action) => {
      state.userProfile = emptyUserProfile;
      state.userIsAuthenticated = false;
      state.hasErrorOccurred = false;
      state.homeRoute = "";
      state.onLoginScreen = true;
      state.clearForm = false;
      state.userEmail = "";
    })
    builder.addCase(logUserOut.rejected, (state, action) => {
      // TODO: Error for logging user out
      state.userProfile = emptyUserProfile;
      state.userIsAuthenticated = false;
      state.homeRoute = "";
    })

    builder.addCase(ssoRedirect.pending, (state, action) =>{
      state.isSSOLoading = true;
    })
    builder.addCase(ssoRedirect.fulfilled, (state, action) =>{
      state.isSSOLoading = true;
    })
    builder.addCase(ssoRedirect.rejected, (state, action) =>{
      state.isSSOLoading = true;
    })
    builder.addCase(checkForCode.pending, (state, action) =>{
        state.isSSOLoading = false;
    })
    builder.addCase(checkForCode.fulfilled, (state, action) =>{
      state.isSSOLoading = false;
    })
    builder.addCase(checkForCode.rejected, (state, action) =>{
      state.isSSOLoading = false;
    })
    builder.addCase(generateToken.pending, (state, ) =>{
      state.isSSOLoading = true;
    })
    builder.addCase(getProfileState.pending, (state, action) => {
     state.userIsAuthenticated = false;
     state.applicationStatus = emptyApplicationStatus;
     state.isSSOLoading = true;
    })
    builder.addCase(getProfileState.fulfilled, (state, action) =>{
      const payload = action.payload;
      state.userProfile = payload!.userProfile;
      state.homeRoute = payload!.userProfile.defaultRoute;
      state.onLoginScreen = payload!.onLoginScreen;
      state.userIsAuthenticated = payload!.userIsAuthenticated;
      state.hasErrorOccurred = payload!.hasErrorOccurred;
      state.applicationStatus = payload!.applicationStatus;
      state.clearForm = payload!.clearForm;
      state.userEmail = payload!.userEmail;
      state.isActive = true;
      state.isSSO = true;
      state.isSSOLoading = true;
      })
    builder.addCase(getProfileState.rejected, (state, action) =>{
      const payload = getDefaultUserContextState();
      state.isSSO = false;
      state.isLoading = false;
      state.userProfile = payload.userProfile;
      state.homeRoute = payload.userProfile.defaultRoute;
      state.onLoginScreen = payload!.onLoginScreen;
      state.hasErrorOccurred = payload.hasErrorOccurred;
      state.clearForm = payload.clearForm;
      state.userEmail = payload.userEmail;
      state.userIsAuthenticated = false;
      state.applicationStatus = GetError(VERIFICATIONCODE_AUTHENICATION_ERROR);

    })


    builder.addCase(checkIfUserIsAuthenticated.fulfilled, (state, action) => {
      const payload = action.payload;
      state.userProfile = payload.userProfile;
      state.homeRoute = payload.userProfile.defaultRoute;
      state.onLoginScreen = payload.userIsAuthenticated;
      state.userIsAuthenticated = payload.userIsAuthenticated;
      state.hasErrorOccurred = payload.hasErrorOccurred;
      state.applicationStatus = payload.applicationStatus;
      state.clearForm = payload.clearForm;
      state.userEmail = payload.userEmail;
    })

    builder.addCase(verifySigninCode.fulfilled, (state, action) => {
      const payload = action.payload;
      state.userProfile = payload.userProfile;
      state.homeRoute = payload.userProfile.defaultRoute;
      state.onLoginScreen = payload.userIsAuthenticated;
      state.userIsAuthenticated = payload.userIsAuthenticated;
      state.hasErrorOccurred = payload.hasErrorOccurred;
      state.applicationStatus = payload.applicationStatus;
      state.clearForm = payload.clearForm;
      state.userEmail = payload.userEmail;
    })

    builder.addCase(verifySigninCode.rejected, (state, action) => {
      const payload = getDefaultUserContextState();
      state.userProfile = payload.userProfile;
      state.homeRoute = payload.userProfile.defaultRoute;
      state.onLoginScreen = payload.userIsAuthenticated;
      state.hasErrorOccurred = payload.hasErrorOccurred;
      state.clearForm = payload.clearForm;
      state.userEmail = payload.userEmail;
      state.userIsAuthenticated = false;
      state.applicationStatus = GetError(VERIFICATIONCODE_AUTHENICATION_ERROR);
    })

    builder.addCase(updatePassword.pending, (state, action) => {
      state.userIsAuthenticated = false;
      state.applicationStatus = emptyApplicationStatus;
      state.clearForm = false;
    })

    builder.addCase(updatePassword.fulfilled, (state, action) => {
      state.userIsAuthenticated = false;
      state.clearForm = true;
      state.homeRoute = "";
      if (action.payload.passwordUpdated) {
        state.applicationStatus = GetNotification(action.payload.statusMessage);
      }  else {
        state.applicationStatus = GetError(action.payload.statusMessage);
      }
    })

    builder.addCase(sendForgetPasswordNotification.pending, (state, action) => {
      state.clearForm = false;
    })

    const handleForgetPasswordNotification = (state: any, action: any) => {
      state.userEmail = action?.payload?.username;
    }

    builder
    .addCase(sendForgetPasswordNotification.fulfilled, handleForgetPasswordNotification)
    .addCase(sendForgetPasswordNotificationV2.fulfilled, handleForgetPasswordNotification)

    const handleUpdatePasswordWithVerificationCodeSuccess = (state: any, action: any) => {
      state.userIsAuthenticated = false;
      state.clearForm = true;
      state.homeRoute = "";
      if (action.payload?.passwordUpdated) {
        state.applicationStatus = GetNotification(action.payload.statusMessage);
      }  else {
        state.applicationStatus = GetError(action.payload.statusMessage);
      }
    }

    builder
    .addCase(updatePasswordWithVerificationCode.fulfilled, handleUpdatePasswordWithVerificationCodeSuccess)
    .addCase(updatePasswordWithVerificationCodev2.fulfilled, handleUpdatePasswordWithVerificationCodeSuccess)

    builder.addCase(refreshToken.fulfilled, (state, action) => {
      state.userProfile.tokenState = action.payload;
    })

  }
});

export const { showStatus, showErrorStatus, updateApplicationStatus, setLoginError, hideStatus, clearForm, setLoginFormStateOn, changeStatus, resetUserContext } = userSlice.actions
export default userSlice.reducer;
