import { Box, Grid, IconButton, Paper, Typography } from '@mui/material';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import WarningIcon from '@mui/icons-material/Warning';
import React, {useEffect,useState} from 'react';
import { useDispatch, useSelector } from "react-redux";
import { patientContact, PatientEncounter } from '../../patient/components/models/patient-encounter';
import { DocumentsAccordionContents } from "../../patient/components/patient-drilldown/documents/documents-accordion-contents";
import { TransactionsAccordionContents } from "../../patient/components/patient-drilldown/transactions/transactions-accordion-contents";
import { clearSignUrl, resetRefreshAccountHolderDashboard, setCurrentConvertedEncounter } from '../../patient/state/patient-slice';
import {
  getConvertedPatients,
  getEncounterSignUrl,
  getPaymentMethods,
  getTransactions,
  setUpEncountersAccountHolder,
  updateEncountersAfterMakePayment,
} from '../../patient/state/patient-thunk';
import '../../scss/pages/account-holder/_active-view.scss';
import CustomizeableAccordion from "../../shared/components/customizeable-accordion";
import { LoadingOverlay } from "@finpay-development/shared-components";
import { RootState } from "../../shared/state/root-reducer";
import PaymentProgressAccordionContents from "./account-holder-active/payment-progress-accordion-contents";
import { formatNumberToUSD } from '../../shared/calculators';
import { getPatientStatusByStatusId, StatusType } from '../../patient/components/misc/patient-status';
import { Button } from "@finpay-development/shared-components";
import { VerifyBankModal } from './microdeposits/verify-bank-modal';
import { openHelloSignDoc } from '../../shared/configuration/config-settings'
import CreditCardIcon from '@mui/icons-material/CreditCard';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import _ from 'lodash';
import RefreshIcon from '@mui/icons-material/Refresh';
import PaymentSourcesAccordionContents from './account-holder-active/payment-sources-accordion-contents';
import { StripePaymentMethod } from '../../patient/components/models/stripe-payment-method';
import ContactAccordionContents from './contact-accordion-contents';
import { Utils } from '../../shared/utils';
import { AppDispatch } from '../../shared/state/store';
import HeaderWithDetails from '../../shared/components/header-with-details';
import { TakePaymentModal } from 'src/shared/components/take-payment-modal';
import { paymentToggleTypes } from 'src/shared/components/take-payment-modal/take-payment-modal-content';
import { PAYMENT_TYPES } from 'src/patient/components/models/payment-type';
import {patientService} from '../../patient/services/patient-service';

interface AccountHolderViewProp {
  isActivePrograms?: boolean;
  isCompletedPrograms?: boolean;
  isCancelledPrograms?: boolean;
}

function AccountHolderView(props: AccountHolderViewProp) {
  const { isActivePrograms, isCompletedPrograms, isCancelledPrograms } = props;
  const [isPaymentProgressModalOpen, setIsPaymentProgressModalOpen] = useState(false);
  const [isVerifyBankModalOpen, setIsVerifyBankModalOpen] = useState(false);
  const [showPaymentProgressDetails, setShowPaymentProgressDetails] = useState(true);
  const [firstPaymentNotVerified, setFirstPaymentNotVerified] = useState({} as StripePaymentMethod);

  const dispatch = useDispatch<AppDispatch>();

  const handlePaymentProgressModalClose = () => {
    setIsPaymentProgressModalOpen(false);
    setShowPaymentProgressDetails(true);
  }

  const handleVerifyBankModalClose = () => {
    setIsVerifyBankModalOpen(false);
  }

  const stateFields = {
    convertedPatientEncounters: useSelector((state: RootState) => {
      return state.patientContext.convertedPatients
    }),
    allPatientEncounters: useSelector((state: RootState) => {
      return state.patientContext.selectedPatient.patientEncounters;
    }),
    selectedEncountersList: useSelector((state: RootState) => {
      return state.patientContext.selectedConvertedEncounters;
    }),
    currentConvertedEncounter: useSelector((state: RootState) => {
      return state.patientContext.currentConvertedEncounter;
    }),
    isLoadingPatientEncounters: useSelector((state: RootState) => {
      return state.patientContext.isLoading.convertedPatients
    }),
    signurl: useSelector((state: RootState) => {
      return state.patientContext.patientEncounterSignUrl;
    }),
    isLoadingAccountHolderDashboard: useSelector((state: RootState) => {
      return state.patientContext.isLoading.accountHolderDashboard;
    }),
    isErrorAccountHolder: useSelector((state: RootState) => {
      return state.patientContext.isError.accountHolderDashboard;
    }),
    isErrorConvertedPatients: useSelector((state: RootState) => {
      return state.patientContext.isError.convertedPatients;
    }),
    refreshDashboard: useSelector((state: RootState) => {
      return state.patientContext.refreshAccountHolderDashboard;
    }),
    missingAddressError: useSelector((state: RootState) => state.patientContext.isError.missingAddress),
    userRoleId: useSelector((state: RootState) => state.userContext.userProfile.userRole.userRoleId),
    userProfile: useSelector((state: RootState) => {
      return state.userContext.userProfile;
    }),
  }
  const {
    convertedPatientEncounters, allPatientEncounters, selectedEncountersList,
    currentConvertedEncounter, isLoadingPatientEncounters, signurl, isLoadingAccountHolderDashboard, isErrorAccountHolder, isErrorConvertedPatients,
    refreshDashboard, userRoleId, missingAddressError
  } = stateFields;

  const isError = (isErrorAccountHolder || isErrorConvertedPatients)
  const isLoading = (isLoadingPatientEncounters || isLoadingAccountHolderDashboard)

  let paymentChannelId = 5;
  const hasFinancialCounselorRole = userRoleId === 1 || userRoleId === 9;
  if(hasFinancialCounselorRole) {
    paymentChannelId = 2;
  }

  useEffect(() => {
    if (isActivePrograms && signurl && signurl.urlToSign) {
      openHelloSignDoc(signurl.urlToSign);
      dispatch(clearSignUrl());
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [signurl, isActivePrograms])

  const handleRefresh = async () => {
    await dispatch(getConvertedPatients("0")); // 0 == all months worth of patients
    await dispatch(setUpEncountersAccountHolder(
      {
        isActive: isActivePrograms || false,
        isCompleted: isCompletedPrograms || false,
        isCancelled: isCancelledPrograms || false,
        convertedPatientEncounters: convertedPatientEncounters
      }
    ))
  }

  const checkForUnsignedHelloSign = () => {
    let unsignedEncounter = allPatientEncounters.find((patientEncounter) => {
      /**
       * 1) For ACH, the Java backend code doesn't set areDocsSigned = true after docs are signed, so need to check different property
       * 2) We only want to have the HelloSign document show up if the installment document is tracked through the patientDocuments table
       * */

      const isInstDocTrackedThroughPatientDocuments = Array.isArray(patientEncounter.patientDocument) && patientEncounter.patientDocument.length >0

      const areDocSigned = (patientEncounter.patientPaymentProgram?.[0]?.areDocsSigned
          || patientEncounter.patientDocument?.[0]?.signatureTracking?.signatureStatus === "Signature Completed")

      return !areDocSigned && isInstDocTrackedThroughPatientDocuments && patientEncounter.patientId !== 0;
    });

    if (unsignedEncounter && Object.keys(unsignedEncounter).length > 0) {
      dispatch(getEncounterSignUrl({ patientId: unsignedEncounter?.patientId, encounterId: unsignedEncounter?.patientEncounterId }));
    }
  }

  useEffect(() => {
    if (isActivePrograms && allPatientEncounters && allPatientEncounters?.length > 0) {
      checkForUnsignedHelloSign();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allPatientEncounters])

  useEffect(() => { // this will fire on initial page load to kick off the process of getting dashboard set up
    dispatch(getConvertedPatients("0")); // 0 == all months worth of patients
    // eslint-disable-next-line
  }, [])

  useEffect(() => { // this will fire after ACH verifying is successful and we need to re-fetch the data
    if (refreshDashboard) {
      dispatch(resetRefreshAccountHolderDashboard());
      dispatch(getConvertedPatients("0")); // 0 == all months worth of patients
    }
    // eslint-disable-next-line
  }, [refreshDashboard])

  useEffect(() => {
    if (convertedPatientEncounters?.length > 0) {
      dispatch(setUpEncountersAccountHolder(
        {
          isActive: isActivePrograms || false,
          isCompleted: isCompletedPrograms || false,
          isCancelled: isCancelledPrograms || false,
          convertedPatientEncounters: convertedPatientEncounters
        }
      ))
    }
    // eslint-disable-next-line
  }, [dispatch, convertedPatientEncounters])

  const paymentProgressModalCallback = () => {
    setIsPaymentProgressModalOpen(true);
  }

  const [pEDocuments, setPEDocuments] = useState<Record<string, any>>({})

  const handleDocumentAccordionExpand = async (encounter: PatientEncounter) => {

    if (encounter?.patientId && encounter?.patientEncounterId) {

      //other documents can be found in the patientEncounter obj
      const s3DocumentResponse =  await patientService.getS3Documents(
          encounter?.patientId,
          encounter?.patientEncounterId
      )

      setPEDocuments({
        ...pEDocuments,
        [encounter?.patientEncounterId] : {
          s3Documents: !s3DocumentResponse.hasErrors ? s3DocumentResponse.entity: []
        }
      })
    }
  };

  const documentsView = (encounter: PatientEncounter) => (
        <CustomizeableAccordion
            icon={<InsertDriveFileIcon className="gray-icon" />}
            details={
              <div className="w-100">
                <DocumentsAccordionContents
                    documents={encounter?.patientDocument}
                    selectedEncounter={encounter}
                    isAccountHolder
                    s3Documents={pEDocuments?.[encounter?.patientEncounterId]?.s3Documents || []}
                />
              </div>
            }
            hasScroll
            headerText="Documents"
            expandCallback={async ()=> await handleDocumentAccordionExpand(encounter)}
        />
    );

  const contactInfoView = (contact:patientContact,patientId:number,encounterId:number) => {
    return (
      <CustomizeableAccordion
      icon={
        <LocationOnIcon className="gray-icon"/>
      }
      headerText="Contact Information"
      details={
        <div className="w-100">
          <ContactAccordionContents patientContact={contact} patientId={patientId} encounterId={encounterId}/>
        </div>
      }
      />
    )
  }

  const paymentProgressView = (encounter: PatientEncounter) => (
    <CustomizeableAccordion
      icon={
        <AttachMoneyIcon className="gray-icon" />
      }
      headerText="Payment Progress"
      dataTestId="payment-progress"
      details={showPaymentProgressDetails && (
        <div className="w-100">
          <PaymentProgressAccordionContents
            setPaymentProgressModalOpen={paymentProgressModalCallback}
            patientEncounter={encounter}
            isCompleteOrCancelled={(isCompletedPrograms || isCancelledPrograms)}
          />
        </div>
      )}
    />
  )

  const transactionsView = (encounter: PatientEncounter) => (
    <CustomizeableAccordion
      icon={
        <CompareArrowsIcon className="gray-icon" />
      }
      dataTestId="transactions"
      headerText="Transactions"
      hasScroll
      details={
        <div className="w-100">
          <TransactionsAccordionContents
            selectedEncounter={encounter}
            isAccountHolder
          />
        </div>
      }
    />
  )

  const paymentSourcesView = (encounter: PatientEncounter) => (
    <CustomizeableAccordion
      icon={
        <CreditCardIcon className="gray-icon"/>
      }
      hasScroll
      headerText="Payment Sources"
      details={
        <div className="w-100">
          <PaymentSourcesAccordionContents
            selectedEncounter={encounter}
            isCompleteOrCancelled={(isCompletedPrograms || isCancelledPrograms)}
          />
        </div>
      }
    />
  )

  // fetch the first recurring Payment Program's payment method last4.
  const getPaymentInfo = (encounter: PatientEncounter) => {
    let paymentInfo = 'N/A'
    if ((encounter?.patientPaymentMethods) && (encounter?.patientPaymentMethods?.length > 0)) {
      const recurringPaymentMethods = encounter?.patientPaymentMethods.filter(
        (paymentMethod: StripePaymentMethod) =>
        paymentMethod?.metadata.metaData_paymentMethodType?.includes("RECURRING") || paymentMethod?.metadata?.isRecurring
      ) || [];
      paymentInfo = (`●●●● ●●●● ●●●● ${recurringPaymentMethods &&
        (recurringPaymentMethods?.length > 0) ? recurringPaymentMethods[0]?.last4 : 'N/A'}`)
    }
    return paymentInfo;
  }

  // Returns Encounter Terms. if terms are 0 or undefined, we return 'N/A'.
  const getPatientEncounterTerms = (encounter: PatientEncounter) => {
    let encounterTerms = 'N/A';
    if (encounter?.patientPaymentProgram?.length > 0) {
      encounterTerms = (encounter?.patientPaymentProgram[0]?.patientPaymentSchedule?.terms === 0)
        ? 'N/A'
        : encounter?.patientPaymentProgram[0]?.patientPaymentSchedule?.terms?.toString();
    }
    return encounterTerms;
  }

  const getIsAnyPaymentNotVerified = (encounter: PatientEncounter) => {
    if (
      encounter?.patientPaymentMethods?.some((patientPaymentMethod: StripePaymentMethod) => (
        (patientPaymentMethod?.status !== 'verified') && (patientPaymentMethod?.object === 'bank_account')
      ))
    ) {
      const paymentMethods: StripePaymentMethod[] = encounter?.patientPaymentMethods?.filter((patientPaymentMethod: StripePaymentMethod) => (
       (patientPaymentMethod?.status !== 'verified') && (patientPaymentMethod?.object === 'bank_account')
      ))

      let paymentMethod: StripePaymentMethod | undefined;
      if (paymentMethods?.length > 1) {
        paymentMethod = paymentMethods[paymentMethods.length - 1];
      } else {
        paymentMethod = paymentMethods[0]
      }

      if (paymentMethod && (paymentMethod?.id !== firstPaymentNotVerified?.id)) {
        setFirstPaymentNotVerified(paymentMethod);
      }
      return true;
    }
  }

  const handleRefreshPaymentMethods = () => {
    dispatch(getPaymentMethods({
      patientId: currentConvertedEncounter?.patientId,
      encounterId: currentConvertedEncounter?.patientEncounterId,
      isAccountHolder: true,
      supressErrors: true
    }));
  }

  const handleRefreshTransactions = () => {
    if (currentConvertedEncounter?.patientEncounterId !== 0 && !missingAddressError) {
      dispatch(getTransactions({
        patientId: currentConvertedEncounter?.patientId,
        encounterId: currentConvertedEncounter?.patientEncounterId,
        isAccountHolder: true,
      }));
    }
  }

  const handleTakePaymentCallback = async (payment: any, response: any) => {
    await dispatch(updateEncountersAfterMakePayment({ payment, response, isAccountHolder: true }));
    await handleRefreshTransactions();
    await handleRefreshPaymentMethods();
  }

  return (
    <main className="active-view">
      <div className="view-content content">
        {((!isLoading) ) ? (
          <>
            {(!isError) ? (((selectedEncountersList.length > 0) && (selectedEncountersList[0])) ? selectedEncountersList.map((encounter, index) => {
              /**
               * Cannot use encounter.patientContactInfo (a misnomer actually)
               * because that is the account holder's contact info.
               * Account holder might be 3pg.
               * */
              let contactDetails = convertedPatientEncounters.filter((convertedEncounter)=>convertedEncounter.patientEncounterId === encounter.patientEncounterId)[0].contactCard;
              let workflowSubStatusDesc = encounter?.workflow?.workflowSubStatus.workflowSubStatusDesc;
              let statusId = encounter?.workflow?.workflowSubStatus.workflowSubStatusId;
              const doPaymentMethodsExist = !!(encounter?.patientPaymentMethods && (encounter?.patientPaymentMethods?.length > 0));
              const isPaymentVerified = (doPaymentMethodsExist && getIsAnyPaymentNotVerified(encounter));
              //to show the verify button, we need to check if the payment method is not verified in the payment program
              // const isPaymentVerified = doPaymentMethodsExist && !(encounter?.patientPaymentProgram?.[0]?.isACHVerified) && encounter?.patientPaymentProgram?.[0]?.isACH; //to do: reference user_payment_methods instead of stripe when FPS-9115 is done
              return (
                <div
                  className="mt-2"
                  key={index}
                  onClick={() => {
                    return (!(_.isEqual(currentConvertedEncounter, encounter)) ?
                      dispatch(setCurrentConvertedEncounter(encounter)) : '');
                  }}
                >
                  {(index === 0) && (
                    <Box display={{ xs: 'block', md: 'none' }} className="py-2">
                      <h1>{isActivePrograms ? 'Active Programs' : isCompletedPrograms ? 'Completed Programs' : 'Cancelled Programs'}</h1>
                    </Box>
                  )}
                  <Grid container spacing={2} justifyContent="center">
                    <Grid item xs={12}>
                      {
                        (workflowSubStatusDesc !== "None") && (
                          <Box display="flex" justifyContent="flex-start" alignItems="baseline">
                            <span className="missed-payments-span" style={{ backgroundColor: getPatientStatusByStatusId(statusId, StatusType['sub-status'], encounter?.workflow?.workflowId).color }}><WarningIcon style={{ fontSize: 14 }} />
                              {encounter?.workflow?.workflowSubStatus.workflowSubStatusDesc}
                            </span>
                          </Box>
                        )
                      }
                      <Paper elevation={1} className={(workflowSubStatusDesc !== "None") ? "px-4 py-4 missed-payments" : "px-4 py-4"} style={{ borderColor: getPatientStatusByStatusId(statusId, StatusType['sub-status'], encounter?.workflow?.workflowId).color }}>
                        <Box display="flex"
                          justifyContent="space-between">
                          <Grid item xs={12} md={6}>
                            <h1 data-testid="ioc-clientname">
                              {encounter?.clientName}
                            </h1>
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <h1 data-testid="ioc-facilityname">{encounter?.facilityName}</h1>
                          </Grid>
                        </Box>
                      </Paper>
                    </Grid>
                    {(isPaymentVerified && (
                      <Grid item xs={12} md={8}>
                        <Paper className="mt-2 px-4 py-4">
                          <Typography variant="h2" className="pt-2">
                            ACH Deposit Verification
                          </Typography>
                          <Box display="flex" justifyContent="flex-end" marginTop={-4}>
                            <Button
                              onClick={() => setIsVerifyBankModalOpen(true)}
                            >
                              Verify
                            </Button>
                          </Box>
                        </Paper>
                      </Grid>
                    ))}
                    <Grid item xs={12} md={8}>
                      <Paper className="mt-2 px-4 py-4">
                        <Box display="flex" flexWrap='wrap'
                          justifyContent="space-between"
                        >
                          <HeaderWithDetails header={"Created"} details={Utils.convertDate(new Date(encounter?.instanceCreateDt))} width="third" showNAIfUndefined />
                          <HeaderWithDetails header={"Patient"} details={`${contactDetails?.firstName} ${contactDetails?.lastName}`} width="third" />
                          <HeaderWithDetails header={"Starting Balance"} details={formatNumberToUSD(encounter?.pfrAmt)} width="third" />
                          <HeaderWithDetails
                            header={"Program"}
                            details={getPatientEncounterTerms(encounter)}
                            showNAIfUndefined
                            width="third"
                          />
                          <HeaderWithDetails
                            header={"Next Payment"}
                            details={encounter?.patientPaymentProgram?.length > 0 ? Utils.convertDate(new Date(encounter?.patientPaymentProgram[0]?.patientPaymentSchedule?.nextPaymentDueDt)) : undefined}
                            showNAIfUndefined
                            width="third"
                          />
                          <HeaderWithDetails header={"Payment Source"} details={getPaymentInfo(encounter)} showNAIfUndefined width="third" />
                        </Box>
                      </Paper>
                    </Grid>
                    <Grid className="mt-2 mb-8 pb-8" item xs={12} md={8}>
                      <div>
                        {documentsView(encounter)}
                        {encounter.patientContactInfo && contactInfoView(encounter.patientContactInfo,encounter.patientId,encounter.patientEncounterId)}
                        {paymentProgressView(encounter)}
                        {paymentSourcesView(encounter)}
                        {transactionsView(encounter)}
                      </div>
                    </Grid>
                  </Grid>
                </div>
              );
            }) : (
              <Typography variant="h1">
                No Programs Available
              </Typography>
            )) : (
              <Grid item xs={12}>
                <Typography variant="h1">
                  Programs Pending. Please refresh to try again.
                </Typography>
                <Box display="flex" justifyContent="flex-end" marginTop="-4rem">
                  <IconButton onClick={() => handleRefresh()} size="large">
                    <RefreshIcon fontSize='large' className="icon" style={{color: '297ec1'}} />
                  </IconButton>
                </ Box>
              </Grid>
            )
          }
          {isPaymentProgressModalOpen &&
            <TakePaymentModal
              open={isPaymentProgressModalOpen}
              handleModalCancel={handlePaymentProgressModalClose}
              paymentData={{
                patientId: currentConvertedEncounter.patientId,
                patientEncounterId: currentConvertedEncounter.patientEncounterId,
                paymentChannelId,
                disallowedPaymentTypes: [paymentToggleTypes.PAID_AT_FACILITY, paymentToggleTypes.CHECK],
                isOnlyAccountHolder: true,
                disallowedPatientAPI: true,
                paymentType: PAYMENT_TYPES.SELF,
              }}
              handleSubmitCallBack={handleTakePaymentCallback}
              hasPaymentReason={false}
            />
          }
          {isVerifyBankModalOpen && (
            <VerifyBankModal
              open={isVerifyBankModalOpen}
              handleModalClose={handleVerifyBankModalClose}
              paymentToVerify={firstPaymentNotVerified}
              encounter={currentConvertedEncounter}
            />
          )}
        </> ) : (
          <LoadingOverlay />
        )}
      </div>
    </main>
  );
}
export default AccountHolderView;
