import {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {clearStatus} from '../../../../admin-configuration/state/admin-configuration-slice';
import {
    showErrorStatus,
} from '../../../../security/state/user-slice';
import {RootState} from '../../../../shared/state/root-reducer';
import {AppDispatch} from '../../../../shared/state/store';
import {
    Estimate,
} from '../../../models/estimator';
import {Utils} from '../../../../shared/utils';
import _ from 'lodash';
import {
    callNewEstimatePost,
    callNewEstimatePut,
} from '../../../state/estimator-thunk';
import {
    Estimate as NewEstimate,
    FacilityLevelOfCare,
    QuoteMethod,
    FacilityLevelOfCareWithCrossoverDays,
    CrossoverSelectionEnum,
    Id,
    SummarySelections,
} from '@finpay/estimation-types';
import {admissionsAdvisorUtils} from '../../../utils/admission-advisor-utils';

import {
    NewLOCModal,
    VobClassificationUpdate,
    DialogActionButton,
    isCrossoverTriggered,
    getDaysBetweenAdmissionAndPolicyEndDates,
} from '@finpay-development/shared-components';
import {saveVob} from 'src/admissions-advisor/state/vob-thunk';
import {Vob, VobPostBody} from 'src/admissions-advisor/models/vob';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    Typography,
} from '@mui/material';
import {FormikProps} from 'formik';
import {AdmissionDate} from '../admission-date';
interface EstLevelOfCareModalProps {
    open: boolean;
    clientId: Id | undefined;
    facilityId: Id | undefined;
    facilityName: string | undefined;
    quoteMethod: string | QuoteMethod;
    admissionDate: string | Date;
    facilityLevelsOfCareList: FacilityLevelOfCare[];
    handleEstimatorLevelOfCareModalClose: () => void;
    promptUserToUpdateModalForCrossover?: boolean
}

export const stringToQuoteMethod = (quoteMethod: string): QuoteMethod => {
    switch (quoteMethod) {
        case 'sca':
            return QuoteMethod.SCA;
        case 'avglos':
            return QuoteMethod.AVGLOS;
        case 'manual':
            return QuoteMethod.MANUAL;
        case 'rlos':
            return QuoteMethod.RLOS;
        default:
            return QuoteMethod.AVGLOS;
    }
};

export const quoteMethodToString = (quoteMethod: QuoteMethod): string => {
    switch (quoteMethod) {
        case QuoteMethod.SCA:
            return 'sca';
        case QuoteMethod.AVGLOS:
            return 'avglos';
        case QuoteMethod.MANUAL:
            return 'manual';
        case QuoteMethod.RLOS:
            return 'rlos';
        default:
            return 'avglos';
    }
};

export const quoteMethodToDisplayString = (quoteMethod: QuoteMethod | string): string => {
    
    switch (quoteMethod) {
        case 'sca':
        case QuoteMethod.SCA:
            return 'Single Case Agreement';
        case 'avglos':
        case QuoteMethod.AVGLOS:
            return 'Average Length of Stay';
        case 'rlos':
        case QuoteMethod.RLOS:
            return 'Recommended Length of Stay';
        case 'manual':
        case QuoteMethod.MANUAL:
            return 'Manual';
        default:
            return 'Average Length of Stay';
    }
}


const compareFunction = (prevValue: any, nextValue: any) =>
    _.isEqual(prevValue, nextValue);

export function EstimatorLevelOfCareModal({
    open,
    clientId,
    facilityId,
    facilityName,
    quoteMethod,
    admissionDate,
    facilityLevelsOfCareList,
    handleEstimatorLevelOfCareModalClose,
    promptUserToUpdateModalForCrossover
}: EstLevelOfCareModalProps) {
    const state = {
        saveStatus: useSelector(
            (state: RootState) =>
                state.adminContext.adminConfigurationContext.modalSaveStatus
        ),
        errorMessage: useSelector(
            (state: RootState) =>
                state.adminContext.adminConfigurationContext.errorMessage
        ),
        vob: useSelector(
            (state: RootState) => state.admissionsAdvisorContext?.vobContext.vob
        ),
        vobPatientState: useSelector(
            (state: RootState) =>
                state.admissionsAdvisorContext.vobPatientContext
        ),
        patient: useSelector(
            (state: RootState) => state.patientContext.selectedPatient
        ),
        masterLevelsOfCareState: useSelector(
            (state: RootState) =>
                state.adminContext.adminConfigurationContext?.levelsOfCare,
            compareFunction
        ),
        isLoadingMasterLoc: useSelector(
            (state: RootState) =>
                state.admissionsAdvisorContext.estimatorContext.isLoading
                    .isLoadingMasterLoc
        ),
        getClientLOCStatus: useSelector(
            (state: RootState) =>
                state.admissionsAdvisorContext.estimatorContext.isLoading
                    .getClientLOCStatus
        ),
        isLoadingClientLoc: useSelector(
            (state: RootState) =>
                state.admissionsAdvisorContext.estimatorContext.isLoading
                    .isLoadingClientLoc
        ),
        configGetLOCStatus: useSelector(
            (state: RootState) =>
                state.admissionsAdvisorContext.estimatorContext.isLoading
                    .configGetLOCStatus
        ),
        clientLevelsOfCareState: useSelector((state: RootState) => {
            // TODO: use reselect memoization package to memoize this selector so that
            // this looping doesn't happen over and over again
            const masterLOCs =
                state.adminContext.adminConfigurationContext?.levelsOfCare;

            if (masterLOCs.length > 0) {
                return state.admissionsAdvisorContext.estimatorContext?.clientLevelsOfCare.map(
                    (loc: any) => {
                        // double O(n) time complexity
                        // consider sorting master list by id, then binary search
                        // performance improvement: O(n) -> O(log n)
                        const matchingLoc: any = masterLOCs.find(
                            (cfgLoc: any) => {
                                return (
                                    cfgLoc.levelOfCareId ===
                                    loc.cfgLevelOfCareId
                                );
                            }
                        );

                        return {
                            ...loc,
                            cfgLocType: matchingLoc?.locType || 0,
                        };
                    }
                );
            } else {
                return state.admissionsAdvisorContext.estimatorContext
                    ?.clientLevelsOfCare;
            }
        }),
        estimate: useSelector((state: RootState) => {
            return state.admissionsAdvisorContext.estimatorContext.estimate;
        }),
        vobClassifications: useSelector(
            (state: RootState) =>
                state.admissionsAdvisorContext.vobContext?.vobClassifications
        ),
    };

    const {
        saveStatus,
        errorMessage,
        vob,
        vobPatientState,
        estimate,
        vobClassifications,
    } = state;

    const [modalFormik, setModalFormik] = useState<FormikProps<any> | null>(
        null
    );

    const newQuoteMethod =
        typeof quoteMethod === 'string'
            ? stringToQuoteMethod(quoteMethod)
            : (quoteMethod as QuoteMethod);

    const [anticipatedAdmissionDate, setAnticipatedAdmissionDate] = useState(admissionDate ? new Date(admissionDate) : new Date())

    const paramId = -2;
    const dispatch = useDispatch<AppDispatch>();

    async function handleSave(
        selectedLocs: FacilityLevelOfCare[],
        vobClassificationUpdates: Record<string, VobClassificationUpdate>
    ) {
        const formikErrorMsg = getFormikErrMsg(modalFormik?.errors)

        if(formikErrorMsg){
            dispatch(showErrorStatus(formikErrorMsg))
            return
        }

        selectedLocs.sort((a: FacilityLevelOfCare, b: FacilityLevelOfCare) => a.sortOrder - b.sortOrder)

        if(
            //User got message after changing admission date from outside the modal
            promptUserToUpdateModalForCrossover &&
            //If the user changed admission date inside the modal, it might have disabled crossover. So need to double-check
            isCrossoverTriggered({
                selectedLocs: selectedLocs as FacilityLevelOfCareWithCrossoverDays[],
                policyEndDate: new Date(vob.policyEndDate),
                admissionDate: anticipatedAdmissionDate
            }) &&
            //For cases where user did not interact with the modal form at all
            !modalFormik?.dirty
        ){

            /**
             * Throwing error for when the user goes in the modal
             * after being prompted to update Days Before Crossover,
             * but tries to save right away without updating anything.
             * */

            if(
                admissionsAdvisorUtils.doLocsHaveUndefinedCrossoverValues(
                    selectedLocs as FacilityLevelOfCareWithCrossoverDays[])
            ){
                dispatch(showErrorStatus('Days Before Crossover must be at least 0 days'))
                return
            }

            if(
                admissionsAdvisorUtils.isSelectedBeforeCrossoverDayGreaterThanTotal({
                    selectedLocs:  selectedLocs as FacilityLevelOfCareWithCrossoverDays[],
                    policyEndDate: new Date(vob.policyEndDate),
                    admissionDate: anticipatedAdmissionDate
                })
            ){
                dispatch(showErrorStatus('The total Days Before Crossover must not exceed the available limit'))
                return
            }
        }

        const vobUpdateKeys = Object.keys(vobClassificationUpdates);
        const vobNeedsUpdate = vobUpdateKeys.length > 0;
        const inNetwork: boolean = vob?.payer?.inNetwork!;

        if (vobNeedsUpdate) {
            const vobCopy: Vob = Utils.deepClone(vob);

            const inNetwVobClassifications =
                vobCopy.inNetwVobClassifications || [];

            const ooNetwVobClassifications =
                vobCopy.ooNetwVobClassifications || [];

            vobUpdateKeys.forEach(key => {
                const {
                    toggleButtonSelection,
                    coPay,
                    coInsurance,
                    maxDays,
                    vobClassification,
                } = vobClassificationUpdates[key];
                let coPaySelection;

                switch (toggleButtonSelection) {
                    case 0:
                        coPaySelection = 'copayonly';
                        break;
                    case 1:
                        coPaySelection = 'n';
                        break;
                    case 2:
                        coPaySelection = 'y';
                        break;
                    default:
                        coPaySelection = 'copayonly';
                        break;
                }

                if (inNetwork) {
                    inNetwVobClassifications.push({
                        id: Math.random(),
                        selectedClassification: {
                            vobClassificationId:
                                vobClassification.vobClassificationId,
                            vobClassificationName: key,
                            vobClassificationDesc: key,
                            isInpatient: vobClassification.isInpatient,
                        },
                        coPaySelection,
                        coInsurance,
                        coPay,
                        maxDays,
                    });
                } else {
                    ooNetwVobClassifications.push({
                        id: Math.random(),
                        selectedClassification: {
                            vobClassificationId:
                                vobClassification.vobClassificationId,
                            vobClassificationName: key,
                            vobClassificationDesc: key,
                            isInpatient: vobClassification.isInpatient,
                        },
                        coPaySelection,
                        coInsurance,
                        coPay,
                        maxDays,
                    });
                }
            });

            const vobPostBody: VobPostBody = {
                advisorPatientId: vobPatientState.patient.advisorPatientId,
                fpClientId: vobCopy.client?.clientId,
                fpClientFacilityId: vobCopy.facility?.facilityId,
                vobBody: {
                    ...vobCopy,
                    inNetwVobClassifications,
                    ooNetwVobClassifications,
                },
            };

            await dispatch(saveVob(vobPostBody));
        }

        const isPlanYearCrossover = selectedLocs.some((loc: FacilityLevelOfCare) => {
            const crossoverLoc = loc as FacilityLevelOfCareWithCrossoverDays;
            return (
                Boolean(crossoverLoc.losDaysAfterCrossover) ||
                Boolean(crossoverLoc.losDaysBeforeCrossover)
            );
        });

        const createdDate = admissionsAdvisorUtils.formatDateTime(new Date());
        const levelsOfCare = selectedLocs
            ?.map((loc: FacilityLevelOfCare) => loc.facilityLevelOfCareName)
            .join(', ');

        const estimateDescription = `${levelsOfCare} - ${createdDate}`;
        const estimateCopy = Utils.deepClone(estimate);
        if (estimate?.estimateId) {
            const {
                priorCareSummary,
                summary,
                crossOverSummary,
                estimateId,
                createUserId,
                createDt,
                lastUpdateUserId,
                lastUpdateDt,
                ...estimatePutBody
            } = estimateCopy;

            const putEstimateBody: NewEstimate = {
                ...estimatePutBody,
                isPlanYearCrossover,
                quoteMethod: newQuoteMethod,
                anticipatedAdmitDate: anticipatedAdmissionDate,
                facilityLevelOfCare: selectedLocs,
                summarySelection: estimateCopy?.summarySelection || SummarySelections.NONADJUSTED,
                crossoverSelection:
                estimateCopy?.crossoverSelection ||
                CrossoverSelectionEnum.WITH, // default
            };

            await dispatch(
                callNewEstimatePut({estimate: putEstimateBody, estimateId})
            );
        } else {
            const postEstimateBody: NewEstimate = {
                vobId: vob.vobId!,
                clientId: clientId!,
                facilityId: facilityId!,
                advisorPatientId: vobPatientState.patient.advisorPatientId!,
                ...(estimateCopy?.patientEncounterId && { patientEncounterId: estimateCopy?.patientEncounterId }),
                description:
                    estimateCopy?.description ||
                    estimateDescription,
                quoteMethod: newQuoteMethod,
                anticipatedAdmitDate: anticipatedAdmissionDate,
                facilityLevelOfCare: selectedLocs,
                isPlanYearCrossover: isPlanYearCrossover,
                summarySelection: SummarySelections.NONADJUSTED,
                crossoverSelection:
                    estimateCopy?.crossoverSelection ||
                    CrossoverSelectionEnum.WITH, // default
                isActive: true,
            };
            await dispatch(callNewEstimatePost(postEstimateBody));
        }
        handleLocModalCallback(true);
    }

    function handleLocModalCallback(saveSuccessful: boolean | undefined) {
        if (saveSuccessful || typeof saveSuccessful === 'undefined') {
            handleEstimatorLevelOfCareModalClose();
        } else {
            dispatch(showErrorStatus(errorMessage));
        }
        dispatch(clearStatus());
    }

    const handleCancelCallback = () => {
        handleEstimatorLevelOfCareModalClose();
    };

    const isEdit = estimate?.facilityLevelOfCare && estimate?.facilityLevelOfCare?.length > 0

    return (
        <>
            <Dialog
                className="modal"
                open={open}
                fullWidth={true}
                maxWidth="lg"
                scroll="body"
            >
                <DialogTitle>{isEdit ? 'Edit' : 'Add'} Levels of Care</DialogTitle>
                <DialogContent>
                    <Grid container direction="row">
                        <Grid item xs={3} sm={3}>
                            <AdmissionDate
                                admissionDate={anticipatedAdmissionDate}
                        onChangeHandler={(date)=>{
                            setAnticipatedAdmissionDate(new Date(date))
                                }}
                            />
                        </Grid>
                        {/* <Grid item xs={4} sm={4}>
                            <TextField
                                select
                                label="Quote Method"
                                name="quoteMethod"
                                value={quoteMethodSelection}
                                className='state-field'
                                onChange={(e: any) => {
                                    handleQuoteMethodChange(e.target.value)
                                }}
                                onBlur={() =>{}}
                            >
                                <MenuItem value={QuoteMethod.AVGLOS}>Average Length of Stay</MenuItem>
                                <MenuItem value={QuoteMethod.RLOS}>Recommended Length of Stay</MenuItem>
                                <MenuItem value={QuoteMethod.MANUAL}>Manual</MenuItem>
                                <MenuItem value={QuoteMethod.SCA}>Single Case Agreement</MenuItem>
                            </TextField>
                        </Grid> */}
                    </Grid>
                    {
                        isCrossoverTriggered({
                            selectedLocs: modalFormik?.values?.selectedLocs || [],
                            admissionDate: anticipatedAdmissionDate,
                            policyEndDate: new Date(vob.policyEndDate)
                        }) && (
                            (()=>{
                                return (
                                    <Typography sx={{marginY: '1rem'}}>
                                        Total Days Before Crossover : {getDaysBetweenAdmissionAndPolicyEndDates({
                                        admissionDate: anticipatedAdmissionDate,
                                        policyEndDate: new Date(vob.policyEndDate)
                                    })} day(s)
                                    </Typography>
                                )
                            })()

                        )
                    }
                    <NewLOCModal
                        quoteMethod={newQuoteMethod}
                        initialListOfSelectedLoc={
                            estimate?.facilityLevelOfCare as FacilityLevelOfCareWithCrossoverDays[]
                        }
                        listOfLoc={facilityLevelsOfCareList}
                        admissionDate={anticipatedAdmissionDate}
                        policyEndDate={new Date(vob.policyEndDate)}
                        vob={vob}
                        vobClassifications={vobClassifications}
                        getFormikValues={(formik: any) => {
                            setModalFormik(formik as FormikProps<any>);
                        }}
                    />
                </DialogContent>
                <DialogActions>
                    <DialogActionButton
                        isEnabled={true}
                        savebuttonText="Save"
                        saveStatus={saveStatus}
                        spinnerLeftPosition={5}
                        executeSave={async () => {
                            await handleSave(
                                modalFormik?.values.selectedLocs,
                                modalFormik?.values.vobClassificationUpdates
                            );
                        }}
                        handleCallbackSave={handleLocModalCallback}
                        handleCallbackCancel={handleCancelCallback}
                    />
                </DialogActions>
            </Dialog>
        </>
    );
}

export function getFormikErrMsg (formikError?: Record<string, any> | any[] | null): string | undefined {

    if(!formikError) return

    if(!Array.isArray(formikError) && Object.values(formikError).length > 0) {

        if (typeof Object.values(formikError)?.[0] === 'string') {
            return Object.values(formikError)?.[0]
        } else {
            return getFormikErrMsg(Object.values(formikError)?.[0])
        }
    }

    if(Array.isArray(formikError)){

        const filteredFormikError = formikError.filter((formError: any)=> {
            if(!_.isNil(formError))
                return formError
        })

        return getFormikErrMsg(filteredFormikError[0])
    }

    return
}
