import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import PermContactCalendarIcon from '@mui/icons-material/PermContactCalendar';
import { Typography, Table, TableBody, TableCell, TableContainer, TableRow } from "@mui/material";

import { RootState } from "../../../shared/state/root-reducer";
import { formatNumberToUSD } from "../../../shared/calculators";

export function SidebarFinancialSummary() {

	const estimate = useSelector((state: RootState) => {
		return state.admissionsAdvisorContext.estimatorContext.estimate
	})

  const financialSummary = estimate?.summary

  const totalCharges = formatNumberToUSD((financialSummary?.coveredCharges || 0) + (financialSummary?.unCoveredCharges || 0))
  const coveredCharges = formatNumberToUSD(financialSummary?.netPFR)
  const unCoveredCharges = formatNumberToUSD(financialSummary?.unCoveredCharges) 
  const totalSurcharges = formatNumberToUSD(financialSummary?.totalSurcharges) 
  
  const showSurcharges = Boolean(financialSummary?.totalSurcharges && financialSummary?.totalSurcharges > 0 )

  const summaryExists = estimate?.facilityLevelOfCare && estimate?.facilityLevelOfCare?.length > 0 && estimate?.summary
	return (
		<>
			{summaryExists ? (
				<>
					<TableContainer>
						<Table size="small" aria-label="a dense table">
							<TableBody>
									<TableRow >
										<TableCell component="th" scope="row">
										Estimated Adjusted Charges
										</TableCell>
										<TableCell align="right"><strong test-id="side-financial-summary-est-adj-charges">{totalCharges}</strong></TableCell>
									</TableRow>
									<TableRow >
										<TableCell component="th" scope="row">
										PFR for Covered Services
										</TableCell>
										<TableCell align="right"><strong test-id="side-financial-summary-pfr-covered-services">{coveredCharges}</strong></TableCell>
									</TableRow>
									<TableRow style={showSurcharges ? {} : {borderBottom: "none"}}>
										<TableCell component="th" scope="row" >
										PFR for Uncovered Services
										</TableCell>
										<TableCell align="right"><strong test-id="side-financial-summary-pfr-uncovered-services">{unCoveredCharges}</strong></TableCell>
									</TableRow>
									{showSurcharges && (
									<TableRow style={{borderBottom: "none"}}>
										<TableCell component="th" scope="row" >
										Admission Assessment
										</TableCell>
										<TableCell align="right" ><strong test-id="side-financial-summary-admin-assessment">{totalSurcharges}</strong></TableCell>
									</TableRow>
									)}
							</TableBody>
						</Table>
					</TableContainer>
				</>
      ) : (
				<Typography 
					align="center"
					style={{
						color: "#B4BECC",
						fontSize: "10pt"
					}}>
					<PermContactCalendarIcon /><br/>Add levels of care to estimate patient financial responsibility
				</Typography>
      )}
		</>
	)
	

}