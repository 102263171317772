import { DialogActionButton, LoadingOverlay, Button } from '@finpay-development/shared-components';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';
import { TakePaymentView } from './take-payment-view';
import TakePaymentModalContent, { paymentToggleTypes } from './take-payment-modal-content';
import { RootState } from '../../state/root-reducer';
import { vobPatient } from '../../../admissions-advisor/models/patient';
import { PaymentType } from 'src/patient/components/models/payment-type';
import { patientService } from 'src/patient/services/patient-service';
import { AppDispatch } from "../../state/store";
import {
  addPatientPaymentMethod,
  createSPAHelloSignRequest,
  getPaymentMethods,
} from 'src/patient/state/patient-thunk';
import { showErrorStatus, showStatus } from 'src/security/state/user-slice';
import { Utils } from '../../utils';
import { PAYMENT_METHOD_TYPES } from 'src/patient/components/models/payment-method';
import { PAYMENT_TYPES } from 'src/patient/components/models/payment-type';
import { PatientPaymentProgram } from 'src/patient/components/models/patient-payment-program';
import { Payment } from 'src/patient/components/models/payment';
import { PaymentMethod } from 'src/patient/components/models/payment-method';
import { PaymentDetail } from 'src/patient/components/models/payment-detail';
import { PatientPaymentSchedule } from 'src/patient/components/models/patient-payment.schedule';
import MobilePaymentsLoader from '../mobile-payments-loader';
import { ApiBatchLimits, stripePaymentErrors } from 'src/shared/enums';
import { setCurrentClient } from 'src/implementation-specialist/state/clients/implementation-clients-slice';
import yupValidationSchema from './validation-schema';
import { saveUser } from 'src/admin/state/users/admin-thunk';
import { UserInfo } from 'src/admin/models/user-info';
import { adminUserHelper, userService } from 'src/admin/services/admin-user-service';
import { UserInfoClient } from 'src/admin/models/user-info-client';
import { axiosSaveHelper } from 'src/shared/service/axios-save-helper';
import { ClientStatusCardViewModel } from 'src/shared/model/client-status-card';

interface TakePaymentProps {
  open: boolean;
  handleModalCancel: () => void;
  vobPatientState?: vobPatient;
  paymentData?: {
    paymentType?: PaymentType,
    patientId: number;
    patientEncounterId: number;
    defaultPaymentAmt?: number;
    paymentChannelId: number;
    disallowedPaymentTypes?: string[];
    isOnlyAccountHolder?: boolean;
    disallowedPatientAPI?: boolean;
    isAuthOnly?: boolean;
  }
  handleSubmitCallBack?: (payment: any, response: any) => void;
  hasPaymentReason?: boolean;
}

export function TakePaymentModal(props: TakePaymentProps) {
  const { open, handleModalCancel, vobPatientState, paymentData, handleSubmitCallBack, hasPaymentReason } = props;
  const patientId = paymentData?.patientId || 0;
  const encounterId = paymentData?.patientEncounterId || 0;
  const defaultPaymentAmt = paymentData?.defaultPaymentAmt || '';
  const paymentChannelId = paymentData?.paymentChannelId;
  const disallowedPaymentTypes = paymentData?.disallowedPaymentTypes || [];
  const isOnlyAccountHolder = !!paymentData?.isOnlyAccountHolder;
  const disallowedPatientAPI = !!paymentData?.disallowedPatientAPI;
  const paymentType = paymentData?.paymentType;
  const isAuthOnly = !!paymentData?.isAuthOnly

  const [isIOCLoading, setIsIOCLoading] = useState(false);
  const [isPatientLoading, setIsPatientLoading] = useState(false);
  const [isPaymentMethodLoading, setIsPaymentMethodLoading] = useState(false);
  const [isPayProcessing, setIsPayProcessing] = useState(false);
  const dispatch = useDispatch<AppDispatch>();

  const [patientIOCData, setPatientIOCData] = useState<any>(null);
  const [patientDetails, setPatientDetails] = useState<any>(null);

  const requiredKeys = ['patientPaymentScheduleId', 'pfrAmt', 'paymentDueAmt', 'downPmtAmt', 'paymentFreq', 'remainingTerms', 'scheduleStatus', 'scheduleStartDt', 'nextPaymentDueDt', 'paymentStartDay', 'terms'];

  const lastPaymentProgram = patientIOCData?.patientPaymentProgram?.length > 0
    ? patientIOCData?.patientPaymentProgram[patientIOCData?.patientPaymentProgram?.length - 1]
    : null;

  const isAchVerified = lastPaymentProgram?.isACHVerified;
  const isAch = lastPaymentProgram?.isACH;
  const workflowStatusId = lastPaymentProgram?.workFlow?.workflowStatus?.workflowStatusId;
  const workflowSubStatusId = lastPaymentProgram?.workFlow?.workflowSubStatus?.workflowSubStatusId;
  const patientPaymentProgram = lastPaymentProgram
    ? {
        ..._.omit(lastPaymentProgram, ['isACHVerified', 'isACH', 'patientPaymentMethod', 'workFlow', 'workflowDueDt', 'workflowStatusDt']),
        ...(lastPaymentProgram.highRiskApprovalDt == null && { highRiskApprovalDt: new Date().toISOString() }), // Add highRiskApprovalDt only if it's null
        ...lastPaymentProgram.isAch = isAch,
        ...lastPaymentProgram.isAchVerified = isAchVerified,
        ...lastPaymentProgram.workflowStatusId = workflowStatusId,
        ...lastPaymentProgram.workflowSubStatusId = workflowSubStatusId,
        patientPaymentSchedule: {
          // Pick only the required keys from patientPaymentSchedule
          ..._.pick(lastPaymentProgram.patientPaymentSchedule, requiredKeys),
          ...(lastPaymentProgram.patientPaymentSchedule?.nextPaymentDueDt == null && { nextPaymentDueDt: new Date().toISOString() }), // Add nextPaymentDueDt only if it's null
          ...(lastPaymentProgram.patientPaymentSchedule?.downPmtAmt == null && { downPmtAmt: 0 }) // Add downPmtAmt only if it's null
        }
      }
    : {} as PatientPaymentProgram;


  const isDisabledACH = !!patientPaymentProgram?.isAch || !!patientIOCData?.isConverted;

  const title = 'Take a Payment';

  const stateFields = {
    stripeCardToken: useSelector(
      (state: RootState) => state.patientContext.downPaymentTokens.token
    ),
    stripeRecurringCardToken: useSelector(
      (state: RootState) => state.patientContext.recurringPaymentTokens.token
    ),
    stripeBankToken: useSelector(
      (state: RootState) => state.patientContext.downPaymentTokens.bankToken
    ),
    stripeRecurringBankToken: useSelector(
      (state: RootState) => state.patientContext.recurringPaymentTokens.bankToken
    ),
    allClients: useSelector((state: RootState) => state.implementationContext.implementationSpecialistClient.allClients),
    currentClient: useSelector((state: RootState) => state.implementationContext.implementationSpecialistClient.currentClient),
    isPatientPortal: useSelector((state: RootState) => state.userContext.userProfile?.isPatient),
  };

  const {
    allClients,
    currentClient,
    stripeCardToken,
    stripeRecurringCardToken,
    stripeBankToken,
    stripeRecurringBankToken,
    isPatientPortal
  } = stateFields;

  const client: ClientStatusCardViewModel | undefined = allClients.find(
    (c: ClientStatusCardViewModel) =>
        c.clientId === patientIOCData?.clientId
  );

  const isPaymentProgramVersionGreaterOrEqualTo4 = typeof client?.paymentProgramVersion === 'number' && client?.paymentProgramVersion >= 4;

  useEffect(() => {
    (async () => {
      if (encounterId) {
        setIsIOCLoading(true);
        const res: any = await patientService.getPatientInstanceOfCare({ patientId, encounterId });
        setPatientIOCData(res.entity);
        setIsIOCLoading(false);
      }
    })();
  }, [encounterId]);

  useEffect(() => {
    if (patientId && !disallowedPatientAPI) {
      (async () => {
        setIsPatientLoading(true);
        const res: any = await patientService.getPatient(patientId);
        setPatientDetails(res.entity);
        if (allClients?.length > 0) {
          const filteredClient = allClients.find((v) => v.clientId === res.entity.clientId);
          dispatch(setCurrentClient(filteredClient));
        }
        setIsPatientLoading(false);
      })();
    }
  }, [patientId]);

  const saveStatus = useSelector(
    (state: RootState) =>
      state.adminContext.adminConfigurationContext.modalSaveStatus
  );

  const processError = (response: any) => {
    if (response.entity?.code) {
      if (response.entity?.message === stripePaymentErrors.generic_decline) {
        dispatch(showErrorStatus(`${response.entity?.message} - ${response.entity?.code}:${response.entity?.declineCode}`));
      } else {
        dispatch(showErrorStatus(response.entity?.message || response.entity));
      }
    } else {
      dispatch(showErrorStatus(response.entity || response.errorMessage));
    }
  }

  const formik = useFormik(
    vobPatientState
      ? {
        initialValues: {
          patientTitle: '',
          patientFirstName: vobPatientState.firstName,
          patientMI: vobPatientState.middleName
            ? vobPatientState.middleName
            : '',
          patientLastName: vobPatientState.lastName,
          email: vobPatientState?.advisorPatientBody?.email
            ? vobPatientState.advisorPatientBody.email
            : '',
          phone1: vobPatientState?.advisorPatientBody?.phoneNumber
            ? vobPatientState.advisorPatientBody.phoneNumber
            : '',
          phone1Home: false,
          phone2: '',
          phone2Home: false,
          retypeEmail: vobPatientState?.advisorPatientBody?.email
            ? vobPatientState.advisorPatientBody.email
            : '',
          streetAddress1: vobPatientState?.advisorPatientBody?.primaryAddress
            ?.streetAddress1
            ? vobPatientState.advisorPatientBody.primaryAddress
              .streetAddress1
            : '',
          streetAddress2: vobPatientState?.advisorPatientBody?.primaryAddress
            ?.streetAddress2
            ? vobPatientState.advisorPatientBody.primaryAddress
              .streetAddress2
            : '',
          city: vobPatientState?.advisorPatientBody?.primaryAddress?.city
            ? vobPatientState.advisorPatientBody.primaryAddress.city
            : '',
          stateCode: vobPatientState?.advisorPatientBody?.primaryAddress
            ?.state?.stateCode
            ? vobPatientState.advisorPatientBody.primaryAddress.state
              .stateCode
            : '',
          zip: vobPatientState?.advisorPatientBody?.primaryAddress?.zipCode
            ? vobPatientState.advisorPatientBody.primaryAddress.zipCode
            : '',
          timingRisk: vobPatientState?.selectedIOC?.timingRisk?.timingRiskId
            ? vobPatientState.selectedIOC.timingRisk.timingRiskId
            : null,
          payorRisk: vobPatientState?.selectedIOC?.payorRisk?.payorRiskId
            ? vobPatientState.selectedIOC.payorRisk.payorRiskId
            : null,
          isWarmTransfer: '',
          noteText: '',
          isPayNow: false,
          payNowAmt: '',
          isPayAtFacility: false,
          payAtFacilityAmt: '',
          useExistingSource: false,
          savePaymentMethod: false,
          nameOnCard: '',
          leaveVMFl: false,
          smsConsentFl: true,
          paymentReason: '',
        },
        onSubmit: () => { }
      }
      : {
        initialValues: {
          useExistingSource: false,
          amount: defaultPaymentAmt,
          payorName: '-1',
          paymentMethodType: paymentToggleTypes.CREDIT_CARD,
          nameOnCard: "",
          bankName: "",
          nameOnAccount: "",
          routingNumber: "",
          accountNumber: "",
          retypeAccountNumber: "",
          paymentDay: "",
          recurringPayment: false,
          receiptEmail: '',
          paymentSources: '',
          useAsRecurringSource: false,
          paymentReason: '',
          firstName: "",
          lastName: "",
        },
        validationSchema: yupValidationSchema,
        onSubmit: async (values) => {
          setIsPayProcessing(true);
          const successMsg = isAuthOnly ? 'Payment Authorization Successful' : 'Payment Successful';
          try {
            const receiptEmail = values.receiptEmail;
            let userRecord;
            if (!isPatientPortal) {
              userRecord = (await userService.getAllUsers(0, ApiBatchLimits.users, { userName: receiptEmail || '' }))?.entity?.[0]
              if (userRecord && !userRecord.isPatient) {
                // Case: User exists but is not a patient
                dispatch(showErrorStatus("Finpay Admin user already exists with this username. Please try another email"));
                handleModalCancel();
                return;
              }
            }

            let newPayorName = '';
            // create patient champion
            if (values.payorName === 'add-new-payor') {
              const fullName = getFullName(values);
              const contact = {
                firstName: (fullName || '').split(' ')[0] || '',
                lastName: (fullName || '').split(' ').slice(1).join(' '),
                email: receiptEmail,
              };
              const customer = await patientService.createCustomer(contact);
              const newChampion = {
                patientEncounterId: encounterId,
                contact: {
                  ...contact,
                  leaveVMFl: false,
                  smsConsentFl: false,
                  externalId: customer?.entity?.id,
                }
              }
              const newPatientChampion = await patientService.createPatientChampion(newChampion);
              newPayorName = newPatientChampion.entity.patientChampionId;
            }
            const paymentMethodType = values.paymentMethodType;

            if (!patientPaymentProgram?.patientPaymentSchedule?.patientPaymentScheduleId) {
              const paymentProgram = mapToPatientPaymentProgram();
              const paymentProgramResponse: any = await patientService.savePatientPaymentProgram({
                paymentProgram, patientId, encounterId,
              });
              const downPaymentInfo = mapToDownPayment({ newPayorName });
              downPaymentInfo.patientPaymentScheduleId = _.get(paymentProgramResponse.entity, 'patientPaymentSchedule.patientPaymentScheduleId');
              downPaymentInfo.paymentGroupId = downPaymentInfo.patientPaymentScheduleId?.toString();
              const response = await patientService.createPayment(downPaymentInfo);
              if (!response.hasErrors) {
                dispatch(showStatus(successMsg));
                await checkAndCreateUser(userRecord);
              } else {
                processError(response);
              }
              handleSubmitCallBack && handleSubmitCallBack(downPaymentInfo, response);
            } else {
              if ((paymentMethodType !== paymentToggleTypes.CREDIT_CARD && stripeBankToken)
                || ((paymentMethodType === paymentToggleTypes.CREDIT_CARD) && stripeCardToken)
                || values.useExistingSource
              ) { // handles ACH and Credit card scenarios.
                if ((values?.recurringPayment === true) && // when we are also saving a recurring payment as well as down payment.
                  ((paymentMethodType !== paymentToggleTypes.CREDIT_CARD && stripeRecurringBankToken)
                    || ((paymentMethodType === paymentToggleTypes.CREDIT_CARD) && stripeRecurringCardToken)
                  )
                ) { // for payment types of Credit or ACH
                  if (paymentMethodType === paymentToggleTypes.ACH) {
                    await patientService.savePatientPaymentProgram({
                      paymentProgram: {
                        ...patientPaymentProgram,
                        isACH: true,
                      },
                      patientId,
                      encounterId,
                    });
                  }
                  const downPaymentInfo = mapToDownPayment({ newPayorName });
                  const recurringPaymentInfo = mapToRecurringPayment();
                  const response = await patientService.createPayment(downPaymentInfo);

                  if (!response.hasErrors) {
                    await dispatch(
                      addPatientPaymentMethod({
                        patientId: patientId,
                        encounterId: encounterId,
                        paymentDetail: recurringPaymentInfo,
                        isAccountHolder: false,
                      })
                    );
                    dispatch(showStatus(successMsg));
                    await checkAndCreateUser(userRecord);
                  } else {
                    processError(response);
                  }
                  handleSubmitCallBack && handleSubmitCallBack(downPaymentInfo, response);
                } else {
                  if (paymentMethodType === paymentToggleTypes.ACH) {
                    await patientService.savePatientPaymentProgram({
                      paymentProgram: {
                        ...patientPaymentProgram,
                        isACH: true,
                      },
                      patientId,
                      encounterId,
                    });
                  }
                  const downPaymentInfo = mapToDownPayment({ newPayorName });

                  const response = await patientService.createPayment(downPaymentInfo);
                  if (!response.hasErrors) {
                    await handleCreateSPARequest(downPaymentInfo);
                    dispatch(showStatus(successMsg));
                    await checkAndCreateUser(userRecord);
                  } else {
                    processError(response);
                  }
                  handleSubmitCallBack && handleSubmitCallBack(downPaymentInfo, response);
                }
              } else if (paymentMethodType === paymentToggleTypes.PAID_AT_FACILITY
                || paymentMethodType === paymentToggleTypes.CHECK) { // handles the PAID AT FACILITY payment type.
                const downPaymentInfo = mapToDownPayment({ newPayorName });
                const response = await patientService.createPayment(downPaymentInfo);
                if (!response.hasErrors) {
                  dispatch(showStatus(successMsg));
                  await checkAndCreateUser(userRecord);
                } else {
                  processError(response);
                }
                handleSubmitCallBack && handleSubmitCallBack(downPaymentInfo, response);
              }
            }
            handleModalCancel();
          } catch (error: any) {
            console.log('error:', error.message);
            await dispatch(showErrorStatus(error.message
              || 'Something Went Wrong'));
          }
          //to do: consolidate with the one in down-payment-modal.tsx
          setIsPayProcessing(false);
        }
      });

  const getFullName = (currentVals: any) => {
    const { paymentMethodType, nameOnCard, firstName, lastName, nameOnAccount } = currentVals;
    return paymentMethodType === paymentToggleTypes.CREDIT_CARD
        ? nameOnCard
        : paymentMethodType === paymentToggleTypes.ACH
          ? nameOnAccount 
          : `${firstName} ${lastName}`.replace(/\s+/g, ' ').trim();
  }

  const checkAndCreateUser = async (userRecord: any) => {
    if (isOnlyAccountHolder || !isPaymentProgramVersionGreaterOrEqualTo4) return; // do not need to post user on patient portal
    
    let userEmail = '';
    let userName = '';

    const currentVals = formik?.values;
    userEmail = currentVals.receiptEmail || '';
    if (currentVals?.payorName === 'add-new-payor') {
      userName = getFullName(currentVals);
    } else {
      userName = Utils.getPayorName(
        {
          payorId: parseInt(currentVals?.payorName || ''),
          patientId,
          selectedPatient: patientDetails,
          selectedEncounter: patientIOCData,
        }
      );
    }

    userName = userName.replace(/\s{2,}/g, ' ');

    const userClientScopeToSave = new UserInfoClient();
    userClientScopeToSave.clientId = patientIOCData?.clientId;
    userClientScopeToSave.isFacilityLevel = true;
    userClientScopeToSave.allowedFacilities = [];
    
    if (!userRecord) {
      // Create new user if none exists
      const userToCreate = {
        userName: userEmail,
        firstName: userName.split(' ')[0],
        lastName: userName.split(' ')[1],
        userScope: "FINPAY",
        clientId: patientIOCData?.clientId,
        entityId: 0,
        isActive: true,
        isPatient: true,
        convRunNum: 1,
        userRole: {
          userRoleId: 4,
          roleName: "Account Holder",
          roleDesc: "User Role for Patients or Patient Guarators",
          roleType: "E",
          isActive: true
        },
        allowedClients: [userClientScopeToSave],
        patientEncounterId: patientIOCData?.patientEncounterId
      };
      await userService.createOrUpdateUser(userToCreate);
    } else {
      const allowedClients = userRecord.clients?.find((allowedClient: any) => 
        allowedClient.clientId === patientIOCData?.clientId
      );
      if (!allowedClients) {
        // Update isFacilityLevel for existing clients if null
        const updatedClients = (userRecord.clients || []).map((client: any) => {
          // Skip updating if isFacilityLevel is already set (non-null)
          if (client.isFacilityLevel === null) {
            return {
              ...client,
              isFacilityLevel: true, // Update to true if null
            };
          }
          return client; // Keep unchanged if isFacilityLevel is already set
        });
        // // Case: User is a patient but has different clientId - update needed
        await axiosSaveHelper({
          dataToSave: {
            allowedClients: [
              ...updatedClients,
              userClientScopeToSave
            ]
          },
          dataId: userRecord.userId,
          url: "user/v2/user",
        });
      }
      // Case: User is a patient and has matching clientId - no action needed
      return;
    }
  }

  const mapToPatientPaymentProgram = () => {
    const pfrAmt = patientIOCData.pfrAmt;
    const currentDate = new Date();
    const terms = 0;
    const recurringPaymentAmt = 0;
    const patientPaymentSchedule = {
      pfrAmt: pfrAmt,
      pfrBalance: pfrAmt,
      paymentFreq: 'M',
      downPmtAmt: formik.values.amount,
      terms: terms,
      remainingTerms: terms,
      paymentDueAmt: recurringPaymentAmt,
      scheduleStatus: 'Pending',
      scheduleStartDt: currentDate.toISOString(),
    } as PatientPaymentSchedule

    const paymentProgram = {
      patientPaymentProgramId: 0,
      isHighRisk: false,
      isPaidInFull: formik.values.amount === patientIOCData.pfrAmt,
      downPmtAmt: 0, //setting to 0 as we are not saving the down payment amount from the take payment modal for the program
      isACH: formik.values.paymentMethodType === paymentToggleTypes.ACH,
      areDocsSigned: false,
      patientPaymentSchedule: patientPaymentSchedule,
    } as PatientPaymentProgram
    return paymentProgram;
  }

  const getPaymentInfo = () => {
    let paymentTypeObj;
    if (paymentType) {
      paymentTypeObj = paymentType;
    } else {
      paymentTypeObj = !!patientIOCData?.isConverted
        ? PAYMENT_TYPES.SPECIALIST
        : PAYMENT_TYPES.DOWN_PAYMENT;
    }
    switch (formik.values.paymentMethodType) {
      case paymentToggleTypes.CREDIT_CARD:
        return {
          paymentMethod: PAYMENT_METHOD_TYPES.CARD,
          paymentTypeObj,
        };
      case paymentToggleTypes.ACH:
        return {
          paymentMethod: PAYMENT_METHOD_TYPES.ACH,
          paymentTypeObj,
        };
      case paymentToggleTypes.PAID_AT_FACILITY:
      case paymentToggleTypes.CHECK:
        return {
          paymentMethod: undefined, // no payment method needed for 'paid at facility' type
          paymentTypeObj: PAYMENT_TYPES.AT_FACILITY
        }
      default:
        return {
          paymentMethod: undefined,
          paymentTypeObj,
        };
    };
  }

  const getPaymentMethod = () => {
    const { paymentMethod } = getPaymentInfo();
    const currentFormValues = formik?.values;
    if (currentFormValues?.useExistingSource) {
      return {
        externalPaymentId: JSON.parse(currentFormValues?.paymentSources || '')?.id
      };
    }
    return {
      externalPaymentId: (paymentMethod?.externalPaymentMethodId === PAYMENT_METHOD_TYPES.CARD.externalPaymentMethodId)
        ? stripeCardToken
        : (paymentMethod?.externalPaymentMethodId === PAYMENT_METHOD_TYPES.ACH.externalPaymentMethodId
          ? stripeBankToken
          : null
        )
    }
  }

  const mapToDownPayment = ({ newPayorName = '' }) => {
    const currentVals = formik?.values;
    const currentDateTime = new Date();

    const { paymentMethod, paymentTypeObj } = getPaymentInfo();
    let receiptEmail = currentVals.receiptEmail;
    if (!receiptEmail) {
      const championList = Utils.getPatientsAndChampionsList(patientDetails, patientIOCData, true);
      if (championList.length > 1) {
        receiptEmail = championList[1].email;
      } else {
        receiptEmail = championList[0].email;
      }
    }

    const existingPaymentType = currentVals.useExistingSource
      ? JSON.parse(currentVals.paymentSources || '{}').paymentMethodType
      : null;

    return {
      // paymentId: 0, // always 0 when we are POSTing a new payment. use paymentID for release payment
      isManual: true, // hardcoded
      isAuthExpired: false, // hardcoded

      paymentGroupId: `${patientPaymentProgram?.patientPaymentSchedule?.patientPaymentScheduleId}`,
      patientPaymentScheduleId: patientPaymentProgram?.patientPaymentSchedule?.patientPaymentScheduleId,
      patientId,
      patientEncounterId: encounterId,
      receiptEmail: receiptEmail || currentClient?.supportEmail || "support@finpay.net",
      paymentMethods: [{
        ...paymentMethod,
        payorId: currentVals?.payorName === 'add-new-payor'
          ? newPayorName
          : (
            (+(currentVals?.payorName || 0) === patientId)
              ? "" : currentVals?.payorName // don't send if same as Patient Id
          ),
        ...getPaymentMethod(),
        nameOnCard: currentVals.useExistingSource
          ? JSON.parse(currentVals.paymentSources || '{}').name
          : (
            currentVals.paymentMethodType === paymentToggleTypes.CREDIT_CARD
              ? currentVals?.nameOnCard
              : Utils.getPayorName({
                payorId: parseInt(currentVals?.payorName || ''),
                patientId,
                selectedPatient: patientDetails,
                selectedEncounter: patientIOCData,
              })
          ),
        // @ts-ignore
        isDefault: currentVals?.isDefault,
      }],
      paymentChannelId,
      paymentAmt: currentVals?.amount,
      paymentType: paymentTypeObj,
      paymentInitDt: currentDateTime.toISOString(),
      isAuthOnly: isAuthOnly,
      isRecurringDownPayment: existingPaymentType === 'RECURRING',
      ...(currentVals.paymentReason ? { paymentReason: currentVals.paymentReason } : {}),
    } as Payment;
  }

  const mapToRecurringPayment = () => {
    const currentVals = formik.values;
    const paymentDay = new Date(currentVals?.paymentDay || '').setUTCHours(23, 59, 59, 999);
    const paymentDayIso = new Date(paymentDay || '').toISOString();

    const paymentMethods = [{
      paymentMethodId: 0,
      payorId: +(currentVals?.payorName || 0) === patientId || currentVals?.payorName === "-1"
        ? null : currentVals?.payorName,
      paymentAmt: patientPaymentProgram?.patientPaymentSchedule?.paymentDueAmt,
      scheduleStartDt: paymentDayIso,
      externalPaymentMethodId: currentVals?.paymentMethodType === paymentToggleTypes.CREDIT_CARD
        ? PAYMENT_METHOD_TYPES.CARD.externalPaymentMethodId
        : PAYMENT_METHOD_TYPES.ACH.externalPaymentMethodId,
      paymentMethodTypeId: currentVals?.paymentMethodType === paymentToggleTypes.CREDIT_CARD
        ? PAYMENT_METHOD_TYPES.CARD.paymentMethodTypeId
        : PAYMENT_METHOD_TYPES.ACH.paymentMethodTypeId,
      externalPaymentId: currentVals?.paymentMethodType === paymentToggleTypes.CREDIT_CARD
        ? stripeRecurringCardToken
        : stripeRecurringBankToken,
      receiptEmail: currentVals.receiptEmail ? currentVals.receiptEmail : "support@finpay.net",
      nameOnCard: currentVals.paymentMethodType === paymentToggleTypes.CREDIT_CARD
        ? currentVals?.nameOnCard : Utils.getPayorName(
          {
            payorId: parseInt(currentVals?.payorName || ''),
            patientId,
            selectedPatient: patientDetails,
            selectedEncounter: patientIOCData,
          }
        )
    }] as PaymentMethod[]
    const paymentType = PAYMENT_TYPES.RECURRING

    return {
      paymentMethods: paymentMethods,
      paymentChannelId,
      paymentType: paymentType
    } as PaymentDetail
  }

  const isPatient = patientIOCData?.patientChampion?.filter((x: any) => x.isGuarantor).length <= 0;
  const signersId = patientIOCData?.patientChampion?.filter((x: any) => x.isGuarantor).length <= 0
    ? patientId : patientIOCData?.patientChampion?.filter((x: any) => x.isGuarantor)[0]?.patientChampionId || patientId;

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async function handleCreateSPARequest(downPaymentInfo: Payment) {
    if (patientPaymentProgram?.patientPaymentSchedule?.paymentFreq === 'F' && patientIOCData?.pfrAmt === downPaymentInfo?.paymentAmt) {
      await dispatch(createSPAHelloSignRequest({
        pfrAmount: patientIOCData?.pfrAmt,
        paymentTotal: downPaymentInfo?.paymentAmt,
        clientId: patientIOCData?.clientId,
        facilityId: patientIOCData?.facilityId,
        encounterId,
        signersId,
        isPatient,
        isFullPay: patientPaymentProgram?.isPaidInFull,
      }));
    }
  }

  const handleStripeStatus = (stripeStatus: boolean) => {
    // setStripePaymentReadyStatus(stripeStatus);
  };

  function handleSaveCallback(saveSuccessful: boolean) { }

  function handleCancelCallback() {
    handleModalCancel();
  }

  const isValidStripeToken = () => {
    if (formik.values.useExistingSource) {
      return !!formik.values.paymentSources;
    } else {
      switch (formik.values.paymentMethodType) {
        case paymentToggleTypes.CREDIT_CARD:
          return !!stripeCardToken;
        case paymentToggleTypes.ACH:
          return !!stripeBankToken;
        default:
          return true;
      }
    }
  }

  return (
    <Dialog
      className="modal user-modal"
      open={open}
      fullWidth={true}
      maxWidth="md"
      scroll="body"
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        {(isIOCLoading || isPatientLoading || isPaymentMethodLoading)
          ? (
            <Grid container direction="row" justifyContent="center" alignItems="center">
              <LoadingOverlay />
            </Grid>
          ) : (vobPatientState
            ? <TakePaymentView
              formik={formik}
              handleStripeStatus={handleStripeStatus}
            />
            :
            <>
              {isPayProcessing &&
                <Grid container direction="row" justifyContent="center" alignItems="center" className="overlay-load">
                  <MobilePaymentsLoader showLoader={isPayProcessing} />
                </Grid>
              }
              <TakePaymentModalContent
                  formik={formik}
                  disallowedPaymentTypes={isDisabledACH
                    ? disallowedPaymentTypes.concat('ACH')
                    : disallowedPaymentTypes
                  }
                  isOnlyAccountHolder={isOnlyAccountHolder}
                  disallowedPatientAPI={disallowedPatientAPI}
                  patientIOCData={patientIOCData}
                  patientDetails={patientDetails}
                  hasPaymentReason={hasPaymentReason !== false}
              />
            </>
          )
        }
      </DialogContent>
      <DialogActions>
        <DialogActionButton
          isEnabled={formik.dirty && formik.isValid && !isIOCLoading && !isPatientLoading && !isPayProcessing && isValidStripeToken()}
          savebuttonText="Pay"
          saveStatus={saveStatus}
          executeSave={formik.handleSubmit}
          handleCallbackSave={handleSaveCallback}
          handleCallbackCancel={handleCancelCallback}
          spinnerLeftPosition={5}
        />
      </DialogActions>
    </Dialog>
  );
}
